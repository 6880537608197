import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../store/auth/reducer';
import { useNavigate } from 'react-router-dom';

const IdleTimeout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuth } = useSelector((state) => state.auth);

    // Function to reset idle timeout
    const resetIdleTimeout = () => {
        clearTimeout(window.idleTimer);
        window.idleTimer = setTimeout(() => {
            // If user is idle for 15 minutes, log out
            dispatch(userLogout(navigate));
        }, 15 * 60 * 1000); // 15 minutes
        // }, 10 * 1000); // 30 seconds
    };

    useEffect(() => {
        if (isAuth) {
            // Listen for user activity to reset the timer
            window.addEventListener('mousemove', resetIdleTimeout);
            window.addEventListener('keypress', resetIdleTimeout);

            // Set the initial idle timeout
            resetIdleTimeout();
        }

        return () => {
            // Cleanup event listeners on unmount
            clearTimeout(window.idleTimer);
            window.removeEventListener('mousemove', resetIdleTimeout);
            window.removeEventListener('keypress', resetIdleTimeout);
        };
    }, [isAuth, dispatch]);

    //   return null;
};

export default IdleTimeout;
