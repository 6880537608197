// get axios api config
import api from './api';

// get axios options
import { axiosOptions, axiosOptionsForFormData } from '../helper/common-helper';
import { store } from '../store/store';
import { setIAProgress, setSupportingDocumentProgress } from '../store/general/reducer';
import { setItemFileDocumentProgress } from '../store/trade/reducer';
import { ATTACHMENT_TYPE } from '../config/constant';


// export the functions
export default {
    // create user
    async upload(data, from, inx) {
        console.log('upload',data,from,inx);
        const axiosConfig = await axiosOptionsForFormData();
        axiosConfig.onUploadProgress = (progressEvent) => {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            switch (from) {
                case ATTACHMENT_TYPE.ADMIN_SUPPORT_DOCUMENT:
                case ATTACHMENT_TYPE.AGENT_SUPPORT_DOCUMENT:
                case ATTACHMENT_TYPE.ORG_SUPPORT_DOCUMENT:
                case ATTACHMENT_TYPE.CUSTOMER_SUPPORT_DOCUMENT:
                    store.dispatch(setSupportingDocumentProgress({ inx, percentCompleted }));
                    break;
                case "customer_photo":
                case "customer_metal_photo":
                case "metal_photo":
                case "weight_photo":
                case "purity_photo":
                    store.dispatch(setIAProgress({ name: from, percentCompleted }));
                    break;
            }
        };
        return await api().post('service-file/', data, axiosConfig);
    },
    async uploadItemDoc(data, itemInx, name, inx) {
        const axiosConfig = await axiosOptionsForFormData();
        axiosConfig.onUploadProgress = (progressEvent) => {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            percentCompleted = percentCompleted > 99 ? "Processing":percentCompleted;
            store.dispatch(setItemFileDocumentProgress({ percentCompleted, itemInx, name, inx }));
        };
        return await api().post('service-file/', data, axiosConfig);
    },

    // update user
    async deleteById(id) {
        const axiosConfig = await axiosOptions();
        return await api().delete('service-file/' + id, axiosConfig);
    },
    async getById(id) {
        const axiosConfig = await axiosOptions();
        return await api().get('service-file/' + id, axiosConfig);
    },
}
