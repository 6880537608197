import React, { useRef, useEffect, useState } from 'react';
import GeneralRoutes from "./layouts/route-navigation/general-routes";
import PortalRoutes from "./layouts/route-navigation/portal-routes";
import CustomerRoutes from "./layouts/route-navigation/customer-routes";
import SideBar from "./layouts/sidebar";
import GeneralTopMenu from "./layouts/general-top-menu";
import CustomerHeader from "./layouts/customer-header";
import { actionGetAwaiting } from './store/trade/action';

import { setNetworkOffline, setDeviceWidth } from "./store/general/reducer";
import Loader from "./component/loader";
import { useSelector, useDispatch } from 'react-redux';

import ToastProvider from "./helper/toast-provider-helper";
import { getDefaultUserPermission } from './store/auth/actions';
import { DEFAULT_USER_ROLE, ENVIRONMENT } from './config/constant';
import LocationGPS from './component/location-gps';
import IdleTimeout from './component/idle-timeout';
import AdminPortalTopMenu from './layouts/admin-portal-top-header';
import { isMobileScreen } from './helper/common-helper';
import VerifyOtp from './component/verify-otp';

function App() {

  const elementRef = useRef(null);

  const { isAuth, userData } = useSelector(state => state.auth);
  const { networkOffline, pageTitle, deviceWidth, popupLocation } = useSelector(state => state.general);

  const dispatch = useDispatch();

  window.addEventListener('offline', function (e) {
    dispatch(setNetworkOffline(true));
  });

  window.addEventListener('online', function (e) {
    dispatch(setNetworkOffline(false));
  });

  useEffect(() => {
    let fontSizes = "";
    for (let i = 8; i < 36; i++) {
      fontSizes += ` .fnt-size-${i}{
      font-size:${i}px;
      }
      `;
    }
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      .bg-image-one {
        background-image: url(${ENVIRONMENT.BASE_URL}assets/images/img-01.jpg);
      }
      .bg-image-two {
        background-image: url(${ENVIRONMENT.BASE_URL}assets/images/img-02.jpg);
      }
      .bg-customer-register {
        background-image: url(${ENVIRONMENT.BASE_URL}assets/images/register-bg.png);
      }
        ${fontSizes}
    `;
    document.head.appendChild(styleElement);

  }, []);

  useEffect(() => {
    isAuth === true && getUserPermission();
    isAuth === true && userData.user_role_id !== DEFAULT_USER_ROLE.CUSTOMER && userData.user_role_id !== DEFAULT_USER_ROLE.ORGANIZATION && dispatch(actionGetAwaiting());
    if (elementRef.current) {
      dispatch(setDeviceWidth(elementRef.current.offsetWidth));
    }
    const classes = ["bg-customer-register"];
    if (userData && userData.user_role_id === DEFAULT_USER_ROLE.CUSTOMER) {
      classes.forEach(className => document.body.classList.add(className));
    } else {
      classes.forEach(className => document.body.classList.remove(className));
    }
    const mobileClasses = ["is-mobile-screen"];
    if (isMobileScreen) {
      classes.forEach(className => document.body.classList.add(mobileClasses));
    } else {
      classes.forEach(className => document.body.classList.remove(mobileClasses));
    }

  }, [isAuth]);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  const getUserPermission = () => {
    dispatch(getDefaultUserPermission());
  }

  return (
    <>
      <ToastProvider>
        <Loader />
        <VerifyOtp />
        <IdleTimeout />
        {networkOffline ? (<div className="alert alert-danger m-0 text-center" role="alert">No internet connection </div>) : null}
        {/* general public routes */}
        {!isAuth && (
          <>
            <GeneralTopMenu />
            <GeneralRoutes />
          </>
        )}
        {isAuth && userData.user_role_id === DEFAULT_USER_ROLE.CUSTOMER && (
          <>
            <LocationGPS />
            <CustomerHeader />

            <div className={`container ${isMobileScreen ? "pt-3" : "pt-5"}`}>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-body shadow rounded'>
                      <CustomerRoutes />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </>
        )}
        {isAuth && userData.user_role_id !== DEFAULT_USER_ROLE.CUSTOMER && (
          <>
            <LocationGPS optional={false} />
            <div className="wrapper" ref={elementRef}>
              <SideBar />
              <div id="content" className={`${deviceWidth <= 460 ? "" : "pt-3"}`}>
                {deviceWidth <= 460 && <AdminPortalTopMenu />}
                <PortalRoutes />
              </div>
            </div>
          </>
        )}
      </ToastProvider>
    </>
  );
}

export default App;
