import { createSlice } from '@reduxjs/toolkit'
import { TABLES } from '../../config/constant';

let tables = {};

Object.entries(TABLES).forEach(([key, value]) => {
    tables[value] = {
        totalRecords: 0,
        sort: '',
        order: '',
        offset: 0,
        limit: 10,
        data: [],
      
        contextMenuRef: null
    }
});

const initialState = tables;
const tableSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
      
        setSortField: (state, action) => {
            state[action.payload.tableKey].sort = action.payload.value;
        },
        setSortOrder: (state, action) => {
            state[action.payload.tableKey].order = action.payload.value;
        },
        setOffset: (state, action) => {
            state[action.payload.tableKey].offset = action.payload.value;
        },
        setLimit: (state, action) => {
            state[action.payload.tableKey].limit = action.payload.value;
        },
        setTableData: (state, action) => {
            state[action.payload.tableKey].data = action.payload.data;
            state[action.payload.tableKey].totalRecords = action.payload.totalRecord;
        },
        setContaxtMenuRef: (state, action) => {
            state[action.payload.tableKey].contextMenuRef = action.payload.opt;
        },
        setClearTableState: (state, action) => {
            Object.entries(TABLES).forEach(([key, value]) => {
                state[value] = {
                    totalRecords: 0,
                    sort: '',
                    order: '',
                    offset: 0,
                    limit: 10,
                    data: [],
                    contextMenuRef: null
                }
            });
        }
    }
});
export default tableSlice.reducer;
export const {
    setTableData,
    setSortField,
    setSortOrder,
    setLimit,
    setOffset,
    setContaxtMenuRef,
    setClearTableState
   
} = tableSlice.actions;
