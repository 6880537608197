//services
import { HTTP_STATUS_CODE } from '../../config/constant';
import { setSubCategoryList } from './reducer';
import subCategoryService from '../../services/sub-category';

export const actionGetSubCategoryList = (catId) => {
    return async (dispatch, getState) => {
        const response = await subCategoryService.list(catId);
        if (response?.status === HTTP_STATUS_CODE.OK) {
            await dispatch(setSubCategoryList(response.data.data.sub_categories));
        } 
    }
}
