//reducer
import { setUpdateId, setFormData, setFormModel } from "./reducer";
import { updateLoaderStatus, setTableLoader, setDisabledButton, setBulkSupportingDocumentData, setFileData } from '../general/reducer';
import { setTableData } from "../table/reducer";

// others
import { HTTP_STATUS_CODE, PERMISSION_KEY, PERMISSION_PAGE_KEY, TABLES, TOAST_TYPE, validatePermission } from '../../config/constant';
import { showToast } from "../../helper/toast-service-helper";

//services
import adminService from '../../services/admin'
import { formatFileSize, handingApiErrors, truncateFileName } from "../../helper/common-helper";
import { setDefaultRolePermission } from "../auth/actions";
import { actionGetFileById } from "../general/action";

// export user create action
export const actionCreate = (data) => {
    return async (dispatch, getState) => {
        // const {defaultUserPermission} = getState().auth;
        // if(!validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.ADMIN,PERMISSION_KEY.ADD)){
        //     return;
        // }
        const response = await adminService.create(data);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }

        await dispatch(actionGetAll());
        await dispatch(setFormModel(false));
        showToast(TOAST_TYPE.SUCCESS, 'Successfully created', 'top-right');

    }
}

// export user update action
export const actionUpdate = (data, id) => {
    return async (dispatch, getState) => {
        // const {defaultUserPermission} = getState().auth;
        // if(!validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.ADMIN,PERMISSION_KEY.UPDATE)){
        //     return;
        // }
        const response = await adminService.update(data, id);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }

        await dispatch(actionGetAll());
        await dispatch(setFormModel(false));
        showToast(TOAST_TYPE.SUCCESS, 'Successfully updated', 'top-right');

    }
}

// export user get all action
export const actionGetAll = () => {
    return async (dispatch, getState) => {
        const { defaultUserPermission } = getState().auth;
        if (!validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.ADMIN, PERMISSION_KEY.READ)) {
            return;
        }


        let getParams = getState().table[TABLES.USER];
        const data = {
            offset: getParams.offset,
            limit: getParams.limit,
        };
        const response = await adminService.getAll(data);
        // dispatch(updateLoaderStatus(false));
        dispatch(setTableLoader(false));

        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }

        await dispatch(setTableData({
            data: response.data.data.admin,
            totalRecord: response.data.data.total_count,
            tableKey: TABLES.USER
        }));

    }
}

// export user get by id action
export const actionGetById = (id) => {
    return async (dispatch, getState) => {
        // const {defaultUserPermission} = getState().auth;
        // if(!validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.ADMIN,PERMISSION_KEY.READ)){
        //     return;
        // }

        const response = await adminService.getById(id);

        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }


        const data = response.data.data.admin;
        let supportingDocuments = {};
        if (data?.supporting_documents && (data.supporting_documents).length > 0) {
            data.supporting_documents.forEach(file => {

                let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;

                let fileDetails = {
                    ...file,
                    size: formatFileSize(file.file_size),
                    name: truncateFileName(file.original_name)
                };

                supportingDocuments[inx] = fileDetails;
            });

            dispatch(setBulkSupportingDocumentData(supportingDocuments));

        }

        await dispatch(setUpdateId(id));
        await dispatch(setFormData({
            email: data.email,
            phone_number: data.phone_number,
            user_role_id: data.user_role_id,
            first_name: data.first_name,
            last_name: data.last_name,
            middle_name: data.middle_name,
            national_id: data.national_id,
            work_experience: data.work_experience,
            designation: data.designation,
            active: data.active
        }));
        if (data.profile) {
            await dispatch(actionGetFileById(data.profile.slug, 'profile'));
        } else {
            await dispatch(setFileData({ name: 'profile', value: null }));

        }
        await dispatch(setFormModel(true));

        await dispatch(setDefaultRolePermission(data.user_permissions));
        dispatch(updateLoaderStatus(false));


    }
}
