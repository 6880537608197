import React from 'react';
let toasts;
export const setToast = (refs) => {
    toasts = refs;
};

export const showToast = (severity, message, position = 'top-right') => {
    if (toasts && toasts[position]?.current) {
        const key = Date.now();
        toasts[position].current.show({ key, severity, summary: severity, detail: message, life: 3000 });
    }
};
