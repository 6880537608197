// get axios api config
import api from './api';

// get axios options
import { axiosOptions } from '../helper/common-helper';

// export the functions
export default {
    // get all the user list
    async getAll(data) {
        const queryString = Object.keys(data)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
            .join('&');
            
        return await api().get(`service-one/agent?${queryString}`, await axiosOptions());
    },
 
    // get user by id
    async getById(id) {
        return await api().get('service-one/agent/' + id, await axiosOptions());

    },

    // create user
    async create(data) {
        const axiosConfig =await axiosOptions();
        return await api().post('service-one/agent', data, axiosConfig);
    },

    // update user
    async update(data, id) {
        const axiosConfig = await axiosOptions();
        return await api().put('service-one/agent/' + id, data, axiosConfig);
    },
    async list(orgId) {
        const axiosConfig = await axiosOptions();
        const queryParam = orgId ? `service-one/agent/list?organizationId=${orgId}`:`service-one/agent/list`;
        return await api().get(queryParam, axiosConfig);
    },

}
