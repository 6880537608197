import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { Avatar } from 'primereact/avatar';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  IconFaCustomers,
  IconFaTrading,
  IconFaProdducts,
  IconFaOrganization,
  IconFaAgents,
  IconFaAdmin,
  IconLogout,
  IconWebLogo,
  IconWorkLocation,
  IconWebIconLogo,
  IconChevronRight,
  IconChevronLeft,
  IconWebAdminLogo,
} from '../svg-icons'

import { DEFAULT_USER_ROLE, ENVIRONMENT, PERMISSION_PAGE_KEY, SIDEBAR_OPTION } from '../config/constant'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
//reducers
import { userLogout } from '../store/auth/reducer';
import { useLocation, useNavigate } from "react-router-dom";
import { setSideBarAction, updateURLParams } from "../store/general/reducer";
import { actionClearData } from "../store/general/action";

const PageHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();


  // Select the sideBarActive from the Redux store using the "useSelector" hook it is declare in const.
  const { awaitingCount } = useSelector((state) => state.trade);
  const sideBarActive = useSelector((state) => state.general.sideBarActive);
  const { userData, profile, defaultUserPermission } = useSelector((state) => state.auth);

  const [sidebarMenu, setSidebarMenu] = useState([]);

  const navigatePage = async (page) => {

    if (location.pathname !== page) {
      dispatch(actionClearData());
    }
    if (sideBarActive === SIDEBAR_OPTION.MOBILE) {
      dispatch(setSideBarAction(SIDEBAR_OPTION.CLOSED));
    }
    navigate(page);
  }


  const logout = async () => {
    dispatch(userLogout(navigate));
  };

  const toggleSidebar = () => {
    if (sideBarActive === SIDEBAR_OPTION.MOBILE) {
      dispatch(setSideBarAction(SIDEBAR_OPTION.CLOSED));
    }
    else if (sideBarActive === SIDEBAR_OPTION.CLOSED) {
      dispatch(setSideBarAction(SIDEBAR_OPTION.MOBILE));
    } else if (sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR) {
      dispatch(setSideBarAction(SIDEBAR_OPTION.FULL_SIDEBAR));
    } else {
      dispatch(setSideBarAction(SIDEBAR_OPTION.ICON_SIDEBAR));
    }
  }

  const handleMouseEnter = () => {

    document.body.classList.add('tooltip-active'); // Add class to body
  };

  const handleMouseLeave = () => {

    document.body.classList.remove('tooltip-active'); // Remove class from body
  };

  const itemRenderer = (item) => {
    const activePathName = location.pathname === '/portal/trading' || location.pathname === '/portal/trading/details' || location.pathname === '/portal/trading/item-approval' ? '/portal/trading' : location.pathname;
    const isActive = activePathName === item.url;

    return (
      <>
        {sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? (
          <OverlayTrigger
            key='profile-tooltip'
            placement='right'
            overlay={<Tooltip>{item.label}</Tooltip>}
          >
            <div className={`p-menuitem-content  ${isActive ? 'active' : ''} ${awaitingCount > 0 && item.cusClass}`}>
              <a
                onMouseEnter={handleMouseEnter} // Add mouse enter event
                onMouseLeave={handleMouseLeave} // Add mouse leave event
                className={`flex align-items-center p-menuitem-link position-relative`}
                onClick={e => navigatePage(item.url)}
              >
                <span className={`m-0`}>{item.icon}</span>
                {item.label === "Trading" && awaitingCount > 0 && <span className={`${isActive ? 'active':''} badge show-review-count`}>{awaitingCount}</span>}
              </a>
            </div>
          </OverlayTrigger>
        ) : (
          <div className={`p-menuitem-content  ${isActive ? 'active' : ''}`}>
            <a
              className={`flex align-items-center p-menuitem-link position-relative`}
              onClick={e => navigatePage(item.url)}
            >
              <span className={`m-0`}>{item.icon}</span>
              <span>{item.label}</span>
              {item.label === "Trading" && awaitingCount > 0 && <span className={`${isActive ? 'active':''} badge show-review-count`}>{awaitingCount}</span>}
            </a>
          </div>
        )}
      </>
    )
  };

  const listOfSideBarMenu = {
    [PERMISSION_PAGE_KEY.CUSTOMER]: {
      label: 'Customer',
      icon: <IconFaCustomers width={sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? 25 : 20} height={sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? 25 : 20} />,
      url: '/portal/customer',
      template: itemRenderer,
      cusClass:""
    },
    [PERMISSION_PAGE_KEY.TRADING]: {
      label: 'Trading',
      icon: <IconFaTrading width={sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR && awaitingCount ===0 ? 25 : 20} height={sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR && awaitingCount ===0 ? 25 : 20} />,
      url: '/portal/trading',
      template: itemRenderer,
      cusClass:"trade-menu"
    },
    [PERMISSION_PAGE_KEY.LOCATION]: {
      label: 'Locations',
      icon: <IconWorkLocation customClass="text-white" width={sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? 25 : 20} height={sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? 25 : 20} />,
      url: '/portal/locations',
      template: itemRenderer,
      cusClass:""
    },
    [PERMISSION_PAGE_KEY.PRODUCT]: {
      label: 'Products',
      icon: <IconFaProdducts width={sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? 25 : 20} height={sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? 25 : 20} />,
      url: '/portal/products',
      template: itemRenderer,
      cusClass:""
    },
    [PERMISSION_PAGE_KEY.ORGANIZATION]: {
      label: 'Organisation',
      icon: <IconFaOrganization width={sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? 25 : 20} height={sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? 25 : 20} />,
      url: '/portal/organisation',
      template: itemRenderer,
      cusClass:""
    },
    [PERMISSION_PAGE_KEY.AGENT]: {
      label: 'Agents',
      icon: <IconFaAgents width={sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? 25 : 20} height={sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? 25 : 20} />,
      url: '/portal/agents',
      template: itemRenderer,
      cusClass:""
    },
    [PERMISSION_PAGE_KEY.ADMIN]: {
      label: 'Admin',
      icon: <IconFaAdmin width={sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? 25 : 20} height={sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? 25 : 20} />,
      url: '/portal/admin',
      template: itemRenderer,
      cusClass:""
    }

  };

  useEffect(() => {
    renderSideBar();
    updateUrlParams();
  }, [defaultUserPermission, sideBarActive, location.pathname, awaitingCount]);

  const updateUrlParams = () => {
    dispatch(updateURLParams());
  }
  const renderSideBar = () => {
    let permissionPages = [];
    if (defaultUserPermission) {
      Object.entries(defaultUserPermission).forEach(([objKey, obj]) => {
        const checkPermission = Object.values(obj).some(permission => permission === true);
        if (checkPermission) {
          permissionPages.push(listOfSideBarMenu[objKey]);
        }
      });

      setSidebarMenu(permissionPages);
    }
  }

  return (
    <nav id="sidebar" className={`position-relative bg-secondary-color ${sideBarActive}`}>
      <ul className="nav">
        <li
          className="nav-item w-100 sidebar-profile"
        >
          <a className="nav-link text-white">
            {sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? (<IconWebIconLogo width={50} height={50} />) : (<IconWebAdminLogo width={180} height={60} />)}
          </a>
        </li>
      </ul>

      <div className="w-100 side-menu-list p-menu p-component p-ripple-disabled" data-pc-name="menu" data-pc-section="root">
        <ul className="p-menu-list p-reset" role="menu" data-pc-section="menu">
          {sidebarMenu && sidebarMenu.map((mi, inx) => (
            <li key={inx} className="p-menuitem" role="menuitem" aria-label="Products" data-p-focused="false" data-p-disabled="false" data-pc-section="menuitem">
              {itemRenderer(mi)}
            </li>
          ))}
        </ul>
      </div>
      <div className={`user-profile ${sideBarActive}`}>
        <Dropdown
          drop='up-centered'
        >
          <Dropdown.Toggle>
            <div className="float-start me-2">
              {profile ? (
                <Avatar size="large" shape="circle" image={profile} />
              ) : (
                <Avatar size="large" shape="circle" image={`${ENVIRONMENT.BASE_URL}assets/images/profile.png`} />
              )}

            </div>
            {sideBarActive !== SIDEBAR_OPTION.ICON_SIDEBAR && (
              <div className="float-start">
                <OverlayTrigger
                  key='profile-tooltip'
                  placement='top'
                  overlay={<Tooltip>{userData.name}</Tooltip>}
                >
                  <span>{userData.name.substring(0, 10)}</span>
                </OverlayTrigger>
                <br />
                <span><RenderUserRole userRole={userData.user_role_id} /></span>
              </div>
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {/* <Dropdown.Item href="#"><IconPerson /> Profile</Dropdown.Item> */}
            <Dropdown.Item onClick={logout}><IconLogout /> Sign out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <span className="extend-sidebar" onClick={toggleSidebar}>
        {sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? <IconChevronRight customClass="text-white" /> : <IconChevronLeft customClass="text-white" />}
      </span>
    </nav>
  );
};

export default PageHeader;

const RenderUserRole = ({ userRole }) => {

  let userRoleName = "";
  switch (parseInt(userRole)) {
    case DEFAULT_USER_ROLE.SUPER_ADMIN:
      userRoleName = "Super Admin";
      break;
    case DEFAULT_USER_ROLE.ADMIN:
      userRoleName = "Admin";
      break;
    case DEFAULT_USER_ROLE.AGENT:
      userRoleName = "Agent";
      break;
    case DEFAULT_USER_ROLE.ORGANIZATION:
      userRoleName = "Organisation";
      break;
    case DEFAULT_USER_ROLE.CUSTOMER:
      userRoleName = "Customer";
      break;
  }
  return userRoleName;
}