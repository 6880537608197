import React, { useEffect, useState } from 'react';
import { currencyFormatValue, deepCopy, fixedTwoDigit, roundUpValue } from '../helper/common-helper';
import { ORDER_ITEM_STATUS } from '../config/constant';
import GetOrderItemStatus from './order-item-status';
import GetCustomerOrderItemStatus from './customer-order-item-status';

const TradeItemTable = ({ items = [], cc = 0, mc = 0, onRowSelected, isCustomer = false }) => {

    const [totalProductCost, setTotalProductCost] = useState(0);
    const [productItems, setProductItems] = useState([]);
    const [certificationCharges, setCertificationCharges] = useState(0);
    const [ccAmount, setCCAmount] = useState(0);
    const [mcAmount, setMCAmount] = useState(0);

    // items.map(item => {
    //     let itemStatus = isCustomer ? <GetCustomerOrderItemStatus status={item.order_item_status} /> : <GetOrderItemStatus status={item.order_item_status} />;
    //     productItems.push({
    //         ...item,
    //         itemStatus
    //     });
    // });

    useEffect(() => {
        startCalculate();
    }, [items, cc, mc, isCustomer]);

    const startCalculate = () => {
        let tempTotalProductCost = 0;
        let tempProductItems = [];
        let tempCertificationCharges = 0;

        items.forEach(item => {
            let newItem = deepCopy(item);
            newItem.itemStatus = isCustomer ? <GetCustomerOrderItemStatus status={item.order_item_status} /> : <GetOrderItemStatus status={item.order_item_status} />;
            const productPrice = fixedTwoDigit(fixedTwoDigit(item.price) * fixedTwoDigit(parseFloat(item.weight)));

            newItem.productPrice = productPrice;
            const loyaltyPrice = fixedTwoDigit(item.loyalty / 100);
            newItem.LoyaltyPrice = loyaltyPrice;

            if (![ORDER_ITEM_STATUS.AGENT_REJECTED.id, ORDER_ITEM_STATUS.ADMIN_REJECTED.id, ORDER_ITEM_STATUS.REJECTED.id].includes(item.order_item_status)) {
                tempTotalProductCost += (
                    productPrice
                    -
                    (
                        productPrice
                        *
                        loyaltyPrice
                    )
                );

                tempCertificationCharges += item.certification_charges;

            }
            tempProductItems.push(newItem);
        });
        setTotalProductCost(tempTotalProductCost);
        setProductItems(tempProductItems);
        setCertificationCharges(tempCertificationCharges);
        setCCAmount(fixedTwoDigit(fixedTwoDigit(cc / 100) * fixedTwoDigit(tempTotalProductCost)));
        setMCAmount(fixedTwoDigit(fixedTwoDigit(mc / 100) * fixedTwoDigit(tempTotalProductCost)));
    }


    return (
        <>
            <div className="table-responsive">
                <table className='table single-line'>
                    <thead className='table-light'>
                        <tr>
                            <th>TRADING ITEM ID</th>
                            <th>PRODUCT</th>
                            <th className='text-end'>CERTIFICATION CHARGES</th>
                            <th className='text-end'>CUSTOMER CONTRIBUTION</th>
                            <th className='text-end'>M/C CONTRIBUTION</th>
                            <th className='text-end'>LME Value</th>
                            {!isCustomer && <th>STATUS</th> }
                        </tr>
                    </thead>
                    <tbody>
                        {productItems.length > 0 && productItems.map((item, inx) => (
                            <tr key={inx + "tr"} className='cursor-pointer' onClick={e => onRowSelected(item.slug, inx)}>
                                <td>{item.row_ref_number}</td>
                                <td>{item.sub_category_name}</td>
                                <td className='text-end'>{item.certification_charges} KES</td>
                                <td className='text-end'>{cc}%</td>
                                <td className='text-end'>{mc}%</td>                              
                                <td className='text-end'>
                                    {
                                        [ORDER_ITEM_STATUS.AGENT_REJECTED.id, ORDER_ITEM_STATUS.ADMIN_REJECTED.id, ORDER_ITEM_STATUS.REJECTED.id].includes(item.order_item_status) ? (
                                            <>-</>
                                        ) : (
                                            <>
                                                {
                                                    currencyFormatValue(
                                                        fixedTwoDigit(
                                                            fixedTwoDigit(fixedTwoDigit(item.price) * fixedTwoDigit(parseFloat(item.weight)))
                                                            -
                                                            (
                                                                fixedTwoDigit(fixedTwoDigit(item.price) * fixedTwoDigit(parseFloat(item.weight)))
                                                                *
                                                                fixedTwoDigit(item.loyalty / 100)
                                                            )
                                                        )
                                                    )
                                                } KES
                                            </>
                                        )
                                    }

                                </td>
                                {!isCustomer &&  <td>{item.itemStatus}</td> }

                            </tr>
                        ))}

                        {/* <tr>
                            <td colSpan={5} className='text-end'>&nbsp;</td>
                            <td>Product Total</td>
                            <td className='text-end'>{
                                currencyFormatValue(totalProductCost)
                            } KES<span className='ms-3'>&nbsp;</span></td>
                        </tr>
                        <tr>
                            <td colSpan={5} className='text-end'>&nbsp;</td>
                            <td>Certification charges</td>
                            <td className='text-end'>{
                                currencyFormatValue(
                                    roundUpValue(certificationCharges)
                                )
                            } KES<span className='ms-1'>(-)</span></td>
                        </tr>
                        <tr>
                            <td colSpan={5} className='text-end'>&nbsp;</td>
                            <td>Customer Contribution ({cc}%)</td>
                            <td className='text-end'>{
                                currencyFormatValue(ccAmount)
                            } KES<span className='ms-1'>(-)</span>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={5} className='text-end'>&nbsp;</td>
                            <td>Madini Chapaa Contribution (15%)</td>
                            <td className='text-end'>{
                                currencyFormatValue(mcAmount)
                            } KES<span className='ms-1'>(+)</span>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={5} className='text-end'>&nbsp;</td>
                            <td> <strong>Total Disbursal Amount</strong></td>
                            <td className='text-end'>
                                <strong> {
                                    currencyFormatValue(
                                        totalProductCost
                                        -
                                        roundUpValue(certificationCharges)
                                        -
                                        ccAmount
                                    )
                                } KES</strong><span className='ms-3'>&nbsp;</span>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={5} className='text-end'>&nbsp;</td>
                            <td><strong>Total Wallet Balance</strong></td>
                            <td className='text-end'>

                                <strong>
                                    {
                                        currencyFormatValue(parseFloat(mcAmount) + parseFloat(ccAmount))

                                    } KES</strong><span className='ms-3'>&nbsp;</span>
                            </td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
            <div className='row mt-5'>
                <div className='col-12 mb-2'>
                    <h5>Estimated price</h5>
                </div>

                <div className='fnt-size-14 mb-2 col-6 col-md-3'>Product Total</div>
                <div className='fnt-size-14 mb-2 col-6 col-md-2 text-end'>
                    {
                        currencyFormatValue(totalProductCost)
                    } KES<span className='ms-3'>&nbsp;</span>
                </div>
                <div className='clear-fix'></div>
                <div className='fnt-size-14 mb-2 col-6 col-md-3'>Certification charges</div>
                <div className='fnt-size-14 mb-2 col-6 col-md-2 text-end'>
                    {
                        currencyFormatValue(
                            roundUpValue(certificationCharges)
                        )
                    } KES<span className='ms-1'>(-)</span>
                </div>
                <div className='clear-fix'></div>
                <div className='fnt-size-14 mb-2 col-6 col-md-3'>Customer Contribution ({cc}%)</div>
                <div className='fnt-size-14 mb-2 col-6 col-md-2 text-end'>
                    {
                        currencyFormatValue(ccAmount)
                    } KES<span className='ms-1'>(-)</span>
                </div>
                <div className='clear-fix'></div>
                <div className='fnt-size-14 mb-2 col-6 col-md-3'>Madini Chapaa Contribution (15%)</div>
                <div className='fnt-size-14 mb-2 col-6 col-md-2 text-end'>
                    {
                        currencyFormatValue(mcAmount)
                    } KES<span className='ms-1'>(+)</span>
                </div>
                <div className='clear-fix'></div>
                <div className='fnt-size-14 mb-2 col-6 col-md-3'><strong>Total Disbursal Amount</strong></div>
                <div className='fnt-size-14 mb-2 col-6 col-md-2 text-end'>
                <strong> {
                            currencyFormatValue(
                                totalProductCost
                                -
                                roundUpValue(certificationCharges)
                                -
                                ccAmount
                            )
                        } KES</strong><span className='ms-3'>&nbsp;</span>
                </div>
                <div className='clear-fix'></div>
                <div className='fnt-size-14 mb-2 col-6 col-md-3'><strong>Total Wallet Balance</strong></div>
                <div className='fnt-size-14 mb-2 col-6 col-md-2 text-end'>
                    <strong>
                        {
                            currencyFormatValue(parseFloat(mcAmount) + parseFloat(ccAmount))

                        } KES</strong><span className='ms-3'>&nbsp;</span>
                </div>
                <div className='clear-fix'></div>
            </div>
        </>
    );
};

export default TradeItemTable;
