//reducer
import { setUpdateId, setFormData, setFormModel, setOrganizationList, setOrgLocationList } from "./reducer";
import { updateLoaderStatus, setTableLoader, setDisabledButton, setBulkSupportingDocumentData, setFileData } from '../general/reducer';
import { setTableData } from "../table/reducer";

// others
import { HTTP_STATUS_CODE, PERMISSION_KEY, PERMISSION_PAGE_KEY, TABLES, TOAST_TYPE, validatePermission } from '../../config/constant';
import { showToast } from "../../helper/toast-service-helper";

//services
import organizationService from '../../services/organization';
import { formatFileSize, handingApiErrors, truncateFileName } from "../../helper/common-helper";
import { setDefaultRolePermission } from "../auth/actions";
import { actionGetFileById } from "../general/action";

// export user create action
export const actionCreate = (data) => {
    return async (dispatch, getState) => {

        const response = await organizationService.create(data);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        await dispatch(actionGetAll());
        await dispatch(setFormModel(false));
        showToast(TOAST_TYPE.SUCCESS, 'Successfully created', 'top-right');

    }
}

// export user update action
export const actionUpdate = (data, id) => {
    return async (dispatch, getState) => {


        const response = await organizationService.update(data, id);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        await dispatch(actionGetAll());
        await dispatch(setFormModel(false));
        showToast(TOAST_TYPE.SUCCESS, 'Successfully updated', 'top-right');

    }
}

// export user get all action
export const actionGetAll = () => {
    return async (dispatch, getState) => {

        const { defaultUserPermission } = getState().auth;
        if (!validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.ORGANIZATION, PERMISSION_KEY.READ)) {
            return;
        }


        let getParams = getState().table[TABLES.USER];
        const data = {
            offset: getParams.offset,
            limit: getParams.limit,
        };
        const response = await organizationService.getAll(data);

        dispatch(setTableLoader(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        await dispatch(setTableData({
            data: response.data.data.organizations,
            totalRecord: response.data.data.total_count,
            tableKey: TABLES.USER
        }));

    }
}

// export user get by id action
export const actionGetById = (id) => {
    return async (dispatch, getState) => {


        const response = await organizationService.getById(id);

        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        const data = response.data.data.organization;
        let supportingDocuments = {};
        if (data?.supporting_documents && (data.supporting_documents).length > 0) {
            data.supporting_documents.forEach(file => {

                let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;

                let fileDetails = {
                    ...file,
                    size: formatFileSize(file.file_size),
                    name: truncateFileName(file.original_name)
                };

                supportingDocuments[inx] = fileDetails;
            });

            dispatch(setBulkSupportingDocumentData(supportingDocuments));

        }

        let locations = [];
        if (data.locations) {
            data.locations.forEach(location => {
                locations.push(location.id);
            });
        }
        await dispatch(setUpdateId(id));
        await dispatch(setFormData({
            name: data.name,
            registration_number: data.registration_number,
            date_of_formation: data.date_of_formation,
            email: data.email,
            phone_number: data.phone_number,
            locations,
            address: data.address,
            gem_target: data.gem_target,
            metal_target: data.metal_target,
            user_role_id: data.user_role_id,
            customer_name: data.customer_name,
            customer_email: data.customer_email,
            customer_phone_number: data.customer_phone_number,
            customer_designation: data.customer_designation,
            active: data.active
        }));

        if (data.profile) {
            await dispatch(actionGetFileById(data.profile.slug, 'profile'));
        } else {
            await dispatch(setFileData({ name: 'profile', value: null }));

        }
        await dispatch(setFormModel(true));

        await dispatch(setDefaultRolePermission(data.user_permissions));
        dispatch(updateLoaderStatus(false));


    }
}

export const actionGetOrganizationList = () => {
    return async (dispatch, getState) => {

        const response = await organizationService.list();
        await dispatch(updateLoaderStatus(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        await dispatch(setOrganizationList(response.data.data.organizations));

    }
}
export const actionGetOrgLocationList = (orgId) => {
    return async (dispatch, getState) => {
        const response = await organizationService.locations(orgId);
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        await dispatch(setOrgLocationList(response.data.data.locations));

    }
}