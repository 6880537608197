import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'
import { resetPassword } from '../../../store/auth/actions';

import { IconEyeVisible, IconEyeVisibleOff, IconLongBack, IconMsgSuccessCheckMark, Spinner } from '../../../svg-icons';
import { encryptValue } from '../../../helper/encryption-helper';
import { setDisabledButton, updatePageTitle } from '../../../store/general/reducer';
import { REGEXP } from '../../../config/constant';
import { setDefaultFormData, setFormData, setShowForgotPassword, setShowForgotPasswordMsg, setShowLogin, setShowResetPasswordMsg } from '../../../store/auth/reducer';
import { useNavigate } from 'react-router-dom';

const ResetPasswordPage = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const xToken = useSelector((state) => state.general.urlParams);

  const { showResetPasswordMsg, formData } = useSelector((state) => state.auth);
  const { disabledButton } = useSelector((state) => state.general);


  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [errors, setErrors] = useState({});
  const schema = Yup.object().shape({
    password: Yup.string()
      .matches(REGEXP.passwordRegExp,  'Password should be minimum 8 characters with 1 uppercase, 1 lowercase, 1 number, and 1 special character')
      .required('Password is required'),
    confirm_password: Yup.string()
      .oneOf([formData.password], 'Password do not match')
      .required('Please confirm your password'),
  });
  const validateField = async (name, value) => {
    try {
      await Yup.reach(schema, name).validate(value);
      setErrors({ ...errors, [name]: '' });
    } catch (validationError) {
      setErrors({ ...errors, [name]: validationError.message });
    }
  };
  const validateForm = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationErrors) {
      const newErrors = validationErrors.inner.reduce((acc, err) => {
        acc[err.path] = err.message;
        return acc;
      }, {});
      setErrors(newErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await validateForm();
    if (isValid) {
      dispatch(setDisabledButton(true));
      dispatch(resetPassword({
        password: encryptValue(formData.password),
        token: xToken['code'],
      }));

    } else {
      console.log('Form has errors:', errors);
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let data = {
      ...formData,
      [name]: value,
    };

    dispatch(setFormData(data));

    await validateField(name, value);
  };

  useEffect(() => {
    dispatch(updatePageTitle("Reset password"));
  }, []);

  const toggleShowHidePassword = () => {
    setShowPassword(!showPassword);
  }
  const toggleConfirmShowHidePassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  }
  const showLoginSection = async () => {
    dispatch(setShowLogin(true));
    dispatch(setShowForgotPassword(false));
    dispatch(setShowForgotPasswordMsg(false));
    dispatch(setShowResetPasswordMsg(false));
    dispatch(setDefaultFormData());
    navigate("/");
  }

  return (

    <div className="container-fluid">
      <div className={`row fit-height ${showResetPasswordMsg ? "bg-msg-box" : ""}`}>
        <div className='col-md-6 align-self-center'>
          {
            !showResetPasswordMsg && (
              <>
                <div className="form-group text-center mb-5">
                  <h3 className='text-center'>
                    <IconLongBack
                      width={46}
                      height={32}
                      customClass='cursor-pointer'
                      onIconClick={showLoginSection}
                    />
                    Reset Password
                  </h3>
                </div>
                <form onSubmit={handleSubmit} noValidate>
                  <div className='login-section-pd'>
                    <div className="form-group mb-3">
                      <label>New password</label>
                      <div className="remove-dd input-group mb-3">
                        <input type={showPassword ? "text" : "password"} className="form-control"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          placeholder='Enter the new password'
                        />
                        <span
                          className="input-group-text"
                          id="basic-addon2"
                          onClick={toggleShowHidePassword}
                        >{showPassword ? <IconEyeVisibleOff /> : <IconEyeVisible />}</span>
                      </div>
                      {errors.password && <span className="error-message">{errors.password}</span>}
                    </div>
                    <div className="form-group mb-3">
                      <label>Confirm password</label>
                      <div className="remove-dd input-group mb-3">
                        <input type={showConfirmPassword ? "text" : "password"} className="form-control"
                          name="confirm_password"
                          value={formData.confirm_password}
                          onChange={handleChange}
                          placeholder='Enter the confirm password'
                        />
                        <span
                          className="input-group-text"
                          id="basic-addon2"
                          onClick={toggleConfirmShowHidePassword}
                        >{showConfirmPassword ? <IconEyeVisibleOff /> : <IconEyeVisible />}</span>
                      </div>
                      {errors.confirm_password && <span className="error-message">{errors.confirm_password}</span>}
                    </div>
                    <div className="form-group text-center">

                      <button disabled={disabledButton} type="submit" className="btn-block btn btn-orange-primary">   {disabledButton ? <Spinner /> : null} Submit</button>

                    </div>
                  </div>
                </form>
              </>
            )}

          {/* reset password message */}
          {showResetPasswordMsg && (
            <div className='form-group text-center'>
              <IconMsgSuccessCheckMark
                width={130}
                height={130}
              />
              <h6 className='m-0 mt-3 mb-3'><strong>Password Reset Successfully</strong></h6>
              <p className='text-muted-one fnt-size-14 mb-0'>Your account is now ready to login using the new password.</p>
              <p className='text-muted-one fnt-size-14'>Click the below button to Login to Your Account</p>
              <button onClick={showLoginSection} type="button" className="btn-block btn btn-orange-primary">Sign in</button>
            </div>
          )}
        </div>
        <div className={`d-none d-md-block col-md-6 bg-image-two`}></div>
      </div>
    </div>
  );
};
export default ResetPasswordPage;
