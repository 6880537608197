//reducer
import { setUpdateId, setFormData,  setFormModel } from "./reducer";
import { updateLoaderStatus, setTableLoader, setDisabledButton } from '../general/reducer';
import { setTableData } from "../table/reducer";

// others
import {HTTP_STATUS_CODE, PERMISSION_KEY, PERMISSION_PAGE_KEY, TABLES, TOAST_TYPE, validatePermission } from '../../config/constant';
import { showToast } from "../../helper/toast-service-helper";

//services
import productService from '../../services/product'
import { actionGetSubCategoryList } from "../sub-category/action";
import { handingApiErrors } from "../../helper/common-helper";


// export user create action
export const actionCreate = (data) => {
    return async (dispatch, getState) => {
        const response = await productService.create(data);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
            await dispatch(actionGetAll());
            await dispatch(setFormModel(false));
            showToast(TOAST_TYPE.SUCCESS, 'Successfully created', 'top-right');
        
    }
}

// export user update action
export const actionUpdate = (data, id) => {
    return async (dispatch, getState) => {


        const response = await productService.update(data, id);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
            await dispatch(actionGetAll());
            await dispatch(setFormModel(false));
            showToast(TOAST_TYPE.SUCCESS, 'Successfully updated', 'top-right');
        
    }
}

// export user get all action
export const actionGetAll = () => {
    return async (dispatch, getState) => {
        const {defaultUserPermission} = getState().auth;
        if(!validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.PRODUCT,PERMISSION_KEY.READ)){
            return;
        }

        let getParams = getState().table[TABLES.USER];
        const data = {
            offset: getParams.offset,
            limit: getParams.limit,
        };
        const response = await productService.getAll(data);
        // dispatch(updateLoaderStatus(false));
        dispatch(setTableLoader(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
            await dispatch(setTableData({
                data: response.data.data.products,
                totalRecord: response.data.data.total_count,
                tableKey: TABLES.USER
            }));
        
    }
}

// export user get by id action
export const actionGetById = (id) => {
    return async (dispatch, getState) => {
       
        const response = await productService.getById(id);
        dispatch(updateLoaderStatus(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
            const data = response.data.data.product;
            await dispatch(setUpdateId(id));
            await dispatch(actionGetSubCategoryList(data.category_id));

            await dispatch(setFormData({
                category: data.category_id,
                sub_category_id: data.sub_category_id,
                loyalty_percentage: data.loyalty_percentage,
                certification_need: data.certification_need,
                certification_charges: data.certification_charges,
                notes: data.notes,
                active: data.active
            }));
          
            await dispatch(setFormModel(true));

        
    }
}
