import { createSlice } from '@reduxjs/toolkit'
import { jwtDecode } from 'jwt-decode';
import { PERMISSION_KEY, PERMISSION_PAGE_KEY } from '../../config/constant';
const _d = sessionStorage.getItem('_d');
const pro = sessionStorage.getItem('pro');

let _dParse = _d ? JSON.parse(_d) : null;

const authStatus = _d ? true : false;
const userToken = _dParse ? _dParse.access_token : null;
const userRefreshToken = _dParse ? _dParse.refresh_token : null;
const profile = pro ? pro : null;
const userData = _dParse ? jwtDecode(_dParse.access_token) : null;

const defaultFormData = {
    email: "",
    password: "",
    confirm_password: "",
    old_password: ""
}

const defaultOTPFormData = {
    phone_number: "",
    otp: ""
}

const initialState = {
    isAuth: authStatus,
    userToken,
    userRefreshToken,
    userData,
    forgotPasswordApiStatus: false,
    resetPasswordApiStatus: false,
    formDefaultPermission: {},
    permissions: {},
    profile,
    formData: defaultFormData,
    otpFormData: defaultOTPFormData,
    showLogin: true,
    showForgotPassword: false,
    showForgotPasswordMsg: false,
    showResetPasswordMsg: false,
    latitude: null,
    longitude: null,
    showVerifyOtp: false
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setShowLogin: (state, action) => {
            state.showLogin = action.payload;
        },
        setLatLng: (state, action) => {
            const { lat, lng } = action.payload;
            state.latitude = lat;
            state.longitude = lng;
        },
        setShowForgotPassword: (state, action) => {
            state.showForgotPassword = action.payload;
        },
        setShowForgotPasswordMsg: (state, action) => {
            state.showForgotPasswordMsg = action.payload;
        },
        setShowResetPasswordMsg: (state, action) => {
            state.showResetPasswordMsg = action.payload;
        },
        setUserToken: (state, action) => {
            sessionStorage.setItem('_d', JSON.stringify(action.payload.data));
            state.isAuth = true;
            state.userToken = action.payload.data.access_token;
            state.userRefreshToken = action.payload.data.refresh_token;
            state.userData = jwtDecode(action.payload.data.access_token);
            state.showLogin = true;
            state.showForgotPassword = false;
            state.showForgotPasswordMsg = false;
            state.showResetPasswordMsg = false;
            state.formData = defaultFormData;
            // state.profile=null;
        },
        userLogout: (state, action) => {
            sessionStorage.clear();
            state.isAuth = false;
            state.userToken = null;
            state.user = null;
            state.userRefreshToken = null;
            state.profile = null;
            const navigate = action.payload;
            if (navigate) {
                navigate("/");
                window.location.reload();
            }
        },
        setForgotPasswordMsg: (state, action) => {
            state.showLogin = false;
            state.showForgotPassword = false;
            state.showForgotPasswordMsg = true;
        },
        setResetPasswordMsg: (state, action) => {
            state.showResetPasswordMsg = false;
        },
        setPermissions: (state, action) => {
            state.permissions = action.payload;
        },
        setFormDefaultPermission: (state, action) => {
            state.formDefaultPermission = action.payload;
        },
        setDefaultUserPermission: (state, action) => {
            const permissionList = action.payload;
            let pagePermissions = {};

            // Loop through each page key and assign the appropriate permissions
            Object.values(PERMISSION_PAGE_KEY).forEach(pageKey => {
                let pageDetails = {
                    [PERMISSION_KEY.ADD_ONLY]: false,
                    [PERMISSION_KEY.READ]: false,
                    [PERMISSION_KEY.ADD]: false,
                    [PERMISSION_KEY.UPDATE]: false,
                    [PERMISSION_KEY.DISBURSAL]: false,
                };

                const permission = permissionList[pageKey];

                // Assign permissions based on the permission value
                if (permission === PERMISSION_KEY.DISBURSAL) {
                    pageDetails[PERMISSION_KEY.DISBURSAL] = true;
                    pageDetails[PERMISSION_KEY.UPDATE] = true;
                    pageDetails[PERMISSION_KEY.ADD] = true;
                    pageDetails[PERMISSION_KEY.READ] = true;
                } else if (permission === PERMISSION_KEY.UPDATE) {
                    pageDetails[PERMISSION_KEY.UPDATE] = true;
                    pageDetails[PERMISSION_KEY.ADD] = true;
                    pageDetails[PERMISSION_KEY.READ] = true;
                } else if (permission === PERMISSION_KEY.ADD) {
                    pageDetails[PERMISSION_KEY.ADD] = true;
                    pageDetails[PERMISSION_KEY.READ] = true;
                } else if (permission === PERMISSION_KEY.ADD_ONLY) {
                    pageDetails[PERMISSION_KEY.ADD_ONLY] = true;
                } else if (permission === PERMISSION_KEY.READ) {
                    pageDetails[PERMISSION_KEY.READ] = true;
                }

                pagePermissions[pageKey] = pageDetails;
            });

            state.defaultUserPermission = pagePermissions;

        },
        setUserProfile: (state, action) => {
            const proFile = action.payload;
            sessionStorage.setItem('pro', proFile);
            state.profile = proFile;
        },
        setFormData: (state, action) => {
            state.formData = action.payload;
        },
        setDefaultFormData: (state, action) => {
            state.formData = defaultFormData;
        },
        setOtpFormData: (state, action) => {
            state.otpFormData = action.payload;
        },
        setDefaultOtpFormData: (state, action) => {
            state.otpFormData = defaultOTPFormData;
        },
        setShowVerifyOtp(state, action) {
            state.showVerifyOtp = action.payload;
        },

    }
});
export default authSlice.reducer;
export const {
    setOtpFormData,
    setDefaultOtpFormData,
    setUserToken,
    userLogout,
    setForgotPasswordMsg,
    setResetPasswordMsg,
    setPermissions,
    setFormDefaultPermission,
    setDefaultUserPermission,
    setUserProfile,
    setFormData,
    setDefaultFormData,
    setShowLogin,
    setShowForgotPassword,
    setShowForgotPasswordMsg,
    setShowResetPasswordMsg,
    setLatLng,
    setShowVerifyOtp
} = authSlice.actions;
