import React from 'react';
import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ProtectedRoutes = ({ path }) => {
    const {isAuth} = useSelector(state => state.auth);
    
    return !isAuth ? <Navigate to='/' /> : <Outlet />

}
export default ProtectedRoutes;