//services
import { HTTP_STATUS_CODE } from '../../config/constant';
import categoryService from '../../services/category';
import { setCategoryList } from './reducer';

export const actionGetCategoryList = () => {
    return async (dispatch, getState) => {
        const response = await categoryService.list();
        if (response?.status === HTTP_STATUS_CODE.OK) {
            await dispatch(setCategoryList(response.data.data.categories));
        } 
    }
}