
// Import files and libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


// others
import { IconCustomerContribution, IconMcContribution, IconPlus, IconWallet, IconBack } from '../../../svg-icons';
import { TABLES } from '../../../config/constant';
import TradingFormModel from './form-modal';
// import TradingDetails from './trade-details';


//reducer
import { setUpdateId, setFormModel, setDefaultFormData, setTradeDetails } from '../../../store/trade/reducer';
import { setClearGeneralState, setTableLoader, updateLoaderStatus, updatePageTitle, updateURLParams } from '../../../store/general/reducer';

//action
import { actionGetAll } from '../../../store/trade/action';

//custom component
import DataTableComponent from '../../../component/data-table';

import { actionGetOrganizationList } from '../../../store/organization/action';
import { useNavigate } from 'react-router-dom';


import { getDisplayDateTime } from '../../../helper/date-helper';
import { actionGetCustomerWallet } from '../../../store/customer/action';
import { currencyFormatValue } from '../../../helper/common-helper';
import GetCustomerOrderStatus from '../../../component/customer-order-status';




const TradingPage = () => {

  // dispatch action
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get the table details like sort, order, page, search, page, page size, table data, total records
  const { search, formModel } = useSelector((state) => state.trade);

  const { sort, order, limit, offset, totalRecords } = useSelector((state) => state.table[TABLES.TRADE]);

  // handle model show
  const showModel = async () => {
    // clear the form details
    dispatch(setDefaultFormData());
    // remove the update id
    dispatch(setUpdateId(null));

    // show the model
    dispatch(setFormModel(true));

  };
  useEffect(() => {
    dispatch(setClearGeneralState());
    dispatch(updatePageTitle("Trading"))
    dispatch(actionGetOrganizationList());
  }, []);

  // On page function call get the page data and if any table action changes call the api
  useEffect(() => {
    // call the api function
    dispatch(setTableLoader(true));
    getAllData();
  }, [limit, offset, search, sort, order]);


  // get data form the api
  const getAllData = async () => {
    // dispatch the data to the API call
    dispatch(actionGetAll())
  }


  const tableColumns = [

    {
      field: 'row_ref_number',
      header: 'TRADING ID'
    },
    {

      field: '',
      header: 'CUSTOMER NAME',
      body: (row) => {
        return `${row.customer_first_name} ${row.customer_middle_name} ${row.customer_last_name}`;
      }
    },
    {

      field: '',
      header: 'AGENT NAME',
      body: (row) => {
        return `${row.agent_first_name} ${row.agent_middle_name} ${row.agent_last_name}`;
      }
    },

    {

      field: 'item_count',
      header: 'NO OF TRADING ITEMS',
      headerAlign: 'text-end',
      bodyAlign: 'text-end',
    },

    // {
    //   sortable: false,
    //   field: 'status',
    //   header: 'STATUS',
    //   body: (row) => {
    //     return <GetCustomerOrderStatus status={row.order_status} />;
    //   }
    // },
    {
      sortable: false,
      field: 'created_at',
      header: 'DATE',
      headerAlign: 'text-end',
      bodyAlign: 'text-end',
      body: (row) => {
        return getDisplayDateTime(row.created_at)
      }
    }

  ];
  const selectedRow = (e) => {
    dispatch(setTradeDetails(null));
    navigate("/trading/details?type=order&view=" + e.data.slug);
    dispatch(updateURLParams());
  }

  const handleBackClick = () => {
    navigate('/dashboard'); // Navigates back to the dashboard
  };
  // page design
  return (
    <>

      {formModel ? (
        <div className="row">
          <div className='col-12'>
            <TradingFormModel />
          </div>
        </div>
      ) : (

        <div className="row">
          <div className='col-md-6 col-12'>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-start">
                <div
                  className="me-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Go to dashboard"
                >
                  <IconBack
                    width={42}
                    height={42}
                    onIconClick={handleBackClick}
                    customClass="cursor-pointer"
                  />
                </div>
                <div>
                  <h3 className="page-title">My Trading</h3>
                  <p className="sub-page-title table-total-count">
                    Total number of Tradings - {totalRecords}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-12 text-end'>
            <button className="btn btn-orange-primary" onClick={showModel}>
              <IconPlus customClass="text-white" /> Create Trade
            </button>
          </div>
          <div className='col-12 pt-3'>
            <DataTableComponent
              tableKey={TABLES.TRADE}
              columns={tableColumns}
              onTableRowSelect={selectedRow}
            />
          </div>
        </div>
      )}
    </>
  );

}

export default TradingPage;
