import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { RECORD_STATUS } from '../config/constant';

const FormStatus = ({ editForm, active, id, onStatusClick }) => {
    
    return (
        <>
            {/* {editForm && id === null ? (
                <div className={`form-group  ps-4`}>
                    <label className='w-100'>Status</label>
                    <button
                        disabled={!editForm}
                        name='active'
                        className={
                            `me-3 btn form-status-btn-active 
                                   ${parseInt(active) === RECORD_STATUS.ACTIVE.id ? 'active' : ''}`
                        } type='button'>
                        {parseInt(active) === RECORD_STATUS.ACTIVE.id ? <span className='ms-3'></span> : ''}
                        {RECORD_STATUS.ACTIVE.name}
                    </button>
                </div>
            ) : ( */}
                <div className={`form-group  ps-4 ${editForm && id === null ? 'd-none' : ''}`}>
                    <label className='w-100'>Status</label>
                    {!editForm && parseInt(active) === RECORD_STATUS.ACTIVE.id && (
                        <button
                            disabled={!editForm}
                            name='active'
                            className={
                                `me-3 btn form-status-btn-active 
                                   ${parseInt(active) === RECORD_STATUS.ACTIVE.id ? 'active' : ''}`
                            } type='button'>
                            {parseInt(active) === RECORD_STATUS.ACTIVE.id ? <span className='ms-3'></span> : ''}
                            {RECORD_STATUS.ACTIVE.name}
                        </button>
                    )}
                    {!editForm && parseInt(active) === RECORD_STATUS.INACTIVE.id && (
                        <button
                            disabled={!editForm}
                            name='active'
                            className={
                                `me-3 btn form-status-btn-inactive 
                                  ${parseInt(active) === RECORD_STATUS.INACTIVE.id ? 'active' : ''}`
                            } type='button'>
                            {parseInt(active) === RECORD_STATUS.INACTIVE.id ? <span className='ms-3'></span> : ''}
                            {RECORD_STATUS.INACTIVE.name}
                        </button>
                    )}
                    {editForm && (
                        <>
                            <button
                                disabled={!editForm}
                                name='active'
                                onClick={onStatusClick}
                                value={RECORD_STATUS.ACTIVE.id}
                                className={
                                    `me-3 btn form-status-btn-active 
                                    ${parseInt(active) === RECORD_STATUS.ACTIVE.id ? 'active' : ''}`
                                } type='button'>
                                {parseInt(active) === RECORD_STATUS.ACTIVE.id ? <span className='ms-3'></span> : ''}
                                {RECORD_STATUS.ACTIVE.name}
                            </button>
                            <button
                                disabled={!editForm}
                                name='active'
                                onClick={onStatusClick}
                                value={RECORD_STATUS.INACTIVE.id}
                                className={
                                    `me-3 btn form-status-btn-inactive 
                                    ${parseInt(active) === RECORD_STATUS.INACTIVE.id ? 'active' : ''}`
                                } type='button'>
                                {parseInt(active) === RECORD_STATUS.INACTIVE.id ? <span className='ms-3'></span> : ''}
                                {RECORD_STATUS.INACTIVE.name}
                            </button>
                        </>
                    )}
                </div>
            {/* )} */}
        </>
    );
};

export default FormStatus;
