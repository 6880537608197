import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { setShowCamera } from '../store/general/reducer';
import { isMobileScreen } from '../helper/common-helper';

const CameraUpload = ({ onImgCallBack }) => {
  const [devices, setDevices] = useState([]);
  const [cameraType, setCameraType] = useState(''); // Default to front camera
  const [selectedImage, setSelectedImage] = useState(null);
  const [permissionStatus, setPermissionStatus] = useState('prompt'); // Track permission status
  const [isCapturing, setIsCapturing] = useState(false);
  let videoRef = useRef(null); // Reference for the video element
  const canvasRef = useRef(null); // Reference for the canvas element (for capturing photo)

  // Button status
  const [showCaptureBtn, setShowCaptureBtn] = useState(false);
  const [showDoneBtn, setShowDoneBtn] = useState(false);

  const { showCamera } = useSelector((state) => state.general);
  const dispatch = useDispatch();



  // Helper function to check if the browser supports querying 'camera' permission
  const isPermissionQuerySupported = () => {
    return navigator.permissions && navigator.permissions.query && 'camera' in navigator.mediaDevices.getSupportedConstraints();
  };
  // Get available devices and check camera permission
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
      const videoDevices = deviceInfos.filter((device) => device.kind === 'videoinput');
      setDevices(videoDevices);
      console.log('device list', videoDevices)
      // Set the default camera as the first device
      
      setCameraType(isMobileScreen ? 'user' : videoDevices[0]?.deviceId || '');
      
    });
    if (isPermissionQuerySupported()) {
      // Check for camera permission status
      navigator.permissions.query({ name: 'camera' }).then((permission) => {
        setPermissionStatus(permission.state);

        // Listen for permission changes
        permission.onchange = () => {
          setPermissionStatus(permission.state);
        };
      });
    } else {
      setPermissionStatus('prompt');
    }
  }, []);

  // Request camera access and display the video stream
  useEffect(() => {
    if (showCamera && (permissionStatus === 'prompt' || permissionStatus === 'granted')) {
      startCamera();
    } else if (!showCamera) {
      stopCamera();
    }
  }, [showCamera, permissionStatus, cameraType]);

  const startCamera = () => {
    console.log(cameraType,'startCamra called')

    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      stream.getTracks().forEach((track) => {
        track.stop()
      });
    }
    
    const selectedDevice = devices.find((device) => device.deviceId === cameraType);
    if (!selectedDevice && !isMobileScreen) {
      console.error('Selected device not available.');
      return;
    }
    setIsCapturing(true);

    const constraints = cameraType === 'user' || cameraType === 'environment' ?
      { facingMode: cameraType } :
      { deviceId: { exact: cameraType } };

    navigator.mediaDevices
      // .getUserMedia({ video: { deviceId: cameraType } })
      .getUserMedia({ video: constraints })
      // .getUserMedia({ video: { deviceId: { exact: cameraType } } })  // Use deviceId
      .then((stream) => {
        setPermissionStatus('granted');
        videoRef.current.srcObject = stream;
        setShowCaptureBtn(true);
      })
      .catch((error) => {
        console.error('Camera access denied:', error);
        setPermissionStatus('denied');
      });
  };

  // Stop the camera stream
  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      stream.getTracks().forEach((track) => track.stop());
    }
    setIsCapturing(false);
    setShowCaptureBtn(false);
    setShowDoneBtn(false);
    setSelectedImage(null); // Reset the selected image
    dispatch(setShowCamera(false));
  };

  // Capture the photo from the video stream
  const capturePhoto = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    const context = canvas.getContext('2d');

    // Set canvas dimensions equal to video dimensions
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Draw the video frame to the canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Get the image from the canvas as a data URL
    const imageDataUrl = canvas.toDataURL('image/png');
    setSelectedImage(imageDataUrl);
    setShowCaptureBtn(false);
    setShowDoneBtn(true);

    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      stream.getTracks().forEach((track) => {
        track.stop()
      });
    }
  };

  const updateCameraSource = (e) => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      stream.getTracks().forEach((track) => track.stop());
    }
    setIsCapturing(false);
    setShowCaptureBtn(false);
    setShowDoneBtn(false);
    setSelectedImage(null); // Reset the selected image
    setCameraType(e.target.value);
  }
  const getImage = () => {
    setIsCapturing(false);
    setShowCaptureBtn(false);
    setShowDoneBtn(false);
    onImgCallBack(selectedImage);
    setSelectedImage(null);
    dispatch(setShowCamera(false));
  };

  return (
    <Dialog
      header="Capture image"
      visible={showCamera}
      style={{ width: isMobileScreen ? '100vw' : '50vw' }}
      onHide={() => dispatch(setShowCamera(false))} // Hide dialog and stop camera
    >
      <div>
        {/* Check permission status and show appropriate message */}
        {permissionStatus === 'denied' && (
          <div style={{ color: 'red' }}>
            <p>Camera access has been denied. Please enable camera permission in your browser settings.</p>
          </div>
        )}

        {permissionStatus !== 'denied' && (
          <div className='row'>           
              <div className='col-12 mb-3'>
                <div className='form-group'>
                  <label>Select Camera:</label>
                  <select className='form-control' onChange={updateCameraSource} value={cameraType}>
                    {isMobileScreen && (
                      <>
                        <option value="user">Front Camera</option>
                        <option value="environment">Back Camera</option>
                      </>
                    )}
                    {!isMobileScreen && devices.map((devic, inx) => (
                      <option value={devic.deviceId} key={inx}>{devic.label}</option>
                    ))}
                  </select>
                </div>
              </div>
          

            {!selectedImage && isCapturing && (
              <div className='col-12'>
                <div className='form-group'>
                  <video ref={videoRef} autoPlay playsInline style={{ width: '100%', height: 'auto' }}></video>
                </div>
              </div>
            )}

            {/* Canvas element for capturing the photo (hidden) */}
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>

            {selectedImage && (
              <div className='col-12'>
                <div className='form-group'>
                  <h6>Captured Image Preview:</h6>
                  <img src={selectedImage} alt="Captured" className="w-100" />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="form-group mt-5 text-end mb-3">
        <button className="btn btn-purple-primary-outline me-2" type="button" onClick={stopCamera}>
          Cancel
        </button>
        {showCaptureBtn && (
          <button className="btn btn-purple-primary" type="button" onClick={capturePhoto}>
            Capture
          </button>
        )}
        {showDoneBtn && (
          <button className="btn btn-purple-primary" type="button" onClick={getImage}>
            Done
          </button>
        )}
      </div>
    </Dialog>
  );
};

export default CameraUpload;
