

// impor the libraries
import { createSlice } from '@reduxjs/toolkit'
import { RECORD_STATUS } from '../../config/constant';

const defaultFormData = {
  first_name:"",
  middle_name:"",
  last_name:"",
  national_id:"",
  email:"",
  phone_number:"",
  work_experience:"",
  designation:"",
  organization_id:"",
  location_id:"",
  gem_target:"",
  metal_target:"",
  active: RECORD_STATUS.ACTIVE.id
};

// user default initial state
const initialState = {
  formData: defaultFormData,
  formModel: false,
  updateId:null,
  agentList:[]
}

// create a slice and reducers
const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    setFormModel: (state, action) => {
      state.formModel = action.payload;
    },
   
    setUpdateId: (state, action) => {
      state.updateId = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setDefaultFormData: (state, action) => {
      state.formData = defaultFormData;
    },   
    setAgentList:(state,action)=>{
      state.agentList=action.payload;
    },
    setClearAgentState: (state, action) => {
      state.formData = defaultFormData;
      state.formModel = false;
      state.updateId = null;
      state.agentList =[];
    }
  },
})
// export the user module
export default agentSlice.reducer;

export const {
  setFormModel,
  setUpdateId,
  setFormData,
  setDefaultFormData,
  setAgentList,
  setClearAgentState
} = agentSlice.actions;