
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'; // Assuming you're using Redux
import { IconBack } from '../../../svg-icons';
import { useSelector } from 'react-redux';
import { actionGetById } from '../../../store/customer/action';
import { updatePageTitle } from '../../../store/general/reducer';
import { actionGetLocationList } from '../../../store/location/action';
import { isMobileScreen } from '../../../helper/common-helper';

const Support = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { supportDetails } = useSelector((state) => state.customer);
  const { userData } = useSelector((state) => state.auth);

  const handleBackClick = () => {
    navigate('/dashboard'); // Navigate back to the dashboard
  };

  useEffect(() => {
    dispatch(updatePageTitle("Support"));
    dispatch(actionGetById(userData.id))
  }, []);

  return (
    <div className={`${isMobileScreen ? '' : 'p-3'}`}>
      <div className="row agent-section mt-2">
        <div className='d-flex align-items-center pb-4'>
          <div
            className="me-2"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Go to dashboard"
          >
            <IconBack
              width={42}
              height={42}
              onIconClick={handleBackClick}
              customClass="cursor-pointer"
            />
          </div>
          <h5 className='ms-2 flex-shrink-1 mb-0'><strong>Support</strong></h5>
        </div>
        <div className="col-md-6 mb-3">
          <div className="form-group">
            <label className="w-100">Agent Name</label>
            <span className="w-100 gray-color">
              {supportDetails && supportDetails.agent_name}
            </span>
          </div>
        </div>

        <div className="col-md-6 mb-3">
          <div className="form-group">
            <label className="w-100">Email Address</label>
            <span className="w-100 gray-color">  {supportDetails && supportDetails.agent_email}</span>
          </div>
        </div>

        <div className="col-md-6 mb-3">
          <div className="form-group">
            <label className="w-100">Phone Number</label>
            <span className="w-100 gray-color">  {supportDetails && supportDetails.agent_phone_number}</span>
          </div>
        </div>

        <div className="col-md-6 mb-3">
          <div className="form-group">
            <label className="w-100">Mining Location</label>
            <span className="w-100 gray-color">  {supportDetails && supportDetails.location_name}</span>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Support;
