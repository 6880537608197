import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const PermissionDenied = () => {

    return (
        <div className='container-fluid'>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="permission-denied">
                    <div className='row align-items-center h-100'>
                        <div className='col denied-box'>

                        <h3 className='sub-page-title text-center text-danger'>Access denied</h3>
                        <p className='m-0 text-center mt-3'>You currently does not have access to this page.</p>
                        <p className='m-0 text-center'>Please try again later</p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PermissionDenied;
