// Support.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconBack } from '../../../svg-icons';
import { updatePageTitle } from '../../../store/general/reducer';
import { useDispatch } from 'react-redux';
import { isMobileScreen } from '../../../helper/common-helper';

const LoanPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(updatePageTitle("Load"));
  },[])
  const handleBackClick = () => {
    navigate('/dashboard'); // Navigate back to the dashboard
  };
  return (
    <div className={`${isMobileScreen ?'': 'p-3'}`}>
      <div className='d-flex align-items-center pb-4'>
        <div
          className="me-2"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Go to dashboard"
        >
          <IconBack
            width={42}
            height={42}
            onIconClick={handleBackClick}
            customClass="cursor-pointer"
          />
        </div>
        <h5 className='ms-2 flex-shrink-1 mb-0'><strong>Loans</strong></h5>
      </div>
      <div>
        <h4 className='mb-3'>Loans Coming Soon</h4>
        <p className='mb-2'><strong>We're Almost Ready to Support Your Dreams!</strong></p>
        <p>Exciting things are on the way! Our upcoming loan services are designed to help you achieve your financial goals, whether it's starting a new venture, funding a big purchase, or simply having peace of mind. We're working hard to bring you the best options—stay tuned!</p>
      </div>

    </div>
  );
}

export default LoanPage;
