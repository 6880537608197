import React from 'react';
import { IconMenu, IconMenuRegular, IconWebAdminLogo, IconWebLogo } from '../svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ENVIRONMENT } from '../config/constant';
import { Avatar } from 'primereact/avatar';
import { useNavigate } from 'react-router-dom';
import { userLogout } from '../store/auth/reducer';
import ChangePassword from '../component/change-password';
import { setChangePasswordModel, setSideBarAction } from '../store/general/reducer';
import { setClearTradeState } from '../store/trade/reducer';

const AdminPortalTopMenu = () => {
  const { userData, profile } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className='container-fluid mobile-header'>
      <div className='row'>
        <div className='col-6 align-self-center'>
          <IconWebAdminLogo width={150} height={60} customClass='cursor-pointer' onIconClick={e => navigate("/trading")} />
        </div>
        <div className='col-6 align-self-center text-end'>
          <IconMenuRegular onIconClick={e=>{dispatch(setSideBarAction('mobile-menu'))}} width={24} height={24} customClass='svg-white' />
        </div>
      </div>
    </div>
  );
};

export default AdminPortalTopMenu;
