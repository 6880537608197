

// impor the libraries
import { createSlice } from '@reduxjs/toolkit'
import { PERMISSION_LIST, RECORD_STATUS } from '../../config/constant';

const defaultFormData = {
  name: '',
  registration_number: '',
  date_of_formation: '',
  email: '',
  phone_number: '',
  locations: [],
  address: '',
  gem_target: '',
  metal_target: '',
  active: RECORD_STATUS.ACTIVE.id,
  customer_name:'',
  customer_email:'',
  customer_phone_number:'',
  customer_designation:''
};

// user default initial state
const initialState = {
  formData: defaultFormData,
  formModel: false,
  updateId:null,
  organizationList:[],
  orgLocationList:[]
}

// create a slice and reducers
const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setFormModel: (state, action) => {
      state.formModel = action.payload;
    },
   
    setUpdateId: (state, action) => {
      state.updateId = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setDefaultFormData: (state, action) => {
      state.formData = defaultFormData;
    },
   
    setOrganizationList:(state, action)=>{
      state.organizationList = action.payload;
    },
    setOrgLocationList:(state, action)=>{
      state.orgLocationList = action.payload;
    },
    setClearOrgState: (state, action) => {
      state.formData = defaultFormData;
      state.formModel = false;
      state.updateId = null;
      state.organizationList = [];
      state.orgLocationList = [];
    }
  },
})
// export the user module
export default organizationSlice.reducer;

export const {
  setFormModel,
  setUpdateId,
  setFormData,
  setDefaultFormData,
  setOrganizationList,
  setClearOrgState,
  setOrgLocationList
} = organizationSlice.actions;