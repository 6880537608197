import React from 'react';
import { useSelector } from 'react-redux'

const Loader = () => {
  const showLoader = useSelector(state => state.general.loader)
  return (
    <>
      {showLoader ? (
        <div className="cs-loader">
          <div className="cs-loader-inner">
            <div className="cssload-container">
              <div className="sk-folding-cube">
                <span className="loader"></span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>

  )
}
export default Loader;