// get axios api config
import api from './api';

// get axios options
import { axiosOptions } from '../helper/common-helper';

// export the functions
export default {
   
    async getRolePermissionListByUserRole(userRoleId) {
        return await api().get(`/service-one/role-permission/${userRoleId}`, await axiosOptions());
    },

}
