
// dependencies
import React, { useEffect } from 'react'

// function
const PageNotFoundPage = () => {
  useEffect(() => {
    document.title = "Page not found"
  }, [])
  return (
    <div id="content">
        <div className="card mt-3 m-3">
          <div className="card-body">
            <p className="text-center page-not-found">Page not found</p>
          </div>
        </div>        
      </div>
  )
}
export default PageNotFoundPage