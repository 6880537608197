//reducer
import { setClearGeneralState, setFileData, setFilePreview, setFilePreviewModel, setIAData, setProDocDefault, setProfileLoader, setSupportingDocumentData } from '../general/reducer';

// others
import { HTTP_STATUS_CODE } from '../../config/constant';

//services
import fileService from '../../services/file'
import { formatFileSize } from "../../helper/common-helper";
import { setClearAdminState } from '../admin/reducer';
import { setClearAgentState } from '../agent/reducer';
import { setClearCategoryState } from '../category/reducer';
import { setClearCustomerState } from '../customer/reducer';
import { setClearLocationState } from '../location/reducer';
import { setClearOrgState } from '../organization/reducer';
import { setClearProductState } from '../product/reducer';
import { setClearSubCatState } from '../sub-category/reducer';
import { setClearTableState } from '../table/reducer';
import { setClearTradeState, setCustomerProfile } from '../trade/reducer';


// export user create action
export const actionFileUpload = (data, inx = 0, type = "profile") => {
    console.log(data,inx,type)
    return async (dispatch, getState) => {
        const response = await fileService.upload(data,type,inx);
        if (response?.status === HTTP_STATUS_CODE.CREATE) {
            await dispatch(setProfileLoader(false));

            switch (type) {
                case "profile":
                    await dispatch(setFileData({ name: type, value: response.data }));
                    break;
                case "customer_photo":
                case "customer_metal_photo":
                case "metal_photo":
                case "weight_photo":
                case "purity_photo":
                    let singleFile = {
                        ...response.data.file,
                        size: formatFileSize(response.data.file.file_size),
                        base64: response.data.base64,
                        isNew: true
                    };
                    await dispatch(setIAData({ data: { ...singleFile }, name:type }));
                    break;
                default:
                    let fileDetails = {
                        ...response.data.file,
                        size: formatFileSize(response.data.file.file_size),
                        base64: response.data.base64,
                        isNew: true
                    };
                    await dispatch(setSupportingDocumentData({ data: { ...fileDetails }, inx }));
                    break;
            }

        }
    }
}
export const actionGetFileById = (id, type = "profile") => {

    return async (dispatch, getState) => {
        const response = await fileService.getById(id);
        if (response?.status === HTTP_STATUS_CODE.GET) {
            switch (type) {
                case 'profile':
                    await dispatch(setFileData({ name: type, value: response.data }));
                    break;
                case "customer_photo":
                case "customer_metal_photo":
                case "metal_photo":
                case "weight_photo":
                case "purity_photo":
                    let singleFile = {
                        ...response.data.file,
                        size: formatFileSize(response.data.file.file_size),
                        base64: response.data.base64,
                        isNew: true
                    };
                    await dispatch(setIAData({ data: { ...singleFile }, name:type }));
                    break;
                case "customer-profile":
                    await dispatch(setCustomerProfile(response.data));
                    break;
                default:
                    dispatch(setFilePreview(response.data));
                    dispatch(setFilePreviewModel(true));
                    break;
            }
        }
    }
}
export const actionClearData = () => {
    return async (dispatch, getState) => {

        //general
        dispatch(setClearGeneralState());
        //admin
        dispatch(setClearAdminState());
        //agent
        dispatch(setClearAgentState());
        //category
        dispatch(setClearCategoryState());
        //customer
        dispatch(setClearCustomerState());
        //location
        dispatch(setClearLocationState());
        //organization
        dispatch(setClearOrgState());
        //product
        dispatch(setClearProductState());
        //sub category
        dispatch(setClearSubCatState());
        //table
        dispatch(setClearTableState());
        //trade
        dispatch(setClearTradeState());
    }
}
