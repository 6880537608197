// get axios api config
import api from './api';

// get axios options
import { axiosOptions } from '../helper/common-helper';

// export the functions
export default {
    // get all the user list
    async getAll(data) {
        const queryString = Object.keys(data)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
            .join('&');
        
        return await api().get(`service-trading/orders?${queryString}`, await axiosOptions());
    },

    // get user by id
    async getById(id) {
        return await api().get('service-trading/orders/' + id, await axiosOptions());

    },

    // create user
    async create(data) {
        const axiosConfig = await axiosOptions();
        return await api().post('service-trading/orders', data, axiosConfig);
    },
    async updateItemStatus(data) {
        return await api().post(`service-trading/orders/approval`,data, await axiosOptions());
    },
    async makePayment(slug) {

        return await api().get(`service-trading/orders/payment/${slug}`, await axiosOptions());
    },
    async getOrderItemBySlug(slug) {

        return await api().get(`service-trading/orders/order-item/${slug}`, await axiosOptions());
  },
    async getAwaiting() {

        return await api().get(`service-trading/orders/awaiting`, await axiosOptions());
    } ,
       async reAssignOrder(data) {

        return await api().post(`service-trading/orders/re-assign`, data, await axiosOptions());
    }

}
