

// impor the libraries
import { createSlice } from '@reduxjs/toolkit'

import { RECORD_STATUS } from '../../config/constant';

const defaultFormData = {
  location_name: "",
  address: "",
  county: "",
  division: "",
  location: "",
  sub_location: "",
  country: 86, // default kenya
  agent_id: "",
  active: RECORD_STATUS.ACTIVE.id
};

// user default initial state
const initialState = {
  formData: defaultFormData,
  formModel: false,
  updateId:null,
  locationList:[],
  countryList:[],
  countyList:[],
  divisionList:[],
  ddLocationList:[],
  subLocationList:[],
}

// create a slice and reducers
const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setFormModel: (state, action) => {
      state.formModel = action.payload;
    },
    setUpdateId: (state, action) => {
      state.updateId = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setDefaultFormData: (state, action) => {
      state.formData = defaultFormData;
    },
    setLocationList: (state, action) => {
      state.locationList = action.payload;
    },
    setClearLocationState: (state, action) => {
      state.formData = defaultFormData;
      state.formModel = false;
      state.updateId = null;
      state.locationList = [];
    },
    setCountryList: (state, action) => {
      state.countryList = action.payload;
    },
    setCountyList: (state, action) => {
      state.countyList = action.payload;
    },
    setDivisionList: (state, action) => {
      state.divisionList = action.payload;
    },
    setDDLocationList: (state, action) => {
      state.ddLocationList = action.payload;
    },
    setSubLocationList: (state, action) => {
      state.subLocationList = action.payload;
    },
   
  },
})
// export the user module
export default locationSlice.reducer;

export const {
  setCountryList,
  setCountyList,
  setDivisionList,
  setDDLocationList,
  setSubLocationList,
  setFormModel,
  setUpdateId,
  setFormData,
  setDefaultFormData,
  setLocationList,
  setClearLocationState
} = locationSlice.actions;