// Import files and libraries
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';

import * as Yup from 'yup';
import { Dropdown as ButtonDropdown } from 'react-bootstrap';


//others
import { IconBack, IconCurrentStep, IconDelete, IconPlus, IconTime } from '../../../svg-icons';

// reducer
import { setFormModel, setFormData, setRemoveItem, setAddProductItems, setItemUpdate, setItemSupportingDocumentPlaceholder, setSelectedCustomer, setItemFileDocumentPlaceholder } from '../../../store/trade/reducer';
import { setClearGeneralState, setDisabledButton, setShowCamera, updateLoaderStatus } from '../../../store/general/reducer';

// action
import { actionCreate, actionGetSubCatAttrWithInx, actionMultipleUpload, actionSearchCustomer, actionSingleUpload } from '../../../store/trade/action'
import { DEFAULT_USER_ROLE, ENVIRONMENT, TOAST_TYPE } from '../../../config/constant';
import { getDatabaseDateOnly, getDatabaseDateTime, getDatabaseTimeOnly, getDisplayDateOnly, getDisplayTimeOnly } from '../../../helper/date-helper';
import { showToast } from '../../../helper/toast-service-helper';
import { base64ToFile, currencyFormatValue, fixedTwoDigit, formatFileSize, getFileExtension, profileAccept, truncateFileName } from '../../../helper/common-helper';

import { actionGetSubCategoryList } from '../../../store/sub-category/action';
import { actionGetAgentList } from '../../../store/agent/action';
import ItemDetails from '../../../component/item-details';
import MultipleFilePreview from '../../../component/multiple-file-upload';
import FilePreview from '../../../component/file-preview';
import { actionClearData } from '../../../store/general/action';
import SingleFilePreview from '../../../component/single-file-upload';
import CameraUpload from '../../../component/camera-upload';


const TradeingFormModel = () => {
    // create a dispatch
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // create a state
    const [errors, setErrors] = useState(false);
    const [cameraUploadFile, setCameraUploadFile] = useState("");
    const [cameraUploadFileInx, setCameraUploadFileInx] = useState(null);

    const fileSD = useRef([]);
    const fileMetalPhoto = useRef([]);

    const triggerFileMetalInput = (index) => {
        
        if (fileMetalPhoto.current[index]) {
            fileMetalPhoto.current[index].click();
        }
    };
    const triggerFileSDInput = (index) => {
        
        if (fileSD.current[index]) {
            fileSD.current[index].click();
        }
    };

    const [currentStep, setCurrentStep] = useState(1);

    const {
        formData,
        formModel,
        selectedCustomer, customerProfile,
        productItems
    } = useSelector((state) => state.trade);

    const { disabledButton, profile } = useSelector((state) => state.general);
    const { subCategoryList } = useSelector((state) => state.subCategory);
    const { organizationList } = useSelector((state) => state.organization);
    const { agentList } = useSelector((state) => state.agent);
    const { userData } = useSelector((state) => state.auth);

    // form validation schema
    let schema = Yup.object().shape({

        organization_id: Yup.string()
            .required('Organization is required'),
        agent_id: Yup.string()
            .required('Agent is required'),
        scheduled_date: Yup.string()
            .required('Schedule Date is required'),
        scheduled_time: Yup.string()
            .required('Schedule Time is required'),

    });


    //validate the fields
    const validateField = async (name, value) => {
        try {
            await Yup.reach(schema, name).validate(value);
            setErrors({ ...errors, [name]: '' });
        } catch (validationError) {
            setErrors({ ...errors, [name]: validationError.message });
        }
    };

    //validate the form
    const validateForm = async () => {
        try {
            await schema.validate(formData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (validationErrors) {
            const newErrors = validationErrors.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };


    const gotoNextStep = async () => {
        if (currentStep === 1) {
            let hasErrors = false; // Local variable to track errors

            productItems.map((row, inx) => {
                if (row.product_id === '') {
                    showToast(TOAST_TYPE.ERROR, `#${inx + 1} Please select the product`, 'top-right');
                    hasErrors = true;
                }
                if (row.purity === 0) {
                    showToast(TOAST_TYPE.ERROR, `#${inx + 1} Please select the purity`, 'top-right');
                    hasErrors = true;
                }
                if (row.weight <= 0) {
                    showToast(TOAST_TYPE.ERROR, `#${inx + 1} Please provide the weight`, 'top-right');
                    hasErrors = true;
                }
                if (row.customer_metal_photo === null) {
                    showToast(TOAST_TYPE.ERROR, `#${inx + 1} Please upload metal photo`, 'top-right');
                    hasErrors = true;
                }
            });

            // After the loop, update the state and take actions accordingly
            if (!hasErrors) {
                // No errors, move to the next step
                setCurrentStep(currentStep + 1);
            } else {
                // Set errors in state if needed
                setErrors(true);
            }
        } else if (currentStep === 3) {
            // get the form status
            const isValid = await validateForm();

            if (isValid) {
                setCurrentStep(currentStep + 1);
            }
        }

    }

    const deleteItem = (inx) => {
        let newItems = productItems.filter((item, itemInx) => inx != itemInx);
        dispatch(setRemoveItem([...newItems]));
    }

    useEffect(() => {
        setErrors(false);
        dispatch(actionGetSubCategoryList(1));
        setDefaultOrg();
        dispatch(setClearGeneralState());
        let data = {
            ...formData,
            scheduled_date: new Date(),
            scheduled_time: new Date()
        }
        dispatch(setFormData(data))
    }, [formModel]);

    useEffect(() => {
        dispatch(actionSearchCustomer(userData.phone_number));
    }, [userData]);

    const setDefaultOrg = () => {
        if (parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.ORGANIZATION) {
            let data = {
                ...formData,
                organization_id: userData.id,
            };

            dispatch(setFormData(data));
            dispatch(actionGetAgentList(userData.id));
        } else if (parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.AGENT) {
            let data = {
                ...formData,
                organization_id: userData.organization_id,
                agent_id: userData.id
            };

            dispatch(setFormData(data));
            dispatch(actionGetAgentList(userData.organization_id));
        }
    }

    // form modal close
    const closeModal = async () => {
        dispatch(setFormModel(false));
    };


    // handling the input changes
    const handleChange = async (e) => {
        let { name, value } = e.target;

        let data = {
            ...formData,
            [name]: value,
        };
        if (name === 'organization_id') {
            dispatch(actionGetAgentList(value));
        }
        dispatch(setFormData(data));
        validateField(name, value);
    };

    const handleItemUpdate = async (inx, e) => {
        const { name, value } = e.target;


        if (name === 'product_id') {
            const productDetails = subCategoryList.filter(subCat => value === subCat.id)[0];
            const metalItem = {
                ...productItems[inx],
                [name]: value,
                loyalty: productDetails.loyalty_percentage,
                per_gram_price: productDetails.per_gram_price,
                certification_need: productDetails.certification_need,
                certification_charges: productDetails.certification_charges,
                product_name: productDetails.sub_category_name
            };
            dispatch(setItemUpdate({ inx, metalItem }));

        } else {
            const metalItem = { ...productItems[inx], [name]: value };
            dispatch(setItemUpdate({ inx, metalItem }));

        }

        if (name === 'product_id') {
            dispatch(actionGetSubCatAttrWithInx(value, inx));
        }
    }

    const fileHandling = (e, itemInx) => {

        const checkFile = e.target.files[0]; // Get the selected file
        if (!checkFile) return;
        const name = e.target.name;

        if (name === 'customer_metal_photo') {
            let ext = getFileExtension(checkFile.name);
            dispatch(setItemFileDocumentPlaceholder({
                name,
                itemInx,
                file: {
                    name: truncateFileName(checkFile.name),
                    type: checkFile.type,
                    ext,
                    size: formatFileSize(checkFile.size),
                    isNew: true
                }
            }));

            const formSDData = new FormData();
            formSDData.append('file', checkFile); // Append the file to the FormData object

            dispatch(actionMultipleUpload(formSDData, itemInx, name));
        }
        else if (name === 'supporting_documents') {
            Array.from(e.target.files).forEach((file) => {
                let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;
                let ext = getFileExtension(file.name);
                dispatch(setItemFileDocumentPlaceholder({
                    name,
                    itemInx,
                    file: {
                        name: truncateFileName(file.name),
                        type: file.type,
                        ext,
                        size: formatFileSize(file.size),
                        isNew: true
                    }, inx
                }));
                const formSDData = new FormData();
                formSDData.append('file', file); // Append the file to the FormData object

                dispatch(actionMultipleUpload(formSDData, itemInx, name, inx));
            });
        }
    }

    const createApplication = () => {
        let order_items = [];
        productItems.forEach(item => {

            
            const customer_metal_photo = item.customer_metal_photo !== null ? item.customer_metal_photo.id : null;
            
            let supporting_documents = [];
            if (Object.values(item.supporting_documents).length > 0) {
                Object.values(item.supporting_documents).map(doc => {
                    if (doc?.isNew) {
                        supporting_documents.push(doc.id);
                    }
                });
            }

            order_items.push({
                loyalty: item.loyalty,
                per_gram_price: item.per_gram_price,
                certification_charges: item.certification_need === 1 ? fixedTwoDigit(item.certification_charges) : 0,
                price: fixedTwoDigit(item.price),
                purity: item.purity,
                weight: fixedTwoDigit(item.weight),
                product_id: item.product_id,
                customer_metal_photo
            });
        });
        let data = {
            customer_contribution: fixedTwoDigit(formData.customer_contribution),
            mc_contribution: fixedTwoDigit(formData.mc_contribution),
            customer_id: selectedCustomer.id,
            agent_id: formData.agent_id,
            location_id: selectedCustomer.location_id,
            organization_id: formData.organization_id,
            scheduled_date: getDatabaseDateOnly(formData.scheduled_date),
            scheduled_time: getDatabaseTimeOnly(formData.scheduled_time),
            order_items
        };
        dispatch(setDisabledButton(true));
        dispatch(updateLoaderStatus(true));
        dispatch(actionCreate(data));
    }

    const handleCapture = (e) => {

        const file = base64ToFile(e);
        if (!file) return;

        const name = cameraUploadFile;
        const itemInx = cameraUploadFileInx;

        let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;
        let ext = 'png';
        dispatch(setItemFileDocumentPlaceholder({
            name,
            itemInx,
            file: {
                name: truncateFileName(file.name),
                type: file.type,
                ext,
                size: formatFileSize(file.size),
                isNew: true
            }, inx
        }));
        const formSDData = new FormData();
        formSDData.append('file', file); // Append the file to the FormData object

        dispatch(actionMultipleUpload(formSDData, itemInx, name, inx));

    }
    const prevLocation = useRef();  
    const handleBackClick = () => {
        if (prevLocation.current === '/trading') {
          navigate('/trading');
        } else {
          navigate('/dashboard');
        }
      };
    return (
        <>

            <div className='row'>
                <div className="col-md-10 mb-3">
                    <div className='d-flex align-items-center'>
                        <IconBack
                            width={42}
                            height={42}
                            onIconClick={formModel ? closeModal : handleBackClick}
                            customClass='cursor-pointer'
                        />
                        <h3 className='ms-2 flex-shrink-1 mb-0'> Create New Trading</h3>
                    </div>
                </div>

            </div>
            <div className='row'>
                <div className="col-12 mt-3">
                    <div className='bg-stepper-line position-relative'>
                        <div className='row stepper'>
                          
                            <div className='col-md-3 col-md-auto mb-2'>

                                <p className='m-0 text-nowrap '>
                                {currentStep > 1 ? (
                                        <IconCurrentStep
                                            width={40}
                                            height={40}
                                            bgColor='#10B981'
                                            customClass='top-2'
                                        />
                                    ) : (<IconCurrentStep
                                        width={40}
                                        height={40}
                                        customClass='top-2'
                                    />)}

                                    <span className='ms-1'>Add Items</span>
                                </p>
                            </div>

                            <div className='col-md-3 col-md-auto mb-2'>
                                <p className='m-0 text-nowrap '>
                                    {currentStep < 2 && (
                                        <>
                                            <span className='default-step me-1 float-start'>02</span>
                                            <span className='ms-1 lh-40'>Select Contribution</span>
                                        </>
                                    )}
                                    {currentStep === 2 && (
                                        <>
                                            <IconCurrentStep
                                                width={40}
                                                height={40}
                                                customClass='top-2'
                                            />
                                            <span className='ms-1'>Select Contribution</span>
                                        </>
                                    )}
                                    {currentStep > 2 && (
                                        <>
                                            <IconCurrentStep
                                                width={40}
                                                height={40}
                                                bgColor='#10B981'
                                                customClass='top-2'
                                            />
                                            <span className='ms-1'>Select Contribution</span>
                                        </>
                                    )}
                                </p>
                            </div>
                            <div className='col-md-3 col-md-auto mb-2'>
                                <p className='m-0 text-nowrap '>
                                    {currentStep < 3 && (
                                        <>
                                            <span className='default-step me-1 float-start'>03</span>
                                            <span className='ms-1 lh-40'>Schedule Pickup</span>
                                        </>
                                    )}
                                    {currentStep === 3 && (
                                        <>
                                            <IconCurrentStep
                                                width={40}
                                                height={40}
                                                customClass='top-2'
                                            />
                                            <span className='ms-1'>Schedule Pickup</span>
                                        </>
                                    )}
                                    {currentStep > 3 && (
                                        <>
                                            <IconCurrentStep
                                                width={40}
                                                height={40}
                                                bgColor='#10B981'
                                                customClass='top-2'
                                            />
                                            <span className='ms-1'>Schedule Pickup</span>
                                        </>
                                    )}
                                </p>
                            </div>
                            <div className='col-md-3 col-md-auto mb-2'>
                                <p className='m-0 text-nowrap '>

                                    {currentStep < 4 && (
                                        <>
                                            <span className='default-step me-1 float-start'>04</span>
                                            <span className='ms-1 lh-40'>Preview</span>
                                        </>

                                    )}
                                    {currentStep === 4 && (
                                        <>
                                            <IconCurrentStep
                                                width={40}
                                                height={40}
                                                customClass='top-2'
                                            />
                                            <span className='ms-1'>Preview</span>
                                        </>
                                    )}
                                    {currentStep > 4 && (
                                        <>
                                            <IconCurrentStep
                                                width={40}
                                                height={40}
                                                bgColor='#10B981'
                                                customClass='top-2'
                                            />
                                            <span className='ms-1'>Preview</span>
                                        </>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

                {/* step content start */}
        

                {/* STEP 1 START */}
                {currentStep === 1 && (
                    <div className='col-12 mt-5'>
                        {productItems.length > 0 && productItems.map((item, index) => (
                            <div key={index + '-item'}>
                                <div className='item-box mb-3'>
                                    <div className='item-box-list position-relative'>
                                        <div className='form-group'>
                                            <label>Enter Metal Details</label>
                                        </div>
                                        <div className='row'>
                                            <div className='col-11 align-self-center'>#{index + 1}</div>
                                            <div className='col-md-2 col-12 mb-2'>
                                                <div className='form-group'>
                                                    <label>Metal Type</label>
                                                    <Dropdown
                                                        value={item.product_id}
                                                        options={subCategoryList}
                                                        optionLabel="sub_category_name"
                                                        optionValue="id"
                                                        placeholder="Select a metal"
                                                        className="w-100 custom-single-dropdown"
                                                        name='product_id'
                                                        onChange={(e) => handleItemUpdate(index, e)}
                                                    />
                                                </div>
                                            </div>

                                            <div className='col-md-2 col-12 '>
                                                <div className='form-group'>
                                                    <label>Weight</label>
                                                    <div className="input-group mb-3">
                                                        <input type="number"
                                                            className="form-control "
                                                            name="weight"
                                                            value={item.weight}
                                                            onChange={(e) => handleItemUpdate(index, e)}
                                                        />
                                                        <span className="input-group-text" id="basic-addon2">Gm</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-3 col-12 '>
                                                <div className='form-group'>
                                                    <label>Purity</label>
                                                    <div className="remove-dd input-group mb-3">
                                                        <Dropdown
                                                            value={item.purity}
                                                            options={item.product_attributes}
                                                            optionLabel="attribute_value"
                                                            optionValue="attribute_value"
                                                            placeholder="Select a purity"
                                                            className="w-50 me-0 custom-single-dropdown"
                                                            name='purity'
                                                            onChange={(e) => handleItemUpdate(index, e)}
                                                        />
                                                        <span className="input-group-text" id="basic-addon2">Carat</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-3 col-12 '>
                                                <div className='form-group'>
                                                    <label> Price (KES)</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" className="form-control "
                                                            disabled={true}
                                                            value={currencyFormatValue(fixedTwoDigit(fixedTwoDigit(item.price) * fixedTwoDigit(item.weight)))}
                                                        />
                                                        <span className="input-group-text" id="basic-addon2">Price</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label>Upload Documents</label>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-3 mb-2'>
                                                <label className='w-100'>Metal Photo</label>
                                                <ButtonDropdown className='mb-2'>
                                                    <ButtonDropdown.Toggle className='btn btn-orange-primary-outline text-orange'>
                                                        Upload
                                                    </ButtonDropdown.Toggle>
                                                    <ButtonDropdown.Menu>
                                                        <ButtonDropdown.Item onClick={e => {
                                                            setCameraUploadFile('customer_metal_photo');
                                                            setCameraUploadFileInx(index);
                                                            dispatch(setShowCamera(true));
                                                        }}>Capture from camera</ButtonDropdown.Item>
                                                         <ButtonDropdown.Item onClick={e => triggerFileMetalInput(index)}>
                                                            Upload from your computer</ButtonDropdown.Item>
                                                    </ButtonDropdown.Menu>
                                                </ButtonDropdown>
                                                <input ref={el => fileMetalPhoto.current[index] = el} onChange={e => fileHandling(e, index)} type='file' name="customer_metal_photo" accept={profileAccept}  className='d-none' />
                                                {item.customer_metal_photo && <SingleFilePreview file={item.customer_metal_photo} />}
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </div>
                        ))}

                        <div className='row mt-5'>
                            <div className="col-6 mt-3">
                                <div className="form-group">
                                    <button
                                        className="btn  btn-purple-primary-outline"
                                        type='button'
                                        onClick={closeModal}
                                    > 
                                        Back
                                    </button>
                                </div>
                            </div>
                            <div className="col-6 mt-3 text-end">
                                <div className="form-group">
                                    <button
                                        className="btn  btn-purple-primary"
                                        type='button'
                                        onClick={gotoNextStep}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* STEP 1 END */}

                {/* STEP 2 START */}
                {currentStep === 2 && (
                    <div className='col-12 mt-5'>

                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <div className='form-group'>
                                    <label>Wallet Contribution</label>
                                </div>
                                <div className='form-group mb-2'>
                                    <label>Customer Contribution</label>
                                    <Dropdown
                                        value={formData.customer_contribution}
                                        options={[{ id: 10, name: '10%' }, { id: 15, name: '15%' }, { id: 20, name: '20%' }, { id: 25, name: '25%' }, { id: 30, name: '30%' }]}
                                        optionLabel="name"
                                        optionValue="id"
                                        placeholder="Select a contribution"
                                        className="w-100 custom-single-dropdown"
                                        name='customer_contribution'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <label className='w-100'>Madini Chapaa Contribution</label>
                                    <label className='form-control'>15%</label>
                                </div>
                            </div>
                            <div className='col-md-9 col-12'>
                                <div className='form-group'>
                                    <label>Estimated Value</label>
                                </div>
                                
                                <ItemDetails
                                    items={productItems}
                                    cc={formData.customer_contribution}
                                    mc={formData.mc_contribution}
                                />
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className="col-6 mt-3">
                                <div className="form-group">
                                    <button
                                        className="btn  btn-purple-primary-outline"
                                        type='button'
                                        onClick={e => setCurrentStep(currentStep - 1)}
                                    >
                                        Back
                                    </button>
                                </div>
                            </div>
                            <div className="col-6 mt-3 text-end">
                                <div className="form-group">
                                    <button
                                        className="btn  btn-purple-primary"
                                        type='button'
                                        onClick={e => setCurrentStep(currentStep + 1)}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* STEP 2 END */}

                {/* STEP 3 START */}
                {currentStep === 3 && (
                    <div className='col-12 mt-5'>
                        <div className='row'>
                        <div className="col-md-12">
                                <h6><strong>Assignee</strong></h6>
                                <hr />
                            </div>
                            <div className="col-md-3">
                                <div className="form-group mb-3">
                                    <label>Organisation</label>
                                    <Dropdown
                                        disabled={true}
                                        value={formData.organization_id}
                                        options={organizationList}
                                        optionLabel="organization_name"
                                        optionValue="id"
                                        placeholder="Select a organisation"
                                        className="w-100 custom-single-dropdown"
                                        name='organization_id'
                                    />

                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group mb-3">
                                    <label>Agent</label>

                                    <Dropdown
                                        disabled={true}
                                        value={formData.agent_id}
                                        options={agentList}
                                        optionLabel="full_name"
                                        optionValue="id"
                                        placeholder="Select a agent"
                                        className="w-100 custom-single-dropdown"
                                        name='agent_id'
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 mt-5">
                                <h6><strong>Pick up schedule</strong></h6>
                                <hr />
                            </div>
                            <div className="col-md-3">
                                <div className="form-group mb-3">
                                    <label>Schedule Date</label>
                                    <Calendar
                                        value={formData.scheduled_date ? new Date(formData.scheduled_date) : ''}
                                        onChange={handleChange}
                                        dateFormat="dd/mm/yy"
                                        name="scheduled_date"
                                        className='date-picker-input'
                                        minDate={new Date()}
                                        showIcon
                                    />

                                    {errors.scheduled_date && <span className="error-message">{errors.scheduled_date}</span>}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group mb-3">
                                    <label>Schedule Time</label>
                                    <Calendar
                                        value={formData.scheduled_time ? new Date(formData.scheduled_time) : ''}
                                        onChange={handleChange}
                                        name="scheduled_time"
                                        className='date-picker-input'
                                        showIcon timeOnly icon={() => <IconTime />}
                                        showTime
                                        hourFormat="12"
                                    />

                                    {errors.scheduled_time && <span className="error-message">{errors.scheduled_time}</span>}
                                </div>
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className="col-6 mt-3">
                                <div className="form-group">
                                    <button
                                        className="btn  btn-purple-primary-outline"
                                        type='button'
                                        onClick={e => setCurrentStep(currentStep - 1)}
                                    >
                                        Back
                                    </button>
                                </div>
                            </div>
                            <div className="col-6 mt-3 text-end">
                                <div className="form-group">

                                    <button
                                        className="btn  btn-purple-primary"
                                        type='button'
                                        onClick={gotoNextStep}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                )}
                {/* STEP 3 END */}

                {/* STEP 4 START */}
                {currentStep === 4 && (
                    <div className='col-12 mt-5'>
                        <div className='row'>
                            <div className='col-md-12 col-12 trade-customer-section mb-3'>
                                {selectedCustomer && (<>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className='row'>
                                                <div className='col-3 align-self-center'>
                                                    {customerProfile ? (
                                                        <div className='placeholder-image-bg'>
                                                            <img src={`data:${customerProfile?.type};base64,${customerProfile?.base64}`} />
                                                        </div>
                                                    ) : (
                                                        <div className='placeholder-image-bg'>
                                                            <img src={`${ENVIRONMENT.BASE_URL}assets/images/profile.png`} />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='col-md-9 col-12 align-self-center'>
                                                    <p className='m-0'><strong>Customer ID: {selectedCustomer.row_ref_number}</strong></p>
                                                    <p className='m-0 gray-color'>{selectedCustomer.first_name} {selectedCustomer.middle_name} {selectedCustomer.last_name}</p>
                                                    <p className='m-0 gray-color'>{selectedCustomer.email}</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Location</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.location_name}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>National Identity Number</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.national_id}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Serial Number</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.serial_number}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Phone Number</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.phone_number}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Gender</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.gender}</label>
                                            </div>
                                        </div>

                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Citizenship</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.citizen_ship}</label>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label className='w-100'>Date of Birth</label>
                                                <label className='w-100 gray-color'>{getDisplayDateOnly(selectedCustomer.dob)}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Marital Status</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.marital_status}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Address</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.address}</label>
                                            </div>
                                        </div>

                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Next of kin Name</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.nominee_name}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Next of kin Relationship</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.nominee_relationship}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Next of kin Phone Number</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.nominee_phone_number}</label>
                                            </div>
                                        </div>
                                     
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Occupation</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.occupation}</label>
                                            </div>
                                        </div>
                                    </div>
                                </>)}
                            </div>
                            <hr />
                            <p className='item-header'><strong>Assignee</strong></p>
                            <div className='col-4 mb-4'>
                                <div className='form-group'>
                                    <label className='w-100'>Organisation</label>
                                    <label className='w-100 gray-color'>{organizationList && formData.organization_id && organizationList.map(org => org.id === formData.organization_id && (org.organization_name))}</label>
                                </div>
                            </div>
                            <div className='col-md-4 col-12 mb-4'>
                                <div className='form-group'>
                                    <label className='w-100'>Agent</label>
                                    <label className='w-100 gray-color'>{agentList && formData.agent_id && agentList.map(agent => agent.id === formData.agent_id && (agent.full_name))}</label>
                                </div>
                            </div>
                            <hr />
                            <p className='item-header'><strong>Pick up schedule</strong></p>
                            <div className='col-md-4 col-12 mb-4'>
                                <div className='form-group'>
                                    <label className='w-100'>Pick-up Date</label>
                                    <label className='w-100 gray-color'>{getDisplayDateOnly(formData.scheduled_date)}</label>
                                </div>
                            </div>
                            <div className='col-md-4 col-12 mb-4'>
                                <div className='form-group'>
                                    <label className='w-100'>Pick-up Time</label>
                                    <label className='w-100 gray-color'>{getDisplayTimeOnly(formData.scheduled_time)}</label>
                                </div>
                            </div>
                            <div className='clearfix'></div>
                        </div>
                        <hr />

                        <p className='item-header'><strong>Charges</strong></p>
                        <ItemDetails
                            items={productItems}
                            cc={formData.customer_contribution}
                            mc={formData.mc_contribution}
                        />

                        <div className='row mt-5'>
                            <div className="col-md-6 col-5 mt-3">
                                <div className="form-group">
                                    <button
                                        className="btn  btn-purple-primary-outline"
                                        type='button'
                                        onClick={e => setCurrentStep(currentStep - 1)}
                                    >
                                        Back
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6 col-7 mt-3 text-end">
                                <div className="form-group">

                                    <button
                                        className="btn  btn-purple-primary"
                                        type='button'
                                        onClick={createApplication}
                                    >
                                        Create Application
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* STEP 4 END */}
            </div >
            {/* step content end */}
            <CameraUpload onImgCallBack={handleCapture} />
            <FilePreview />
        </>
    );
};


export default TradeingFormModel;
