import React from 'react'
import { ORDER_STATUS } from "../config/constant"

const GetOrderStatus = ({status})=>{
    switch (status) {
        case ORDER_STATUS.NEW.id:
          return <span className='badge order-status-new-bg'>{ORDER_STATUS.NEW.text}</span>
        case ORDER_STATUS.INPROGRESS.id:
          return <span className='badge order-status-inprogress-bg'>{ORDER_STATUS.INPROGRESS.text}</span>
        case ORDER_STATUS.REJECTED.id:
          return <span className='badge order-status-reject-bg'>{ORDER_STATUS.REJECTED.text}</span>
        case ORDER_STATUS.DISBURSAL.id:
          return <span className='badge order-status-disbursal-bg'>{ORDER_STATUS.DISBURSAL.text}</span>
        case ORDER_STATUS.SETTLED.id:
          return <span className='badge order-status-settled-bg'>{ORDER_STATUS.SETTLED.text}</span>
      }    
}

export default GetOrderStatus;