

// impor the libraries
import { createSlice } from '@reduxjs/toolkit'

import { DEFAULT_USER_ROLE, RECORD_STATUS } from '../../config/constant';

const defaultFormData = {
  first_name: '',
  middle_name: '',
  last_name: '',
  national_id: '',
  email: '',
  phone_number: '',
  work_experience: '',
  designation: '',
  user_role_id: DEFAULT_USER_ROLE.ADMIN,
  active: RECORD_STATUS.ACTIVE.id
};

// user default initial state
const initialState = {
  formData: defaultFormData,
  formModel: false,
  updateId: null
}

// create a slice and reducers
const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setFormModel: (state, action) => {
      state.formModel = action.payload;
    },
    setUpdateId: (state, action) => {
      state.updateId = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setDefaultFormData: (state, action) => {
      state.formData = defaultFormData;
    },
    setClearAdminState: (state, action) => {
      state.formData = defaultFormData;
      state.formModel = false;
      state.updateId = null;
    }

  },
})
// export the user module
export default adminSlice.reducer;

export const {
  setFormModel,
  setUpdateId,
  setFormData,
  setDefaultFormData,
  setClearAdminState
} = adminSlice.actions;