//reducer
import {
    setAwaitingCount,
    setComment,
    setCurrentItem,
    setCustomerProfile,
    setFormModel,
    setItemAttributes,
    setItemFileData,
    setItemFileDocumentData,
    setPaymentDisbursal,
    setPaymentDisbursalMsg,
    setReAssignFormData,
    setSelectedCustomer,
    setShowComment,
    setTradeDetails,
    setUpdateId
} from "./reducer";
import { updateLoaderStatus, setTableLoader, setDisabledButton, setFilePreview, setFilePreviewModel, setFileData, setBulkSupportingDocumentData } from '../general/reducer';
import { setTableData } from "../table/reducer";

// others
import { DEFAULT_USER_ROLE, HTTP_STATUS_CODE, PERMISSION_KEY, PERMISSION_PAGE_KEY, TABLES, TOAST_TYPE, validatePermission } from '../../config/constant';
import { showToast } from "../../helper/toast-service-helper";

//services
import tradeService from '../../services/trade';
import { actionGetFileById } from "../general/action";
import customerService from '../../services/customer';
import paymentService from '../../services/payment';
import subCategoryAttributeService from '../../services/sub-category-attribute';
import fileService from '../../services/file';
import { formatFileSize, handingApiErrors, truncateFileName } from "../../helper/common-helper";
import { actionGetAgentList } from "../agent/action";
import {  getDisplayTimeParase } from "../../helper/date-helper";

// export user create action
export const actionCreate = (data) => {
    return async (dispatch, getState) => {
        const response = await tradeService.create(data);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        await dispatch(actionGetAll());
        await dispatch(setFormModel(false));

        showToast(TOAST_TYPE.SUCCESS, 'Application Creation Completed Successfully', 'top-right');

    }
}


// export user get all action
export const actionGetAll = (customerId) => {
    return async (dispatch, getState) => {

        const { defaultUserPermission, userData } = getState().auth;

        if (!validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.TRADING, PERMISSION_KEY.READ) && userData.user_role_id !== DEFAULT_USER_ROLE.CUSTOMER) {
            return;
        }

        let getParams = getState().table[TABLES.TRADE];
        let tradeParams = getState().trade;
        console.log(getParams)
        const data = {
            offset: getParams.offset,
            limit: getParams.limit,
            sort:getParams.sort,
            order:getParams.order,
            search:tradeParams.search
        };
        if(customerId){
            data.customer_id = customerId;
        }
        const response = await tradeService.getAll(data);
        // dispatch(updateLoaderStatus(false));
        dispatch(setTableLoader(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        await dispatch(setTableData({
            data: response.data.data.orders,
            totalRecord: response.data.data.total_count,
            tableKey: TABLES.TRADE
        }));

    }
}

export const actionGetById = (id) => {
    return async (dispatch, getState) => {
        const response = await tradeService.getById(id);
        dispatch(updateLoaderStatus(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        const data = response.data.data.order;
        await dispatch(setUpdateId(data.id));
        await dispatch(actionGetAgentList(data.organization_id));

        await dispatch(setReAssignFormData({
            order_id: data.id,
            agent_id: data.agent_id,
            organization_id: data.organization_id,
            scheduled_date: data.scheduled_date,
            scheduled_time: getDisplayTimeParase(data.scheduled_time)
        }));

        await dispatch(setTradeDetails(data));

    }
}
export const actionGetOrderItemById = (slug) => {
    return async (dispatch, getState) => {
        const response = await tradeService.getOrderItemBySlug(slug);
        dispatch(updateLoaderStatus(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        const data = response.data.data.order_item_details;
        await dispatch(setUpdateId(data.id));


        await dispatch(setCurrentItem(data));
        if (data.customer_photo) {
            await dispatch(actionGetFileById(data.customer_photo.slug, 'customer_photo'));
        } else {
            await dispatch(setFileData({ name: 'customer_photo', value: null }));

        }
        if (data.customer_metal_photo) {
            await dispatch(actionGetFileById(data.customer_metal_photo.slug, 'customer_metal_photo'));
        } else {
            await dispatch(setFileData({ name: 'customer_metal_photo', value: null }));

        }
        if (data.metal_photo) {
            await dispatch(actionGetFileById(data.metal_photo.slug, 'metal_photo'));
        } else {
            await dispatch(setFileData({ name: 'metal_photo', value: null }));

        }
        if (data.weight_photo) {
            await dispatch(actionGetFileById(data.weight_photo.slug, 'weight_photo'));
        } else {
            await dispatch(setFileData({ name: 'weight_photo', value: null }));

        }
        if (data.purity_photo) {
            await dispatch(actionGetFileById(data.purity_photo.slug, 'purity_photo'));
        } else {
            await dispatch(setFileData({ name: 'purity_photo', value: null }));

        }

        let supportingDocuments = {};
        if (data?.supporting_documents && (data.supporting_documents).length > 0) {
            data.supporting_documents.forEach(file => {

                let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;

                let fileDetails = {
                    ...file,
                    size: formatFileSize(file.file_size),
                    name: truncateFileName(file.original_name)
                };

                supportingDocuments[inx] = fileDetails;
            });

            dispatch(setBulkSupportingDocumentData(supportingDocuments));

        }
    }

}

export const actionSearchCustomer = (search) => {

    return async (dispatch, getState) => {
        const response = await customerService.searchCustomer(search);
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        const data = response.data.data.customer;
        if (data) {
            await dispatch(actionGetAgentList(data.organization_id))
        }
        await dispatch(setSelectedCustomer(data ? data : false));
        if (response.data.data?.customer && data.profile) {
            await dispatch(actionGetFileById(data.profile.slug, 'customer-profile'));
        } else {
            await dispatch(setCustomerProfile(null));

        }

        dispatch(updateLoaderStatus(false));
    }
}

export const actionGetSubCatAttrWithInx = (subCatId, inx) => {
    return async (dispatch, getState) => {
        const response = await subCategoryAttributeService.list(subCatId);
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        await dispatch(setItemAttributes({ inx, attributes: response.data.data.sub_category_attributes }));

    }
}


export const actionSingleUpload = (data, inx, name) => {
    return async (dispatch, getState) => {
        const response = await fileService.upload(data);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        await dispatch(setItemFileData({
            data: response.data,
            inx,
            name
        }));

    }
}
export const actionMultipleUpload = (data, itemInx, name, inx) => {
    return async (dispatch, getState) => {
        const response = await fileService.uploadItemDoc(data, itemInx, name, inx);
        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        let fileDetails = {
            ...response.data.file,
            size: formatFileSize(response.data.file.file_size),
            base64: response.data.base64,
            isNew: true
        };
        dispatch(setItemFileDocumentData({ data: { ...fileDetails }, inx, itemInx, name }));

    }
}

export const actionFileById = (id) => {
    return async (dispatch, getState) => {
        const response = await fileService.getById(id);
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        dispatch(setFilePreview(response.data));
        dispatch(setFilePreviewModel(true));

    }
}


export const actionUpdateItemStatus = (data, orderId, navigate) => {
    return async (dispatch, getState) => {
        const response = await tradeService.updateItemStatus(data);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        await dispatch(actionGetById(orderId));
        await dispatch(setCurrentItem(null));
        await dispatch(setShowComment(false));
        await dispatch(setComment(""));
        await dispatch(actionGetAwaiting())
        navigate(-1);

    }
}
export const actionDisbursal = (orderSlug) => {
    return async (dispatch, getState) => {
        const response = await paymentService.dispursal(orderSlug);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        dispatch(setPaymentDisbursal(false));
        dispatch(setPaymentDisbursalMsg(true));

    }
}

export const actionGetAwaiting = () => {
    return async (dispatch, getState) => {
        const response = await tradeService.getAwaiting();
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        dispatch(setAwaitingCount(response.data.data.count));

    }
}

export const actionReassignOrder = (data) => {
    return async (dispatch, getState) => {
        const response = await tradeService.reAssignOrder(data);
        dispatch(updateLoaderStatus(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        window.location.reload();

    }
}