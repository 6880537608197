// get axios api config
import api from './api';

// get axios options
import { axiosOptions } from '../helper/common-helper';

// export the functions
export default {
    
    // get customer wallet by id
    async getById(id) {
        return await api().get('service-one/wallet/' + id, await axiosOptions());

    }

}
