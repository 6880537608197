// Import files and libraries
import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { Dropdown } from "primereact/dropdown";
import Accordion from "react-bootstrap/Accordion";
import { Dropdown as ButtonDropdown } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap";

//others
import {
  IconBack,
  IconCustomerContribution,
  IconEdit,
  IconMcContribution,
  IconWallet,
  Spinner,
} from "../../../svg-icons";

// reducer
import {
  setFormModel,
  setFormData,
  setUpdateId,
  setDefaultFormData,
} from "../../../store/customer/reducer";
import { setTradeDetails } from '../../../store/trade/reducer';
import {
  setDisabledButton,
  setProDocDefault,
  setProfileLoader,
  setShowCamera,
  setSupportingDocumentPlaceholder,
  setTableLoader,
  updateLoaderStatus,
} from "../../../store/general/reducer";

// action
import {
  actionCreate,
  actionUpdate,
  actionValidateNationalId,
} from "../../../store/customer/action";

// custom component
import FilePreview from "../../../component/file-preview";
import {
  base64ToFile,
  checkFileUpload,
  checkFileUploadProfile,
  currencyFormatValue,
  fileAccept,
  formatFileSize,
  getFileExtension,
  profileAccept,
} from "../../../helper/common-helper";
import {
  ATTACHMENT_TYPE,
  DEFAULT_USER_ROLE,
  ENVIRONMENT,
  MARITAL_STATUS_LIST,
  PERMISSION_KEY,
  PERMISSION_PAGE_KEY,
  RECORD_STATUS,
  REGEXP,
  TOAST_TYPE,
  validatePermission,
} from "../../../config/constant";

import { showToast } from "../../../helper/toast-service-helper";
import { actionFileUpload } from "../../../store/general/action";
import MultipleFilePreview from "../../../component/multiple-file-upload";
import FormStatus from "../../../component/form-status";
import { actionGetAgentList } from "../../../store/agent/action";
import { actionGetOrgLocationList } from "../../../store/organization/action";
import { getDisplayDateOnly } from "../../../helper/date-helper";
import CameraUpload from "../../../component/camera-upload";

import DataTableComponent from "../../../component/data-table";
import { actionGetCustomerWallet } from "../../../store/customer/action";
import { TABLES } from "../../../config/constant";
import GetOrderStatus from "../../../component/order-status";

import { getDisplayDateTime } from "../../../helper/date-helper";

import { useNavigate } from "react-router-dom";
import { actionGetAll } from "../../../store/trade/action";
import { actionSendOtp } from "../../../store/auth/actions";


const CustomerFormModel = () => {
  // create a dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // create a state
  const [errors, setErrors] = useState({});
  const [editForm, setEditForm] = useState(false);
  const [cameraUploadFile, setCameraUploadFile] = useState("");

  const fileProfile = useRef(null);
  // Function to trigger file input click
  const handleFileProfileUploadClick = () => {
    fileProfile.current.click(); // Trigger the file input click
  };
  const fileSD = useRef(null);
  // Function to trigger file input click
  const handleFilefileSDUploadClick = () => {
    fileSD.current.click(); // Trigger the file input click
  };

  const { formData, updateId, formModel, validatedNationalId, validatedPhoneNumber } = useSelector(
    (state) => state.customer
  );

  const { organizationList, orgLocationList } = useSelector(
    (state) => state.organization
  );
  const { agentList } = useSelector((state) => state.agent);
  const { disabledButton, profile, supportingDocuments, profileLoader } =
    useSelector((state) => state.general);
  const { defaultUserPermission, userData } = useSelector(
    (state) => state.auth
  );

  const { sort, order, limit, offset, totalRecords } = useSelector(
    (state) => state.table[TABLES.TRADE]
  );

  const { customerWallet } = useSelector((state) => state.customer);
  const [tabKey, setTabKey] = useState("basicInfo");

  // On page function call get the page data and if any table action changes call the api
  useEffect(() => {

    if (tabKey === "wallet") {
      dispatch(actionGetCustomerWallet(updateId));
    } else if (tabKey === "list") {
      // call the api function
      dispatch(setTableLoader(true));
      dispatch(actionGetAll(updateId));
    }
  }, [limit, offset, sort, order, tabKey]);

  const tableColumns = [
    {
      field: "row_ref_number",
      header: "TRADING ID",
    },
    {
      field: "",
      header: "CUSTOMER NAME",
      body: (row) => {
        return `${row.customer_first_name} ${row.customer_middle_name} ${row.customer_last_name}`;
      },
    },
    {
      field: "",
      header: "AGENT NAME",
      body: (row) => {
        return `${row.agent_first_name} ${row.agent_middle_name} ${row.agent_last_name}`;
      },
    },

    {
      field: "item_count",
      header: "NO OF TRADING ITEMS",
      headerAlign: "text-end",
      bodyAlign: "text-end",
    },

    {
      sortable: false,
      field: "status",
      header: "STATUS",
      headerAlign: "text-center",
      bodyAlign: "text-center",
      body: (row) => {
        return <GetOrderStatus status={row.order_status} />;
      },
    },
    {
      sortable: false,
      field: "created_at",
      header: "DATE",
      headerAlign: "text-end",
      bodyAlign: "text-end",
      body: (row) => {
        return getDisplayDateTime(row.created_at);
      },
    },
  ];
  const selectedRow = (e) => {
    dispatch(setTradeDetails(null));
    navigate("/portal/trading/details?type=order&view=" + e.data.slug);
  }
  useEffect(() => {
    setErrors({});
    setEditForm(updateId ? false : true);
    setDefaultOrg();
  }, [formModel]);

  const setDefaultOrg = () => {
    if (parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.ORGANIZATION) {
      let data = {
        ...formData,
        organization_id: userData.id,
      };
      dispatch(actionGetOrgLocationList(userData.id));
      dispatch(setFormData(data));
      dispatch(actionGetAgentList(userData.id));
    } else if (parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.AGENT) {
      let data = {
        ...formData,
        organization_id: userData.organization_id,
        agent_id: userData.id,
      };
      dispatch(actionGetOrgLocationList(userData.organization_id));

      dispatch(setFormData(data));
      dispatch(actionGetAgentList(userData.organization_id));
    }
  };

  // form validation schema
  let schema = Yup.object().shape({
    first_name: Yup.string()
      .min(1, 'First name must be at least 1 character long')
      .max(50, 'First name must be at most 50 characters long')
      .matches(REGEXP.alphabetRegExp, 'First name can only contain alphabetic characters') // Allows letters and spaces only
      .matches(REGEXP.noConsecutiveSpaceRegExp, 'First name cannot contain consecutive spaces') // Prevents multiple consecutive spaces
      .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
      .trim()
      .required('First name is required'),
    last_name: Yup.string()
      .min(1, 'last name must be at least 1 character long')
      .max(50, 'last name must be at most 50 characters long')
      .matches(REGEXP.alphabetRegExp, 'last name can only contain alphabetic characters') // Allows letters and spaces only
      .matches(REGEXP.noConsecutiveSpaceRegExp, 'last name cannot contain consecutive spaces') // Prevents multiple consecutive spaces
      .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
      .trim()
      .required('Last name is required'),
    middle_name: Yup.string()
      .min(1, 'middle name must be at least 1 character long')
      .max(50, 'middle name must be at most 50 characters long')
      .matches(REGEXP.alphabetRegExp, 'middle name can only contain alphabetic characters') // Allows letters and spaces only
      .matches(REGEXP.noConsecutiveSpaceRegExp, 'middle name cannot contain consecutive spaces') // Prevents multiple consecutive spaces
      .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
      .trim()
      .required('Middle name is required'),
    email: Yup.string()
      .max(50, "Email must be at most 50 characters long")
      .matches(REGEXP.emailRegExp, "Invalid email address")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(REGEXP.phoneNumberRegExp, "Phone number is not valid")
      .max(15, "Phone number must be at most 15 characters long")
      .min(7, "Phone number must be at least 7 characters long")
      .required("Phone number is required"),
    national_id: Yup.string()
      .matches(REGEXP.numberRegExp, "National ID is not valid")
      .min(5, 'National ID must be at least 5 character long')
      .max(10, "National ID must be at most 10 characters long")
      .required("National ID is required"),
    marital_status: Yup.string().required("Marital Status is required"),
    occupation: Yup.string()
      .min(1, 'occupation must be at least 1 character long')
      .max(50, 'occupation must be at most 50 characters long')
      .matches(REGEXP.alphabetRegExp, 'occupation can only contain alphabetic characters') // Allows letters and spaces only
      .matches(REGEXP.noConsecutiveSpaceRegExp, 'occupation cannot contain consecutive spaces') // Prevents multiple consecutive spaces
      .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
      .trim()
      .required("Occupation is required"),
    nominee_name: Yup.string()
      .min(1, 'Next of Kin name must be at least 1 character long')
      .max(50, 'Next of Kin  name must be at most 50 characters long')
      .matches(REGEXP.alphabetRegExp, 'Next of Kin  name can only contain alphabetic characters') // Allows letters and spaces only
      .matches(REGEXP.noConsecutiveSpaceRegExp, 'Next of Kin  name cannot contain consecutive spaces') // Prevents multiple consecutive spaces
      .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
      .trim()
      .required('Next of Kin name is required'),
    nominee_relationship: Yup.string()
      .min(1, 'Next of Kin relationship must be at least 1 character long')
      .max(50, 'Next of Kin  relationship must be at most 50 characters long')
      .matches(REGEXP.alphabetRegExp, 'Next of Kin  relationship can only contain alphabetic characters') // Allows letters       and spaces only
      .matches(REGEXP.noConsecutiveSpaceRegExp, 'Next of Kin  relationship cannot contain consecutive spaces') // Prevents multiple consecutive spaces
      .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
        .required("Next of Kin Relationship is required"),
    nominee_phone_number: Yup.string()
      .matches(REGEXP.phoneNumberRegExp, "Phone number is not valid")
      .max(15, "Phone number must be at most 15 characters long")
      .min(7, "Phone number must be at least 7 characters long")
      .required("Next of Kin Phone number is required"),
    address: Yup.string()
      .trim()
      .max(255, 'Address must be at most 255 characters long')
      .test('not-only-numbers', 'Address cannot be only numbers', (value) => {
          return !/^[0-9]+$/.test(value); // Ensure the address is not only numbers
      })
      .test('contains-letter-or-number', 'Address must contain at least one letter', (value) => {
          return /[a-zA-Z]/.test(value); // Ensure there is at least one letter or number
      })
        .required("Address is required"),
    // remarks:
    //     Yup.string()
    //         .max(255, 'Remarks must be at most 255 characters long')
    //         .required('Remarks is required'),
    location_id: Yup.string().required("Location is required"),
    organization_id: Yup.string().required("Organisation is required"),
    agent_id: Yup.string().required("Agent is required"),
  });

  // form modal close
  const closeModal = async () => {
    dispatch(setFormModel(false));
  };

  //validate the fields
  const validateField = async (name, value) => {
    try {
      await Yup.reach(schema, name).validate(value);
      setErrors({ ...errors, [name]: "" });
    } catch (validationError) {
      setErrors({ ...errors, [name]: validationError.message });
    }
  };

  //validate the form
  const validateForm = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationErrors) {
      const newErrors = validationErrors.inner.reduce((acc, err) => {
        acc[err.path] = err.message;
        return acc;
      }, {});
      setErrors(newErrors);
      return false;
    }
  };

  //handling the form submition
  const handleSubmit = async (e) => {
    // prevent the form submission
    e.preventDefault();

    // get the form status
    const isValid = await validateForm();

    if (isValid) {

      if (!validatedNationalId) {
        showToast(TOAST_TYPE.ERROR, "Please validate the National ID", 'top-right');
      }
      if (!validatedPhoneNumber) {
        showToast(TOAST_TYPE.ERROR, "Please verify the M-Pesa Phone Number", 'top-right');
      }
      if (!validatedNationalId || !validatedPhoneNumber) {
        return;
      }

      // check the form is valid or invalid
      dispatch(setDisabledButton(true));
      dispatch(updateLoaderStatus(true));

      let supporting_documents = [];
      if (Object.values(supportingDocuments).length > 0) {
        Object.values(supportingDocuments).map((doc) => {
          if (doc?.isNew) {
            supporting_documents.push(doc.id);
          }
        });
      }

      const finalData = {
        first_name: formData.first_name,
        middle_name: formData.middle_name,
        last_name: formData.last_name,
        national_id: formData.national_id == '123456789' ? formData.national_id + Math.floor(10000 + Math.random() * 90000) : formData.national_id,
        email: formData.email,
        phone_number: formData.phone_number,
        citizen_ship: formData.citizen_ship,
        cladfy_request_id: formData.cladfy_request_id,
        serial_number: formData.serial_number,
        dob: formData.dob,
        gender: formData.gender,
        marital_status: formData.marital_status,
        occupation: formData.occupation,
        nominee_name: formData.nominee_name,
        nominee_relationship: formData.nominee_relationship,
        nominee_phone_number: formData.nominee_phone_number,
        address: formData.address,
        // remarks: formData.remarks,
        location_id: formData.location_id,
        profile: profile !== null ? profile.file.id : null,
        active: formData.active,
        organization_id: formData.organization_id,
        agent_id: formData.agent_id,
        supporting_documents,
      };

      if (updateId) {
        //check the form action update or create
        // update data with update id
        dispatch(actionUpdate(finalData, updateId));
      } else {
        // create form data
        dispatch(actionCreate(finalData));
      }
    } else {
      console.log("Form has errors:", errors);
    }
  };

  // handling the input changes
  const handleChange = async (e) => {
    let { name, value } = e.target;

    if (name === "dob") {
      value = getDisplayDateOnly(value);
    }
    if (name === "organization_id") {
      dispatch(actionGetAgentList(value));

      dispatch(actionGetOrgLocationList(value));
    }
    let data = {
      ...formData,
      [name]: value,
    };

    dispatch(setFormData(data));
    validateField(name, value);
  };

  const validateNationalId = (e) => {

    if (e.trim() != "") {
      dispatch(actionValidateNationalId(formData.national_id));
    } else {
      showToast(TOAST_TYPE.ERROR, "Provide the National ID to validate", 'top-right');
    }

  };
  const validatePhoneNumber = (e) => {

    if (e.trim() != "" && (formData.phone_number).length >= 7) {
      dispatch(actionSendOtp(formData.phone_number));
    } else {
      showToast(TOAST_TYPE.ERROR, "Provide the M-Pesa Phone Number", 'top-right');
    }

  };

  const uploadFile = (e) => {
    const { name } = e.target;
    const checkFile = e.target.files[0]; // Get the selected file
    if (!checkFile) return;
    if (name === "profile") {
      const ckf = checkFileUploadProfile(checkFile);
      if (ckf) {
        const formData = new FormData();
        formData.append("file", checkFile);
        dispatch(setProfileLoader(true));
        dispatch(actionFileUpload(formData));
      }
    } else {
      Array.from(e.target.files).forEach((file) => {
        const ckf = checkFileUpload(file);
        if (ckf) {
          let inx = "file_" + Math.floor(Math.random() * (999 - 100 + 1)) + 100;
          let ext = getFileExtension(file.name);
          dispatch(
            setSupportingDocumentPlaceholder({
              inx,
              file: {
                name: file.name,
                type: file.type,
                ext,
                size: formatFileSize(file.size),
                isNew: true,
              },
            })
          );
          const formSDData = new FormData();
          formSDData.append("file", file); // Append the file to the FormData object

          dispatch(actionFileUpload(formSDData, inx, name));
        }
      });
    }
  };

  const handleCapture = (e) => {
    const file = base64ToFile(e);
    if (!file) return;
    const formData = new FormData();
    formData.append("file", file);
    if (cameraUploadFile === "profile") {
      dispatch(setProfileLoader(true));
      dispatch(actionFileUpload(formData));
    } else {
      let inx = "file_" + Math.floor(Math.random() * (999 - 100 + 1)) + 100;
      let ext = "png";
      dispatch(
        setSupportingDocumentPlaceholder({
          inx,
          file: {
            name: file.name,
            type: file.type,
            ext,
            size: formatFileSize(file.size),
            isNew: true,
          },
        })
      );
      const formSDData = new FormData();
      formSDData.append("file", file); // Append the file to the FormData object

      dispatch(
        actionFileUpload(
          formSDData,
          inx,
          ATTACHMENT_TYPE.ADMIN_SUPPORT_DOCUMENT
        )
      );
    }
  };

  const renderForm = (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-md-12">
          <div className="row d-flex justify-content-between">
            {/* Profile and Upload Section */}
            <div className="col-md-auto d-flex">
              {/* Profile Section */}
              <div className="col-md-auto pb-3">
                {profile ? (
                  <div className="placeholder-image-bg position-relative">
                    <img
                      src={`data:${profile?.type};base64,${profile?.base64}`}
                      alt="Profile"
                    />
                    {profileLoader && (
                      <span className="profile-loader">
                        <Spinner width={36} height={36} />
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="placeholder-image-bg position-relative">
                    <img
                      src={`${ENVIRONMENT.BASE_URL}assets/images/profile.png`}
                      alt="Default Profile"
                    />
                    {profileLoader && (
                      <span className="profile-loader">
                        <Spinner width={36} height={36} />
                      </span>
                    )}
                  </div>
                )}
              </div>

              {/* File Upload Section */}
              {editForm && (
                <div className="col-md-auto align-self-center ms-3">
                  <input
                    accept={profileAccept}
                    type="file"
                    ref={fileProfile}
                    name="profile"
                    onChange={uploadFile}
                    className="d-none"
                  />
                  <ButtonDropdown>
                    <ButtonDropdown.Toggle className="btn btn-orange-primary">
                      Upload
                    </ButtonDropdown.Toggle>
                    <ButtonDropdown.Menu>
                      <ButtonDropdown.Item
                        onClick={() => {
                          setCameraUploadFile("profile");
                          dispatch(setShowCamera(true));
                        }}
                      >
                        Capture from camera
                      </ButtonDropdown.Item>
                      <ButtonDropdown.Item
                        onClick={handleFileProfileUploadClick}
                      >
                        Upload from your computer
                      </ButtonDropdown.Item>
                    </ButtonDropdown.Menu>
                  </ButtonDropdown>
                </div>
              )}
            </div>

            {/* Edit Button Section */}
            {updateId &&
              !editForm &&
              validatePermission(
                defaultUserPermission,
                PERMISSION_PAGE_KEY.AGENT,
                PERMISSION_KEY.UPDATE
              ) && (
                <div className="col-md-2 mb-3 text-end">
                  <button
                    className="btn  btn-purple-primary me-3"
                    type="button"
                    onClick={(e) => setEditForm(true)}
                  >
                    <IconEdit customClass="text-white" /> Edit
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
      <Accordion
        defaultActiveKey={["0", "1"]}
        alwaysOpen
        className="custom-accordiant"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <strong>Customer information</strong>
          </Accordion.Header>
          <Accordion.Body>


            <div className="row">
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>National Identity Number</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className={`form-control ${validatedNationalId ? 'is-valid' : ''}`}
                      name="national_id"
                      disabled={!editForm}
                      value={formData.national_id}
                      onChange={handleChange}
                      placeholder="Enter the national ID"
                    />
                    {!validatedNationalId && (<span
                      className="input-group-text cursor-pointer"
                      onClick={(e) =>
                        validateNationalId(formData.national_id)
                      }
                    >
                      Validate
                    </span>)}
                  </div>
                  {errors.national_id && (
                    <span className="error-message">
                      {errors.national_id}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>M-Pesa Phone Number</label>
                  <div className="input-group">
                    <input type="text"
                      className={`form-control ${validatedPhoneNumber ? 'is-valid' : ''}`}
                      name="phone_number"
                      disabled={validatedPhoneNumber}
                      placeholder='Enter the phone number'
                      value={formData.phone_number}
                      onChange={handleChange}
                    />
                    {!validatedPhoneNumber && (<span className="input-group-text cursor-pointer"
                      onClick={e => validatePhoneNumber(formData.phone_number)}
                    >
                      Verify
                    </span>)}
                  </div>
                  {errors.phone_number && <span className="error-message">{errors.phone_number}</span>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>Serial Number</label>
                  <input
                    readOnly={true}
                    type="text"
                    className="form-control "
                    name="serial_number"
                    disabled={!editForm}
                    value={formData.serial_number}
                    placeholder="Enter the serial number"
                  />

                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control "
                    readOnly={true}
                    name="first_name"
                    disabled={!editForm}
                    value={formData.first_name}
                    placeholder="Enter the first name"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>Middle Name</label>
                  <input
                    type="text"
                    className="form-control "
                    readOnly={true}
                    name="middle_name"
                    disabled={!editForm}
                    value={formData.middle_name}
                    placeholder="Enter the middle name"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control "
                    readOnly={true}
                    name="last_name"
                    disabled={!editForm}
                    value={formData.last_name}
                    placeholder="Enter the last name"
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>Email address</label>
                  <input
                    type="text"
                    className="form-control "
                    name="email"
                    disabled={
                      userData.user_role_id ===
                        DEFAULT_USER_ROLE.SUPER_ADMIN
                        ? !editForm
                        : updateId
                    }
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter the email address"
                  />
                  {errors.email && (
                    <span className="error-message">
                      {errors.email}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>Gender</label>
                  <input
                    type="text"
                    className="form-control "
                    readOnly={true}
                    name="gender"
                    disabled={!editForm}
                    value={formData.gender}
                    placeholder="Enter the gender"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>Citizenship</label>
                  <input
                    type="text"
                    className="form-control "
                    readOnly={true}
                    name="citizen_ship"
                    disabled={!editForm}
                    value={formData.citizen_ship}
                    placeholder="Enter the citizenship"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>Date of Birth</label>
                  <input
                    type="text"
                    className="form-control "
                    readOnly={true}
                    name="dob"
                    disabled={!editForm}
                    value={getDisplayDateOnly(formData.dob)}
                    placeholder="Enter the date of birth"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>Marital Status</label>
                  <Dropdown
                    disabled={!editForm}
                    value={formData.marital_status}
                    onChange={handleChange}
                    options={MARITAL_STATUS_LIST}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select a marital status"
                    className="w-100 custom-single-dropdown"
                    name="marital_status"
                  />

                  {errors.marital_status && (
                    <span className="error-message">
                      {errors.marital_status}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>Occupation</label>
                  <input
                    type="text"
                    className="form-control "
                    name="occupation"
                    disabled={!editForm}
                    value={formData.occupation}
                    onChange={handleChange}
                    placeholder="Enter the occupation"
                  />
                  {errors.occupation && (
                    <span className="error-message">
                      {errors.occupation}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>Next of Kin Name</label>
                  <input
                    type="text"
                    className="form-control "
                    name="nominee_name"
                    disabled={!editForm}
                    value={formData.nominee_name}
                    onChange={handleChange}
                    placeholder="Enter the next of kin name"
                  />
                  {errors.nominee_name && (
                    <span className="error-message">
                      {errors.nominee_name}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>Next of Kin Relationship</label>
                  <input
                    type="text"
                    className="form-control "
                    name="nominee_relationship"
                    disabled={!editForm}
                    value={formData.nominee_relationship}
                    onChange={handleChange}
                    placeholder="Enter the next of kin relationship"
                  />
                  {errors.nominee_relationship && (
                    <span className="error-message">
                      {errors.nominee_relationship}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>Next of Kin Phone Number</label>
                  <input
                    type="text"
                    className="form-control "
                    name="nominee_phone_number"
                    disabled={!editForm}
                    value={formData.nominee_phone_number}
                    onChange={handleChange}
                    placeholder="Enter the next of kin phone number"
                  />
                  {errors.nominee_phone_number && (
                    <span className="error-message">
                      {errors.nominee_phone_number}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>Address</label>
                  <textarea
                    className="form-control "
                    name="address"
                    disabled={!editForm}
                    value={formData.address}
                    onChange={handleChange}
                    rows={6}
                    placeholder={`Address Line 1\nAddress Line 2\nCity\nState\nCountry\nPincode`}
                  >
                    {formData.address}
                  </textarea>
                  {errors.address && (
                    <span className="error-message">
                      {errors.address}
                    </span>
                  )}
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label className="w-100">
                    Supporting documents
                  </label>
                  {editForm && (
                    <>
                      <ButtonDropdown>
                        <ButtonDropdown.Toggle className="btn btn-orange-primary-outline ">
                          Upload
                        </ButtonDropdown.Toggle>
                        <ButtonDropdown.Menu>
                          <ButtonDropdown.Item
                            onClick={(e) => {
                              setCameraUploadFile(
                                ATTACHMENT_TYPE.CUSTOMER_SUPPORT_DOCUMENT
                              );
                              dispatch(setShowCamera(true));
                            }}
                          >
                            Capture from camera
                          </ButtonDropdown.Item>
                          <ButtonDropdown.Item
                            onClick={handleFilefileSDUploadClick}
                          >
                            Upload from your computer
                          </ButtonDropdown.Item>
                        </ButtonDropdown.Menu>
                      </ButtonDropdown>

                      <input
                        accept={fileAccept}
                        type="file"
                        ref={fileSD}
                        className="d-none"
                        name={
                          ATTACHMENT_TYPE.CUSTOMER_SUPPORT_DOCUMENT
                        }
                        multiple
                        onChange={uploadFile}
                      />
                    </>
                  )}
                </div>
              </div>
              <MultipleFilePreview files={supportingDocuments} />
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <strong>Agent Information</strong>
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>Organisation</label>
                  {parseInt(userData.user_role_id) ===
                    DEFAULT_USER_ROLE.ORGANIZATION ||
                    parseInt(userData.user_role_id) ===
                    DEFAULT_USER_ROLE.AGENT ? (
                    <Dropdown
                      disabled={true}
                      value={formData.organization_id}
                      options={organizationList}
                      optionLabel="organization_name"
                      optionValue="id"
                      placeholder="Select a organisation"
                      className="w-100 custom-single-dropdown"
                      name="organization_id"
                    />
                  ) : (
                    <Dropdown
                      disabled={!editForm}
                      value={formData.organization_id}
                      onChange={handleChange}
                      options={organizationList}
                      optionLabel="organization_name"
                      optionValue="id"
                      placeholder="Select a organisation"
                      className="w-100 custom-single-dropdown"
                      name="organization_id"
                    />
                  )}

                  {errors.organization_id && (
                    <span className="error-message">
                      {errors.organization_id}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>Location</label>

                  <Dropdown
                    disabled={!editForm}
                    value={formData.location_id}
                    onChange={handleChange}
                    options={orgLocationList}
                    optionLabel="location_name"
                    optionValue="id"
                    placeholder="Select a location"
                    className="w-100 custom-single-dropdown"
                    name="location_id"
                  />

                  {errors.location_id && (
                    <span className="error-message">
                      {errors.location_id}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-3">
                  <label>Agent</label>
                  {parseInt(userData.user_role_id) ===
                    DEFAULT_USER_ROLE.AGENT ? (
                    <Dropdown
                      disabled={true}
                      value={formData.agent_id}
                      options={agentList}
                      optionLabel="full_name"
                      optionValue="id"
                      placeholder="Select a agent"
                      className="w-100 custom-single-dropdown"
                      name="agent_id"
                    />
                  ) : (
                    <Dropdown
                      disabled={!editForm}
                      value={formData.agent_id}
                      onChange={handleChange}
                      options={agentList}
                      optionLabel="full_name"
                      optionValue="id"
                      placeholder="Select a agent"
                      className="w-100 custom-single-dropdown"
                      name="agent_id"
                    />
                  )}

                  {errors.organization_id && (
                    <span className="error-message">
                      {errors.organization_id}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="col-12 mb-3">
        {defaultUserPermission &&
          defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER] &&
          !defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER][
          PERMISSION_KEY.ADD_ONLY
          ] && (
            <FormStatus
              onStatusClick={handleChange}
              active={formData.active}
              id={updateId}
              editForm={editForm}
            />
          )}
      </div>
      <div className="col-12 text-end">
        <div className="form-group mb-3">
          {editForm && (
            <>
              {defaultUserPermission &&
                defaultUserPermission[
                PERMISSION_PAGE_KEY.CUSTOMER
                ] &&
                !defaultUserPermission[
                PERMISSION_PAGE_KEY.CUSTOMER
                ][PERMISSION_KEY.ADD_ONLY] && (
                  <button
                    className="btn  btn-purple-primary-outline me-3"
                    type="button"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                )}
              <button
                disabled={disabledButton}
                className="btn  btn-purple-primary"
                type="submit"
              >
                {disabledButton ? <Spinner /> : null}{" "}
                {updateId ? "Update" : "Create"}
              </button>
            </>
          )}
        </div>
      </div>
    </form>
  );

  return (
    <>

      <div className="row">
        <div className="col-md-10  mb-3">
          <div className="d-flex align-items-center">
            {defaultUserPermission &&
              defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER] &&
              !defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER][
              PERMISSION_KEY.ADD_ONLY
              ] && (
                <IconBack
                  width={32}
                  height={32}
                  onIconClick={closeModal}
                  customClass="cursor-pointer me-2 "
                />
              )}
            <h3 className="flex-shrink-1 mb-0">
              {updateId ? "Update Customer" : "Create New Customer"}
            </h3>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mt-3">
          {!updateId && renderForm}
          {updateId && (<Tabs
            id="item-details-tab"
            activeKey={tabKey}
            onSelect={(k) => setTabKey(k)}
            className="custom-tabs mb-3 pt-3 pb-3"
          >
            <Tab eventKey="basicInfo" title="Basic Information" className="mb-3">
              {renderForm}
            </Tab>

            <Tab eventKey="list" title="Trading" className="mb-3">
              {updateId && (
                <DataTableComponent
                  tableKey={TABLES.TRADE}
                  columns={tableColumns}
                  onTableRowSelect={selectedRow}
                />
              )}
            </Tab>

            {/* Wallet Tab */}

            <Tab eventKey="wallet" title="Wallet" className="mb-3">
              {updateId && (
                <>
                  {/* Wallet and Contribution Cards */}
                  <div className="row mb-2">
                    <div className="col-md-4 col-12 mb-3">
                      <div className="card shadow rounded min-height-card1">
                        <div className="card-body">
                          <div className="d-flex align-items-center mb-2">
                            <IconWallet width={20} height={20} className="position-absolute top-0 start-0" />
                            <h6 className="ms-2 pt-2">Total Wallet Balance</h6>
                          </div>
                          <hr className="my-2" style={{ width: '80px', borderTop: '2px solid #000' }} />
                          <p className="m-0 text-end">
                            <strong>
                              {customerWallet
                                ? currencyFormatValue(
                                  customerWallet.customer_contribution +
                                  customerWallet.mc_contribution
                                )
                                : 0}{" "}
                              KES
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-12 mb-3">
                      <div className="card shadow rounded min-height-card1">
                        <div className="card-body">
                          <div className="d-flex align-items-center mb-2">
                            <IconCustomerContribution width={20} height={20} className="position-absolute top-0 start-0" />
                            <h6 className="ms-2 pt-2">Contribution - customer</h6>
                          </div>
                          <hr className="my-2" style={{ width: '80px', borderTop: '2px solid #000' }} />
                          <p className="m-0 text-end">
                            <strong>
                              {customerWallet
                                ? currencyFormatValue(
                                  customerWallet.customer_contribution
                                )
                                : 0}{" "}
                              KES
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-12 mb-3">
                      <div className="card shadow rounded min-height-card1">
                        <div className="card-body">
                          <div className="d-flex align-items-center mb-2">
                            <IconMcContribution width={20} height={20} className="position-absolute top-0 start-0" />
                            <h6 className="ms-2 pt-2">Contribution - Madini Chapaa</h6>
                          </div>
                          <hr className="my-2" style={{ width: '80px', borderTop: '2px solid #000' }} />
                          <p className="m-0 text-end">
                            <strong>
                              {customerWallet
                                ? currencyFormatValue(
                                  customerWallet.mc_contribution
                                )
                                : 0}{" "}
                              KES
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

            </Tab>

            {/* </div> */}
          </Tabs>
          )}
        </div>
      </div>
      <CameraUpload onImgCallBack={handleCapture} />
      <FilePreview />
    </>
  );
};

export default CustomerFormModel;
