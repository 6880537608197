

// impor the libraries
import { createSlice } from '@reduxjs/toolkit'

// user default initial state
const initialState = {
categoryList:[]
}

// create a slice and reducers
const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategoryList: (state, action) => {
      state.categoryList = action.payload;
    },
    setClearCategoryState: (state, action) => {
      state.categoryList = [];
    }
  },
})
// export the user module
export default categorySlice.reducer;

export const {
  setCategoryList,
  setClearCategoryState
} = categorySlice.actions;