import React, { useRef, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { setToast } from './toast-service-helper';

const ToastProvider = ({ children }) => {

    const toasts = {
        'top-left': useRef(null),
        'top-center': useRef(null),
        'top-right': useRef(null),
        'bottom-left': useRef(null),
        'bottom-center': useRef(null),
        'bottom-right': useRef(null),
        'center': useRef(null)
    };

    useEffect(() => {
        setToast(toasts);
    }, []);

    return (
        <div>
            <Toast ref={toasts['top-left']} position="top-left" />
            <Toast ref={toasts['top-center']} position="top-center" />
            <Toast ref={toasts['top-right']} position="top-right" />
            <Toast ref={toasts['bottom-left']} position="bottom-left" />
            <Toast ref={toasts['bottom-center']} position="bottom-center" />
            <Toast ref={toasts['bottom-right']} position="bottom-right" />
            <Toast ref={toasts['center']} position="center" />
            {children}
        </div>
    );
};

export default ToastProvider;
