

// impor the libraries
import { createSlice } from '@reduxjs/toolkit'

// user default initial state
const initialState = {
subCategoryList:[]
}

// create a slice and reducers
const subCategorySlice = createSlice({
  name: 'subCategory',
  initialState,
  reducers: {
    setSubCategoryList: (state, action) => {
      state.subCategoryList = action.payload;
    },
    setClearSubCatState: (state, action) => {
      state.subCategoryList = [];
    }
  },
})
// export the user module
export default subCategorySlice.reducer;

export const {
  setSubCategoryList,
  setClearSubCatState
} = subCategorySlice.actions;