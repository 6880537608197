import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { setDisabledButton, setShowCamera } from '../store/general/reducer';
import { isMobileScreen } from '../helper/common-helper';
import * as Yup from 'yup';
import { setOtpFormData, setShowVerifyOtp } from '../store/auth/reducer';
import { actionVerifyOtp } from '../store/auth/actions';
import { Spinner } from '../svg-icons';

const VerifyOtp = ({ onImgCallBack }) => {

  const { otpFormData,showVerifyOtp } = useSelector((state) => state.auth);
  const { disabledButton } = useSelector((state) => state.general);

  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});


  const schema = Yup.object().shape({
    otp: Yup.string()
      .required('OTP is required')
  });

  const validateField = async (name, value) => {
    try {
      await Yup.reach(schema, name).validate(value);
      setErrors({ ...errors, [name]: '' });
    } catch (validationError) {
      setErrors({ ...errors, [name]: validationError.message });
    }
  };
  const validateForm = async () => {
    try {
      await schema.validate(otpFormData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationErrors) {
      const newErrors = validationErrors.inner.reduce((acc, err) => {
        acc[err.path] = err.message;
        return acc;
      }, {});
      setErrors(newErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = await validateForm();

    if (isValid) {
      dispatch(setDisabledButton(true));
      dispatch(actionVerifyOtp(otpFormData));
    } else {
      console.log('Form has errors:', errors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = {
      ...otpFormData,
      [name]: value,
    };
    dispatch(setOtpFormData(data));
    validateField(name, value);
  };


  return (
    <Dialog
      header="Verify OTP"
      visible={showVerifyOtp}
      style={{ width: isMobileScreen ? '75vw' : '25vw' }}
      onHide={() => dispatch(setShowVerifyOtp(false))}
    >
      <form onSubmit={handleSubmit} noValidate>
        <div className='row mb-4'>
          <div className='col-12'>
            <div className='form-group'>
              <label>Enter OTP</label>
              <input type='number' 
              className='form-control'
                name="otp"
                value={otpFormData.otp}
                onChange={handleChange}
                placeholder='Enter the OTP'
              />
              {errors.otp && <span className="error-message">{errors.otp}</span>}
            </div>
          </div>
          <div className='col-12 mt-3'>
            <div className='form-group text-center'>
              <button type='submit' className='btn btn-orange-primary'> {disabledButton ? <Spinner /> : null} Verify OTP</button>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default VerifyOtp;
