
// impor the libraries
import { createSlice } from '@reduxjs/toolkit'

// user default initial state
const initialState = {
  fileDetails: null
}

// create a slice and reducers
const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    setfileDetails: (state, action) => {
      state.fileDetails = action.payload.dataObject;
    }
  },
})
// export the user module
export default fileSlice.reducer;

export const {
  setfileDetails
} = fileSlice.actions;