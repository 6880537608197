import React from 'react';
import { Routes, Route } from "react-router-dom";

import PublicRoutes from "./public-routes";

//get all customer pages
import LoginPage from "../../pages/public/login";
import ResetPage from "../../pages/public/reset-password";
import CustomerRegisterPage from "../../pages/public/customer-register";
import PageNotFoundPage from "../../pages/error/page-not-found";



const GeneralRoutes = () => (
  
  <Routes>
    {/* Public routes */}
    <Route path="" element={<PublicRoutes />}>
      <Route path="/" element={<LoginPage />} />
    </Route>
    <Route path="reset-password" element={<PublicRoutes page="reset-password" />}>
      <Route path="/reset-password" element={<ResetPage />} />
    </Route>
    <Route path="register" element={<PublicRoutes page="register" />}>
      <Route path="/register" element={<CustomerRegisterPage />} />
    </Route>

    {/* Page not found */}
    <Route path="" element={<PublicRoutes />}>
      <Route path="/*" element={<PageNotFoundPage />} />
    </Route>
  </Routes>
);

export default GeneralRoutes;
