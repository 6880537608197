import React from 'react';
import SingleFilePreview from './single-file-upload';
const MultipleFilePreview = ({files}) => {
    return (
        <>
        {
            Object.values(files).length > 0 &&
            Object.values(files).map((file, index) => (
                <div className="col-md-12" key={index}>                                                    
                    <SingleFilePreview file={file} />
                </div>
            ))
        }
        </>
    );
};

export default MultipleFilePreview;
