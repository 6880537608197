import React from 'react';
import { Routes, Route } from "react-router-dom";

import PublicRoutes from "./public-routes";
import ProtectedRoutes from "./protected-routes";

//get all customer pages
import LoginPage from "../../pages/public/login";
import ResetPage from "../../pages/public/reset-password";
import DashboardPage from "../../pages/customer-protected/dashboard";
import SupportPage from "../../pages/customer-protected/support";
import LoanPage from "../../pages/customer-protected/loan";

import PageNotFoundPage from "../../pages/error/page-not-found";

import TradingPage from "../../pages/customer-protected/trade";
import TradingDetailsPage from "../../pages/customer-protected/trade/trade-details";
import TradingItemApprovalPage from "../../pages/customer-protected/trade/item-approval";
import ProfilePage from "../../pages/customer-protected/profile";



const CustomerRoutes = () => (
  
  <Routes>
    {/* Public routes */}
    <Route path="" element={<PublicRoutes />}>
      <Route path="/" element={<LoginPage />} />
    </Route>
    <Route path="reset-password" element={<PublicRoutes page="reset-password" />}>
      <Route path="/reset-password" element={<ResetPage />} />
    </Route>

    {/* Protected routes */}
    <Route path="Loan" element={<ProtectedRoutes path="loan" />}>
      <Route path="/Loan" element={<LoanPage />} />
    </Route>
    <Route path="support" element={<ProtectedRoutes path="support" />}>
      <Route path="/support" element={<SupportPage />} />
    </Route>
    <Route path="dashboard" element={<ProtectedRoutes path="dashboard" />}>
      <Route path="/dashboard" element={<DashboardPage />} />
    </Route>

    <Route path="/trading" element={<ProtectedRoutes path="/trading" />}>
      <Route path="/trading" element={<TradingPage />} />
    </Route>
    <Route path="/trading/details" element={<ProtectedRoutes path="/trading/details" />}>
      <Route path="/trading/details" element={<TradingDetailsPage />} />
    </Route>
    <Route path="/trading/item-approval" element={<ProtectedRoutes path="/trading/item-approval" />}>
      <Route path="/trading/item-approval" element={<TradingItemApprovalPage />} />
    </Route>
    <Route path="/profile" element={<ProtectedRoutes path="/profile" />}>
      <Route path="/profile" element={<ProfilePage />} />
    </Route>
    

    {/* Page not found */}
    <Route path="" element={<PublicRoutes />}>
      <Route path="/*" element={<PageNotFoundPage />} />
    </Route>
  </Routes>
);

export default CustomerRoutes;
