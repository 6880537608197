import { Dialog } from 'primereact/dialog';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilePreviewModel } from '../store/general/reducer';
import { isMobileScreen } from '../helper/common-helper';

const FilePreview = () => {
    const dispatch = useDispatch();
    const { filePreview, filePreviewModel } = useSelector((state) => state.general);
    const closeModel = () => {
        dispatch(setFilePreviewModel(false))
    }
    const renderPreview = (file) => {
        
        switch (file.file.file_type) {
            case 'application/pdf':
                return (<><iframe src={`data:${file.file.file_type};base64,${file.base64}`} className='w-100 h-100' /></>);
          
            default:
                return (<><img className='w-100 h-100' src={`data:${file.file.file_type};base64,${file.base64}`} /></>);                
        }
    }
    
    return (
        <Dialog
            header="File preview"
            // position='top'
            visible={filePreviewModel} 
            style={{ width: isMobileScreen ? '100vw':'50vw' }}
            onHide={closeModel}
            // footer={footerContent}
            maximizable
            className='file-preview-model'
        >
            {filePreview ? renderPreview(filePreview) : null}

        </Dialog>
    );
};

export default FilePreview;
