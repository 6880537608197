

// impor the libraries
import { createSlice } from '@reduxjs/toolkit'

import {PRODUCT_CERTIFICATION, RECORD_STATUS } from '../../config/constant';

const defaultFormData = {
  sub_category_id: "",
  category: "",
  loyalty_percentage: 0,
  certification_need: PRODUCT_CERTIFICATION.YES.id,
  certification_charges: 0,
  notes: "",
  active: RECORD_STATUS.ACTIVE.id
};

// user default initial state
const initialState = {
  search: '',
  formData: defaultFormData,
  formModel: false
}

// create a slice and reducers
const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setFormModel: (state, action) => {
      state.formModel = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setUpdateId: (state, action) => {
      state.updateId = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setDefaultFormData: (state, action) => {
      state.formData = defaultFormData;
    },
    setClearProductState: (state, action) => {
      state.formData = defaultFormData;
      state.formModel = false;
      state.updateId = null;
    }
  },
})
// export the user module
export default productSlice.reducer;

export const {
  setFormModel,
  setSearch,
  setUpdateId,
  setFormData,
  setDefaultFormData,
  setClearProductState
} = productSlice.actions;