import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { setPopupLocation, setShowCamera } from '../store/general/reducer';
import { setLatLng } from '../store/auth/reducer';
import { isMobileScreen } from '../helper/common-helper';

const LocationGPS = ({ optional = true }) => {

  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const { popupLocation } = useSelector(state => state.general);

  useEffect(() => {
    const locOpt = sessionStorage.getItem('showPermission');
    if (locOpt && optional) {
      dispatch(setPopupLocation(false));
      return;
    }

    // Check the current permission state when the component mounts
    const checkPermission = async () => {
      try {
        const permission = await navigator.permissions.query({ name: 'geolocation' });
        handlePermissionChange(permission.state); // Handle the current state

        // Listen for permission status changes
        permission.onchange = () => {
          handlePermissionChange(permission.state);
        };
      } catch (error) {
        console.error('Error checking permissions:', error);
      }
    };

    checkPermission();

  }, []);

  // Handle the permission change dynamically
  const handlePermissionChange = (status) => {
    if (status === 'granted') {
      getLocation();
    } else if (status === 'denied') {
      // show the error message popup
      dispatch(setPopupLocation(true));
    } else if (status === 'prompt') {
      requestLocation();
    }
  };

  // Function to request location
  const requestLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch(setPopupLocation(false));
        dispatch(setLatLng({ lat: position.coords.latitude, lng: position.coords.longitude }));
        setErrorMessage('');
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          dispatch(setPopupLocation(true));
        }
      }
    );
  };

  // Function to get location if permission is granted
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch(setPopupLocation(false));
        dispatch(setLatLng({ lat: position.coords.latitude, lng: position.coords.longitude }));
        setErrorMessage('');
      },
      (error) => {
        setErrorMessage('Unable to retrieve location.');
      }
    );
  };
  const skipLocationPermission = () => {
    sessionStorage.setItem('showPermission', 'true');
    dispatch(setPopupLocation(false));
  }

  return (
    <>
      {
        popupLocation && (
          <>
            <Dialog
              header="Location GPS"
              visible={true}
              modal
              style={{ width: isMobileScreen ? '100vw' : '25vw' }}
              onHide={null} // Hide dialog and stop camera
              content={({ hide }) => (
                <div className='card'>
                  <div className='card-body'>
                    <p className='m-0'>{(optional ? 'Location permission is currently denied. Please enable it to access this feature or skip if you prefer.' : 'Location permission denied. Please enable it to access the feature.')}</p>
                  </div>
                  {optional && (
                    <div className='card-footer'>
                      <div className='row'>
                        <div className='col-12 text-end'>
                          <button className='btn btn-purple-primary-outline me-2' onClick={skipLocationPermission}>Skip</button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            />
          </>
        )
      }
    </>

  );
};

export default LocationGPS;
