import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { userLogout } from '../../../store/auth/reducer';
import { setClearTradeState, setUpdateId, setFormModel, setDefaultFormData } from '../../../store/trade/reducer';
import { setChangePasswordModel, updatePageTitle } from '../../../store/general/reducer';
import { IconCustomerContribution, IconHistory, IconLoan, IconMcContribution, IconPersonCircle, IconPersonKey, IconPlus, IconSupport, IconWallet } from "../../../svg-icons";
import { currencyFormatValue, isMobileScreen } from "../../../helper/common-helper";
import { actionGetCustomerWallet } from '../../../store/customer/action';

const DashboardPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customerWallet } = useSelector((state) => state.customer);
  const { userData } = useSelector(state => state.auth);

  const createTrade = async () => {
    dispatch(setDefaultFormData());
    dispatch(setUpdateId(null));
    dispatch(setFormModel(true));
    navigate('/trading', { state: { fromCreateTrade: true } });
  };

  useEffect(() => {
    dispatch(updatePageTitle("Dashboard"));
    dispatch(actionGetCustomerWallet(userData.id));
  }, [])

  const handleSupportClick = () => {
    navigate('/support');
  };
  const handleLoanClick = () => {
    navigate('/loan');
  };
  return (
    <div className={`${isMobileScreen ?'': 'p-3'}`}>
      {/* Top Menu */}
      <div>
        <h2>Welcome back, {userData.name}</h2>
        <p>Discover exclusive financial assitance and employee perks tailored just for you</p>
      </div>

      {/* Wallet and Contribution Cards */}
      <div className="row mb-2">
        <div className="col-md-4 col-12 mb-3">
          <div className="card shadow rounded min-height-card1">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <IconWallet width={20} height={20} className="position-absolute top-0 start-0" />
                <h6 className="ms-2 pt-2">Total Wallet Balance</h6>
              </div>
              <hr className="my-2" style={{ width: '80px', borderTop: '2px solid #000' }} />
              <p className="m-0 text-end">
                <strong>
                  {customerWallet
                    ? currencyFormatValue(
                      customerWallet.customer_contribution +
                      customerWallet.mc_contribution
                    )
                    : 0}{" "}
                  KES
                </strong>
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-12 mb-3">
          <div className="card shadow rounded min-height-card1">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <IconCustomerContribution width={20} height={20} className="position-absolute top-0 start-0" />
                <h6 className="ms-2 pt-2">Contribution - customer</h6>
              </div>
              <hr className="my-2" style={{ width: '80px', borderTop: '2px solid #000' }} />
              <p className="m-0 text-end">
                <strong>
                  {customerWallet
                    ? currencyFormatValue(
                      customerWallet.customer_contribution
                    )
                    : 0}{" "}
                  KES
                </strong>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12 mb-3">
          <div className="card shadow rounded min-height-card1">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <IconMcContribution width={20} height={20} className="position-absolute top-0 start-0" />
                <h6 className="ms-2 pt-2">Contribution - Madini Chapaa</h6>
              </div>
              <hr className="my-2" style={{ width: '80px', borderTop: '2px solid #000' }} />
              <p className="m-0 text-end">
                <strong>
                  {customerWallet
                    ? currencyFormatValue(
                      customerWallet.mc_contribution
                    )
                    : 0}{" "}
                  KES
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Main Action Cards */}
      <div>
        <div className='pt-3'>
          <h5>Types</h5>
        </div>
        <div>
          <div className="row pt-2">
            <div className="col-md-4 mb-3">
              <div className="card shadow rounded cursor-pointer  min-height-card" onClick={createTrade}>
                <div className="card-body">
                  <div className="d-flex align-items-center mb-2">
                    <IconPlus width={20} height={20} className="position-absolute top-0 start-0" />
                    <h6 className="ms-2 pt-2">Create Trade</h6>
                  </div>
                  <hr className="my-2" style={{ width: '80px', borderTop: '2px solid #000' }} />
                  <p className="card-text text-muted fnt-size-11">Quickly set up and initiate new trades across various markets. Begin by selecting assets, setting preferences, and making secure transactions.</p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="card shadow rounded cursor-pointer  min-height-card" onClick={() => {
                dispatch(setClearTradeState());
                navigate("/trading");
              }}>
                <div className="card-body">
                  <div className="d-flex align-items-center mb-2">
                    <IconHistory width={20} height={20} className="position-absolute top-0 start-0" />
                    <h6 className="ms-2 pt-2">Trade History</h6>
                  </div>
                  <hr className="my-2" style={{ width: '80px', borderTop: '2px solid #000' }} />
                  <p className="card-text text-muted fnt-size-11">Easily access your complete history of trades with real-time updates. Track performance, view trade details, and analyze trends to enhance your strategy.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card shadow rounded cursor-pointer  min-height-card" onClick={handleLoanClick}>
                <div className="card-body">
                  <div className="d-flex align-items-center mb-2">
                    <IconLoan width={20} height={20} className="position-absolute top-0 start-0" />
                    <h6 className="ms-2 pt-2">Loans</h6>
                  </div>
                  <hr className="my-2" style={{ width: '80px', borderTop: '2px solid #000' }} />
                  <p className="card-text text-muted fnt-size-11">Explore a variety of loan options tailored to meet your financial needs. Stay tuned for more details on our upcoming loan services.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card shadow rounded cursor-pointer min-width-card min-height-card" onClick={() => navigate("/profile")}>
                <div className="card-body">
                  <div className="d-flex align-items-center mb-2">
                    <IconPersonCircle width={20} height={20} className="position-absolute top-0 start-0" />
                    <h6 className="ms-2 pt-2">My Profile</h6>
                  </div>
                  <hr className="my-2" style={{ width: '80px', borderTop: '2px solid #000' }} />
                  <p className="card-text text-muted fnt-size-11">Update personal details, adjust preferences, and configure account settings. Keep your profile information current for a secure trading experience.</p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="card shadow rounded cursor-pointer  min-height-card" onClick={() => dispatch(setChangePasswordModel(true))}>
                <div className="card-body">
                  <div className="d-flex align-items-center mb-2">
                    <IconPersonKey width={20} height={20} />
                    <h6 className="ms-2 pt-2">Security</h6>
                  </div>
                  <hr className="my-2" style={{ width: '80px', borderTop: '2px solid #000' }} />
                  {/* <h6>Update Your Password</h6> */}
                  <p className="card-text text-muted fnt-size-11">Easily change your password to keep your account secure. Regular updates help ensure the safety and privacy of your trading information.</p>
                </div>
              </div>
            </div>          

            <div className="col-md-4 mb-3">
              <div className="card shadow rounded cursor-pointer  min-height-card" onClick={handleSupportClick}>
                <div className="card-body">
                  <div className="d-flex align-items-center mb-2">
                    <IconSupport width={20} height={20} />
                    <h6 className="ms-2 pt-2">Support</h6>
                  </div>
                  <hr className="my-2" style={{ width: '80px', borderTop: '2px solid #000' }} />
                  {/* <h6>Get Assistance Anytime</h6> */}
                  <p className="card-text text-muted fnt-size-11">Access dedicated support for any trading issues or inquiries. Our team is here to help with quick resolutions and guidance, ensuring a smooth experience.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
