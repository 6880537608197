

// Import files and libraries
import React, { useEffect, useState } from 'react';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { updatePageTitle } from '../../../store/general/reducer';
import { useDispatch } from 'react-redux';
import { BreadCrumb } from 'primereact/breadcrumb';
// start dashboard page function
const DashboardPage = () => {
  const dispatch = useDispatch();
  // On page function call get the page data and if any table action changes call the api
  useEffect(() => {
    dispatch(updatePageTitle('Dashboard'));
  }, []);



  // page design
  return (
    <>
      <div className='container-fluid'>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className='col-6'>
                <h3 className='page-title'>Dashboard</h3>
              </div>
              <div className='col-12 text-center'>
                <h2>Page under constraction</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

}
// export the dashboard function
export default DashboardPage;
