
// Import files and libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';



// others
import { IconPlus, IconProfile, IconSearch } from '../../../svg-icons';
import { ID_TYPE, ORDER_STATUS, PERMISSION_KEY, PERMISSION_PAGE_KEY, TABLES, validatePermission } from '../../../config/constant';
import TradingFormModel from './form-modal';
// import TradingDetails from './trade-details';


//reducer
import { setUpdateId, setFormModel, setDefaultFormData, setTradeDetails, setSearch } from '../../../store/trade/reducer';
import { setClearGeneralState, setProDocDefault, setTableLoader, updateLoaderStatus, updatePageTitle } from '../../../store/general/reducer';

//action
import { actionGetAll } from '../../../store/trade/action';

//custom component
import DataTableComponent from '../../../component/data-table';

import { actionGetOrganizationList } from '../../../store/organization/action';
import { useNavigate } from 'react-router-dom';
import GetOrderStatus from '../../../component/order-status';
import PermissionDenied from '../../../component/permission-denied';
import { actionClearData } from '../../../store/general/action';
import { getDisplayDateTime } from '../../../helper/date-helper';




const TradingPage = () => {

  // dispatch action
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get the table details like sort, order, page, search, page, page size, table data, total records
  const { formModel, search } = useSelector((state) => state.trade);
  const { sort, order, limit, offset, totalRecords } = useSelector((state) => state.table[TABLES.TRADE]);
  const { defaultUserPermission } = useSelector((state) => state.auth);

  // handle model show
  const showModel = async () => {
    // clear the form details
    dispatch(setDefaultFormData());
    // remove the update id
    dispatch(setUpdateId(null));

    // show the model
    dispatch(setFormModel(true));
  };
  useEffect(() => {
    dispatch(setClearGeneralState());
    dispatch(actionGetOrganizationList());
    dispatch(updatePageTitle("Trading"));
  }, []);

  // On page function call get the page data and if any table action changes call the api
  useEffect(() => {
    // call the api function
    getAllData();
  }, [limit, offset, sort, order, defaultUserPermission]);


  // get data form the api
  const getAllData = async () => {
    dispatch(setTableLoader(true));
    // dispatch the data to the API call
    dispatch(actionGetAll())
  }

  const tableColumns = [
    {
      field: 'row_ref_number',
      header: 'TRADING ID'
    },
    {

      field: 'customer_name',
      header: 'CUSTOMER NAME',
      sortable: true, // Enables sorting      
      body: (row) => {
        return `${row.customer_first_name} ${row.customer_middle_name} ${row.customer_last_name}`;
      }
    },
    {

      field: 'agent_name',
      header: 'AGENT NAME',
      sortable: true, // Enables sorting      
      body: (row) => {
        return `${row.agent_first_name} ${row.agent_middle_name} ${row.agent_last_name}`;
      }
    },

    {

      field: 'item_count',
      header: 'NO OF TRADING ITEMS',
      headerAlign: 'text-end',
      bodyAlign: 'text-end',
    },

    {
      field: 'status',
      header: 'STATUS',
      sortable: true, // Enables sorting      
      body: (row) => {
        return <GetOrderStatus status={row.order_status} />;

      }
    },
    {
      field: 'created_at',
      header: 'DATE',
      headerAlign: 'text-end',
      bodyAlign: 'text-end',
      sortable: true, // Enables sorting      
      body: (row) => {
        return getDisplayDateTime(row.created_at)
      }
    }

  ];
  const selectedRow = (e) => {
    dispatch(setTradeDetails(null));
    navigate("/portal/trading/details?type=order&view=" + e.data.slug);
  }
  const handleSearchClick = () => {
    getAllData();
  };
  const updateSearch = (e) => {
    dispatch(setSearch(e.target.value));
  };


  // page design
  return (
    <>
      {defaultUserPermission && validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.TRADING, PERMISSION_KEY.READ) ? (
        <div className='container-fluid'>
          <div className="card mb-3">
            <div className="card-body">
              {formModel ? (
                <div className="row">
                  <div className='col-12'>
                    <TradingFormModel />
                  </div>
                </div>
              ) : (

                <div className="row">
                  <div className='col-md-6 col-12 mb-2'>
                    <h3 className='page-title'>Trading</h3>
                    <p className='sub-page-title table-total-count'>Total number of Tradings - {totalRecords}</p>
                  </div>
                  {defaultUserPermission && validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.TRADING, PERMISSION_KEY.ADD) && (
                    <div className='col-md-6 col-12 text-end mb-3'>
                      <button className='btn btn-orange-primary' onClick={showModel}>
                        <IconPlus customClass='text-white' /> Create Trade
                      </button>
                    </div>
                  )}
                  <div className='clearfix'></div>
                  <div className='col-md-3 col-9 mb-2'>
                    {/* Search Field */}

                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search customer name, email, etc..."
                      value={search}
                      onChange={updateSearch}
                    />
                  </div>

                  {/* Search Button */}
                  <div className="col-md-1 col-3 mb-2 text-end">
                    <button className="btn btn-orange-primary" onClick={handleSearchClick} >
                      <IconSearch
                        width={22}
                        height={22}
                        customClass='text-white' />
                    </button>
                  </div>

                  <div className='clearfix'></div>
                  <div className='col-12'>
                    <DataTableComponent
                      tableKey={TABLES.TRADE}
                      columns={tableColumns}
                      onTableRowSelect={selectedRow}
                    />
                  </div>
                </div>
              )}
            </div >
          </div >
        </div >
      ) : defaultUserPermission && <PermissionDenied />}
    </>
  );

}

export default TradingPage;
