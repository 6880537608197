import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { setFormDefaultPermission } from '../store/auth/reducer';

const PermissionList = ({editForm}) => {
    const { permissions, formDefaultPermission } = useSelector((state) => state.auth);
    // create a dispatch
    const dispatch = useDispatch();

    const handleChangePermission = (e) => {
        const { name, value } = e.target;
        let data = {
            ...formDefaultPermission,
            [name]: value,
        };

        dispatch(setFormDefaultPermission(data));
    }
    return (
        <>
            {permissions &&
                Object.values(permissions).length > 0 &&
                Object.entries(permissions).map(([permissionKey, permissionValue]) => (
                    <div className="row" key={permissionKey}>
                        <div className="col-md-3">
                            <div className="form-group mb-3">
                                <label>{permissionValue.label}</label>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group mb-3">
                                <Dropdown
                                    disabled={!editForm}
                                    value={formDefaultPermission[permissionKey]}
                                    onChange={handleChangePermission}
                                    options={permissionValue.list}
                                    optionLabel="option_name"
                                    optionValue="permission_option_id"
                                    placeholder="Select a option"
                                    className="w-100 custom-single-dropdown"
                                    name={permissionKey}
                                />
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    );
};

export default PermissionList;
