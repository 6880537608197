// Import files and libraries
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Dialog } from 'primereact/dialog';

// action
import { IconBack, IconDownload, IconErrorWarning, IconTime, Spinner } from '../../../svg-icons';
import { ENVIRONMENT, ORDER_STATUS, PERMISSION_KEY, PERMISSION_PAGE_KEY, validatePermission } from '../../../config/constant';
import { actionDisbursal, actionGetById } from '../../../store/trade/action';
import TradeItemTable from '../../../component/trade-item-table';
import { useNavigate } from 'react-router-dom';

import { getDisplayDateOnly, getDisplayDateTime, getDisplayTimeParaseOnly } from '../../../helper/date-helper';
import ItemDetails from '../../../component/item-details';
import { setCurrentItem, setPaymentDisbursal, setPaymentDisbursalMsg } from '../../../store/trade/reducer';

import { setClearGeneralState, setDisabledButton, updateLoaderStatus, updateURLParams } from '../../../store/general/reducer';

import { currencyFormatValue, isMobileScreen } from '../../../helper/common-helper';


import { actionGetOrganizationList } from '../../../store/organization/action';
import GetCustomerOrderStatus from '../../../component/customer-order-status';


const TradingDetails = () => {
    // create a dispatch
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // create a state

    const [key, setKey] = useState('items');


    const {
        defaultUserPermission, userData
    } = useSelector((state) => state.auth);

    const {
        tradeDetails,
        paymentDisbursal,
        paymentDisbursalMsg,
        reAssignFormData
    } = useSelector((state) => state.trade);

    const {
        disabledButton,
        urlParams
    } = useSelector((state) => state.general);

    const { organizationList } = useSelector((state) => state.organization);
    const { agentList } = useSelector((state) => state.agent);


    // On page function call get the page data and if any table action changes call the api
    useEffect(() => {

        if (urlParams?.view && urlParams?.type && urlParams.type === 'order') {

            dispatch(setClearGeneralState());
            dispatch(actionGetById(urlParams.view));
            // setDefaultOrg();
            dispatch(actionGetOrganizationList());

        }
    }, [urlParams]);


    const gotoItemApproval = (slug, inx) => {
        // dispatch(setCurrentItem(tradeDetails.order_items[inx]));
        // navigate("/trading/item-approval?type=order-item&view=" + slug);
        // dispatch(updateURLParams());
    }
    const closeModal = () => {
        navigate("/trading");
        dispatch(updateURLParams());
    }
    const disbursal = () => {
        // dispatch(actionDisbursal(tradeDetails.slug));
        dispatch(setPaymentDisbursal(true));
    }
    const makePayment = () => {
        dispatch(setDisabledButton(true));
        dispatch(updateLoaderStatus(true));
        dispatch(actionDisbursal(tradeDetails.slug));
    }
    const closePaymentMsg = () => {
        dispatch(setPaymentDisbursalMsg(false));
        window.location.reload();
    }
    return (
        <div className={`${isMobileScreen ?'': 'p-3'}`}>
            <div className='row'>
                <div className="col-md-12 mb-3">
                    <div className='d-flex align-items-center'>
                        <IconBack
                            width={32}
                            height={32}
                            onIconClick={closeModal}
                            customClass='cursor-pointer'
                        />
                        <h3 className='ms-2 flex-shrink-1 mb-0'>Trading ID: {tradeDetails && tradeDetails.row_ref_number}</h3>
                    </div>
                </div>
                <div className="col-md-6 mb-2">
                    <p className='m-2 fnt-size-14 '>Date: {tradeDetails && getDisplayDateTime(tradeDetails.created_at)}</p>
                    <p className='m-2 fnt-size-14 '>Scheduled: {tradeDetails && getDisplayDateOnly(tradeDetails.scheduled_date) + ' ' + getDisplayTimeParaseOnly(tradeDetails.scheduled_time)}</p>
                    <p className='m-2 fnt-size-14 '>Status: {tradeDetails && <GetCustomerOrderStatus status={tradeDetails.order_status} />}</p>
                    {tradeDetails && tradeDetails.order_status === ORDER_STATUS.SETTLED.id && <p className='m-2 fnt-size-14 cursor-pointer'><a className='text-decoration-none text-muted' href={`${ENVIRONMENT.API_URL}service-trading/pdf/generate-pdf/${tradeDetails?.invoice_slug}`}>Download Receipt <IconDownload /></a></p>}
                </div>
    
                <div className="col-md-12 mt-3">
                    <Tabs
                        id="item-details-tab"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="items" title="Products">
                            {defaultUserPermission && validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.TRADING, PERMISSION_KEY.DISBURSAL) && tradeDetails && tradeDetails.order_status === ORDER_STATUS.DISBURSAL.id && (
                                <div className='form-group text-end mb-3'>
                                    <button
                                        type='button'
                                        className='btn btn-purple-primary'
                                        onClick={disbursal}
                                    >Disbursal</button>
                                </div>
                            )}
                            {
                                tradeDetails && (
                                    <TradeItemTable
                                        items={tradeDetails.order_items}
                                        cc={tradeDetails.customer_contribution}
                                        mc={tradeDetails.mc_contribution}
                                        onRowSelected={gotoItemApproval}
                                        isCustomer={true}
                                    />
                                )
                            }

                        </Tab>
                        {tradeDetails && tradeDetails.order_status === ORDER_STATUS.SETTLED.id && (
                            <Tab eventKey="bankTransaction" title="Payment information">
                                <div className='row'>
                                    <div className='col-6'>
                                        <h5>Invoice Details</h5>
                                        <table className='table' cellPadding={3}>
                                            <tbody>
                                                <tr>
                                                    <td>Status</td>
                                                    {tradeDetails.payment_transactional.callback_response_code === '0' && <td><span className='badge badge-active'>Transaction Completed</span></td>}
                                                    {/* <td>{tradeDetails && tradeDetails.payment_transactional && parseInt(tradeDetails.payment_transactional.callback_response_code) === 0 ? "Completed" : "Payment processing"}</td> */}
                                                </tr>
                                                <tr>
                                                    <td>Amount</td>
                                                    <td>{tradeDetails && tradeDetails.payment_transactional && currencyFormatValue(tradeDetails.payment_transactional.amount)} KES</td>
                                                </tr>
                                                <tr>
                                                    <td>Transaction ID</td>
                                                    <td>{tradeDetails && tradeDetails.payment_transactional && tradeDetails.payment_transactional.transaction_id}</td>
                                                </tr>
                                                <tr>
                                                    <td>Account ID</td>
                                                    <td>{tradeDetails && tradeDetails.payment_transactional && tradeDetails.payment_transactional.party_a}</td>
                                                </tr>
                                                <tr>
                                                    <td>Reference ID</td>
                                                    <td>{tradeDetails && tradeDetails.payment_transactional && tradeDetails.payment_transactional.originator_conversation_id}</td>
                                                </tr>
                                                <tr>
                                                    <td>Transaction Date and Time</td>
                                                    <td>{tradeDetails && tradeDetails.payment_transactional && getDisplayDateTime(tradeDetails.payment_transactional.created_at)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Tab>
                        )}
                    </Tabs>
                </div>
            </div>
            <Dialog
                header=""
                // position='top'
                visible={paymentDisbursalMsg}
                style={{ width: isMobileScreen ? '100vw':'25vw' }}
                onHide={() => {
                    dispatch(setPaymentDisbursal(false));
                }}
            >
                <div className='form-group'>
                    <IconErrorWarning width={20} height={20} /> We have initiated the transaction with M-Pesa, Please wait for few minutes until the transaction is completed. You can close this popup and continue with the other tasks and check again after few minutes
                </div>
                <div className='form-group mt-5 text-end mb-3'>
                    <button
                        className="btn  btn-purple-primary"
                        type='button'
                        onClick={closePaymentMsg}
                    >
                        Close
                    </button>
                </div>
            </Dialog>
            <Dialog
                header="Approved Order Items"
                // position='top'
                visible={paymentDisbursal}
                style={{ width: isMobileScreen ? '100vw':'75vw' }}
                onHide={() => {
                    dispatch(setPaymentDisbursal(false));
                }}
                maximizable
                className='approved-item-list'
            >
                {tradeDetails && <ItemDetails cc={tradeDetails.customer_contribution} mc={tradeDetails.mc_contribution} items={tradeDetails.order_items} onlyApproved={true} />}

                <div className='form-group mt-5 text-end mb-3'>
                    <button
                        className="btn  btn-purple-primary-outline me-3"
                        type='button'
                        onClick={(e) => {
                            dispatch(setPaymentDisbursal(false));
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        disabled={disabledButton}
                        className="btn  btn-purple-primary"
                        type='button'
                        onClick={makePayment}
                    >
                        {disabledButton ? <Spinner /> : null} Proceed to payment
                    </button>
                </div>
            </Dialog>
        </div>
    );
}


export default TradingDetails;
