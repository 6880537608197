import React, { useEffect, useState } from 'react';
import { currencyFormatValue, deepCopy, fixedTwoDigit, roundUpValue } from '../helper/common-helper';
import { ORDER_ITEM_STATUS } from '../config/constant';

const ItemDetails = ({ items = [], cc = 0, mc = 0, onlyApproved = false, previewDetails = false }) => {

    const [totalProductCost, setTotalProductCost] = useState(0);
    const [productItems, setProductItems] = useState([]);
    const [certificationCharges, setCertificationCharges] = useState(0);
    const [ccAmount, setCCAmount] = useState(0);
    const [mcAmount, setMCAmount] = useState(0);

    useEffect(() => {
        startCalculate();
    }, [items, cc, mc, onlyApproved, previewDetails]);
    console.log(onlyApproved, previewDetails, 'status')
    const startCalculate = () => {
        let tempTotalProductCost = 0;
        let tempProductItems = [];
        let tempCertificationCharges = 0;

        items.forEach(item => {
            let newItem = deepCopy(item);
            const productPrice = fixedTwoDigit(fixedTwoDigit(item.price) * fixedTwoDigit(parseFloat(item.weight)));

            newItem.productPrice = productPrice;
            const loyaltyPrice = fixedTwoDigit(item.loyalty / 100);
            newItem.LoyaltyPrice = loyaltyPrice;
            console.log('it s', item, item.order_item_status, ORDER_ITEM_STATUS.APPROVED.id)
            if (!previewDetails && onlyApproved && item.order_item_status && item.order_item_status === ORDER_ITEM_STATUS.APPROVED.id) {
                console.log('appr')
                tempTotalProductCost += (
                    productPrice
                    -
                    (
                        productPrice
                        *
                        loyaltyPrice
                    )
                );
                tempCertificationCharges += item.certification_charges;
                tempProductItems.push(newItem);
            }

            if (!onlyApproved && !previewDetails) {
                console.log('not app')
                if (item.certification_need === 1) {
                    tempCertificationCharges += item.certification_charges;
                }
                tempTotalProductCost += (
                    productPrice
                    -
                    (
                        productPrice
                        *
                        loyaltyPrice
                    )
                );
                tempProductItems.push(newItem);
            }
            if (previewDetails) {
                console.log('pre')
                tempTotalProductCost += (
                    productPrice
                    -
                    (
                        productPrice
                        *
                        loyaltyPrice
                    )
                );
                tempCertificationCharges += item.certification_charges;
                tempProductItems.push(newItem);
            }
        });
        setTotalProductCost(tempTotalProductCost);
        setProductItems(tempProductItems);
        setCertificationCharges(tempCertificationCharges);
        console.log('te', cc, mc, totalProductCost, fixedTwoDigit(tempTotalProductCost), fixedTwoDigit(cc / 100) * fixedTwoDigit(tempTotalProductCost), fixedTwoDigit(mc / 100) * fixedTwoDigit(tempTotalProductCost))
        setCCAmount(fixedTwoDigit(fixedTwoDigit(cc / 100) * fixedTwoDigit(tempTotalProductCost)));
        setMCAmount(fixedTwoDigit(fixedTwoDigit(mc / 100) * fixedTwoDigit(tempTotalProductCost)));
    }
    console.log(ccAmount, mcAmount, 'cc and mc')

    return (
        <div className="table-responsive">
            <table className='table single-line'>
                <thead>
                    <tr>
                        <th className='text-center'>Product</th>
                        <th className='text-center'>Weight</th>
                        <th className='text-center'>Carat</th>
                        <th className='text-end'>Product Price</th>
                        <th className='text-center'>Loyalty %</th>
                        <th className='text-end'>Value after Loyalty</th>
                    </tr>
                </thead>
                <tbody>
                    {productItems.length === 0 && <tr><td colSpan={6} className='text-center'>There is no approved items</td></tr>}
                    {productItems.length > 0 && productItems.map((item, inx) => (
                        <tr key={inx + "tr"}>
                            <td className='text-center'>{item.product_name ? item.product_name : item.sub_category_name}</td>
                            <td className='text-center'>{fixedTwoDigit(parseFloat(item.weight))} Gm</td>
                            <td className='text-center'>{item.purity}kt</td>
                            <td className='text-end'>{
                                currencyFormatValue(item.productPrice)
                            } KES</td>
                            <td className='text-center'>{item.loyalty}%</td>
                            <td className='text-end'>
                                {
                                    currencyFormatValue(
                                        item.productPrice
                                        -
                                        (
                                            item.productPrice
                                            *
                                            item.LoyaltyPrice
                                        )
                                    )

                                } KES<span className='ms-3'>&nbsp;</span>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={4} className='text-end'>&nbsp;</td>
                        <td>Product Total</td>
                        <td className='text-end'>{
                            currencyFormatValue(totalProductCost)
                        } KES<span className='ms-3'>&nbsp;</span></td>
                    </tr>
                    <tr>
                        <td colSpan={4} className='text-end'>&nbsp;</td>
                        <td>Certification charges</td>
                        <td className='text-end'>{
                            currencyFormatValue(
                                roundUpValue(certificationCharges)
                            )
                        } KES<span className='ms-1'>(-)</span></td>
                    </tr>
                    <tr>
                        <td colSpan={4} className='text-end'>&nbsp;</td>
                        <td>Customer Contribution ({cc}%)</td>
                        <td className='text-end'>{
                            currencyFormatValue(ccAmount)
                        } KES<span className='ms-1'>(-)</span>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4} className='text-end'>&nbsp;</td>
                        <td>Madini Chapaa Contribution (15%)</td>
                        <td className='text-end'>{
                            currencyFormatValue(mcAmount)
                        } KES<span className='ms-1'>(+)</span>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4} className='text-end'>&nbsp;</td>
                        <td> <strong>Total Disbursal Amount</strong></td>
                        <td className='text-end'>
                            <strong> {
                                currencyFormatValue(
                                    totalProductCost
                                    -
                                    roundUpValue(certificationCharges)
                                    -
                                    ccAmount
                                )
                            } KES</strong><span className='ms-3'>&nbsp;</span>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4} className='text-end'>&nbsp;</td>
                        <td><strong>Total Wallet Balance</strong></td>
                        <td className='text-end'>

                            <strong>
                                {
                                    currencyFormatValue(parseFloat(mcAmount) + parseFloat(ccAmount))

                                } KES</strong><span className='ms-3'>&nbsp;</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ItemDetails;
