import CryptoJS from 'crypto-js';
import { ENCRYPTION_KEY, IV } from '../config/constant';

export const encryptValue = (value) => {
    const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
    const ivParsed = CryptoJS.enc.Utf8.parse(IV);
    const encrypted = CryptoJS.AES.encrypt(value, key, {
        iv: ivParsed,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString();
    return encrypted;
}