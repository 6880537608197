import React from 'react'
import { ORDER_ITEM_STATUS } from "../config/constant"

const GetCustomerOrderItemStatus = ({ status }) => {
    switch (status) {
        case ORDER_ITEM_STATUS.NEW.id:
            return <span className='badge text-black'>-</span>
        case ORDER_ITEM_STATUS.AGENT_REJECTED.id:
            return <span className='badge text-black'>-</span>
        case ORDER_ITEM_STATUS.WAITING_ADMIN_REVIEW.id:
            return <span className='badge text-black'>-</span>
        case ORDER_ITEM_STATUS.ADMIN_REJECTED.id:
            return <span className='badge text-black'>-</span>
        case ORDER_ITEM_STATUS.WAITING_SUPER_ADMIN_REVIEW.id:
            return <span className='badge text-black'>-</span>
        case ORDER_ITEM_STATUS.REJECTED.id:
            return <span className='badge order-item-status-rejected-bg'>{ORDER_ITEM_STATUS.REJECTED.text}</span>
        case ORDER_ITEM_STATUS.APPROVED.id:
            return <span className='badge order-item-status-approved-bg'>{ORDER_ITEM_STATUS.APPROVED.text}</span>
    }
}

export default GetCustomerOrderItemStatus;