
// Import files and libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


// others
import { IconPlus } from '../../../svg-icons';
import { ID_TYPE, PERMISSION_KEY, PERMISSION_PAGE_KEY, RECORD_STATUS, TABLES, validatePermission } from '../../../config/constant';
import LocationFormModel from './form-modal';


//reducer
import { setUpdateId, setFormModel, setDefaultFormData } from '../../../store/location/reducer';
import { setTableLoader, updateLoaderStatus, updatePageTitle } from '../../../store/general/reducer';

//action
import { actionGetAll, actionGetById, actionGetCountryList, actionGetCountyList, actionGetVillageList } from '../../../store/location/action';

//custom component
import DataTableComponent from '../../../component/data-table';
import { getDisplayIdByType } from '../../../helper/common-helper';
import PermissionDenied from '../../../component/permission-denied';
import { actionGetAgentList } from '../../../store/agent/action';



const LocationPage = () => {

  // dispatch action
  const dispatch = useDispatch();

  // get the table details like sort, order, page, search, page, page size, table data, total records
  const { search, formModel } = useSelector((state) => state.location);
  const { defaultUserPermission,userData } = useSelector((state) => state.auth);
  const { sort, order, limit, offset, totalRecords } = useSelector((state) => state.table[TABLES.USER]);

  // handle model show
  const showModel = async () => {    
    // clear the form details
    dispatch(setDefaultFormData());
    // remove the update id
    dispatch(setUpdateId(null));
    
    // show the model
    dispatch(setFormModel(true));

    dispatch(actionGetCountryList());
    dispatch(actionGetCountyList());
  };

  useEffect(() => {
    dispatch(updatePageTitle('Location'));
    
    // remove the update id
    dispatch(setUpdateId(null));
    // show the model
    dispatch(setFormModel(false));

    dispatch(actionGetAgentList());

  },[]);
  // On page function call get the page data and if any table action changes call the api
  useEffect(() => {
    // call the api function
    dispatch(setTableLoader(true));
    getAllData();
  }, [limit, offset, search, sort, order, defaultUserPermission]);


  // get data form the api
  const getAllData = async () => {
    // dispatch the data to the API call
    dispatch(actionGetAll())
  }


  //get the specific details by id
  const getById = async (id) => {
    // dispatch the action
    dispatch(updateLoaderStatus(true));
    dispatch(actionGetById(id));
  }

  const tableColumns = [

    {
      field: 'row_ref_number',
      header: 'ID'
    },
    {

      field: 'location_name',
      header: 'NAME'
    },
    {

      field: 'sub_location_title',
      header: 'SUB-LOCATION'
    },
    {

      field: 'country_name',
      header: 'COUNTRY'
    },
    {

      // field: 'product_name',
      header: 'AGENT',
      body: (row) => {
        return row.agent_id ? `${row.agent_first_name} ${row.agent_middle_name} ${row.agent_last_name}` : '';
      }
    },
    {
      sortable: false,
      field: 'status',
      header: 'STATUS',
      headerAlign: 'text-center',
      bodyAlign: 'text-center',
      body: (row) => {
        return (<>
          {row.active === RECORD_STATUS.ACTIVE.id ? (
            <span className='badge badge-active w-100'>{RECORD_STATUS.ACTIVE.name}</span>
          ) : (
            <span className='badge badge-inactive w-100'>{RECORD_STATUS.INACTIVE.name}</span>
          )}
        </>)
      }
    },
  
  ];


  const selectedRow = (e) => {
    getById(e.data.id);
  }
  // page design
  return (
    <>
      {defaultUserPermission && validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.LOCATION,PERMISSION_KEY.READ) ? (
        <div className='container-fluid'>
          <div className="card mb-3">
            <div className="card-body">
              {formModel ? (
                <div className="row">
                  <div className='col-12'>
                    <LocationFormModel />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className='col-md-6 col-12 mb-2'>
                    <h3 className='page-title'>Locations</h3>
                    <p className='sub-page-title table-total-count'>Total number of Locations - {totalRecords}</p>
                  </div>
                  {defaultUserPermission && validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.LOCATION,PERMISSION_KEY.ADD) && (
                    <div className='col-md-6 col-12 text-end mb-3'>
                      <button className='btn btn-orange-primary' onClick={showModel}>
                        <IconPlus customClass='text-white' /> Create Location
                      </button>
                    </div>
                  )}
                  <div className='col-12'>
                    <DataTableComponent
                      tableKey={TABLES.USER}
                      columns={tableColumns}
                      onTableRowSelect={selectedRow}
                    />
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      ) : defaultUserPermission &&  <PermissionDenied />}
    </>
  );

}

export default LocationPage;
