// get axios api config
import api from './api';

// get axios options
import { axiosOptions } from '../helper/common-helper';

// export the functions
export default {
    
    async dispursal(orderSlug) {
        return await api().get(`service-trading/payment/${orderSlug}`, await axiosOptions());

    }

}
