import React from 'react';
import { IconWebLogo, IconLogout } from '../svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ENVIRONMENT } from '../config/constant';
import { Avatar } from 'primereact/avatar';
import { useNavigate } from 'react-router-dom';

import ChangePassword from '../component/change-password';

import { userLogout } from '../store/auth/reducer';
import { setClearTradeState } from '../store/trade/reducer';
import { isMobileScreen } from '../helper/common-helper';

const CustomerTopMenu = () => {
  const { userData, profile } = useSelector(state => state.auth);
  const { deviceWidth } = useSelector((state) => state.general);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = async () => {
    dispatch(userLogout(navigate));
  };

  const goToDashboard = () => {
    dispatch(setClearTradeState());
    navigate("/dashboard");
  };

  return (
    <div className={`container-fluid bg-custom-white general-heading-logo p-3`}>
      <div className='row'>
        <div className='col-12'>
          <div className='float-start'>
            <IconWebLogo width={deviceWidth <= 460 ? 120 : 150} height={60} customClass='cursor-pointer' onIconClick={e => navigate("/dashboard")} />
          </div>
          <div className='float-end'>
            <div className="float-start me-2 customer-profile-img">
              {profile ? (
                <Avatar size="large" shape="circle" image={profile} />
              ) : (
                <Avatar size="large" shape="circle" image={`${ENVIRONMENT.BASE_URL}assets/images/profile.png`} />
              )}
            </div>
            <span className='customer-name-span me-3'>{userData.name}</span>
            <div className="btn-group">
              <span
                className="cursor-pointer"
                onClick={logout} // Call the dashboard navigation function on click
              >
                <IconLogout width={24} height={24} /> Sign out
              </span>
            </div>
          </div>
        </div>
      </div>
      <ChangePassword />
    </div>
  );
};

export default CustomerTopMenu;
