//reducer
import { setDefaultFormData, setDefaultUserPermission, setForgotPasswordApiStatus, setFormDefaultPermission, setOtpFormData, setPermissions, setResetPasswordApiStatus, setShowForgotPassword, setShowForgotPasswordMsg, setShowLogin, setShowResetPasswordMsg, setShowVerifyOtp, setUserProfile, setUserToken } from './reducer';
import { updateLoaderStatus, setDisabledButton } from '../general/reducer';

//api service
import authService from '../../services/auth';
import rolePermissionService from '../../services/role-permission';
import userPermissionService from '../../services/user-permission';
import permissionService from '../../services/permission';
import fileService from '../../services/file';
import { HTTP_STATUS_CODE, PERMISSION_PAGE_KEY, TOAST_TYPE } from '../../config/constant';
import { actionClearData } from '../general/action';
import { handingApiErrors } from '../../helper/common-helper';
import { showToast } from '../../helper/toast-service-helper';
import { setPhoneNumberValidation } from '../customer/reducer';

export const login = (data, urlParams, navigate) => {
    return async (dispatch, getState) => {
        const response = await authService.login(data);

        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        dispatch(actionClearData());
        response.data.isAuth = true;
        await dispatch(setUserToken(response.data));
        const proFile = response.data.data.profile;
        if (proFile) {
            await dispatch(actionGetProfilePic(proFile));
        }
        if (urlParams?.redirect) {
            window.location.href = atob(urlParams.redirect);
        }
    }
}

export const forgotPassword = (data) => {
    return async (dispatch, getState) => {
        const response = await authService.forgotPassword(data);
        dispatch(updateLoaderStatus(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        dispatch(setDisabledButton(false));
        dispatch(setShowLogin(false));
        dispatch(setShowForgotPassword(false));
        dispatch(setShowForgotPasswordMsg(true));
        dispatch(setDefaultFormData());


    }
}
export const resetPassword = (data) => {
    return async (dispatch, getState) => {
        const response = await authService.resetPassword(data);
        dispatch(updateLoaderStatus(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        dispatch(setDisabledButton(false));
        dispatch(setShowLogin(true));
        dispatch(setShowForgotPassword(false));
        dispatch(setShowForgotPasswordMsg(false));
        dispatch(setShowResetPasswordMsg(true));
        dispatch(setDefaultFormData());

    }
}
export const actionGetPermissions = (userRole) => {
    return async (dispatch, getState) => {
        const response = await permissionService.getPermissionList();
        dispatch(updateLoaderStatus(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        let permissions = {};
        let formDefaultPermission = {};

        // Populate the permissions object
        response.data.data.permissions.forEach((permission) => {
            if (permissions[permission.permission_key]) {
                // If permission_key already exists, push the permission to the list
                permissions[permission.permission_key].list.push(permission);
            } else {
                // Initialize permissions[permission.permission_key] as an object first
                permissions[permission.permission_key] = {
                    label: permission.permission_name,
                    list: [
                        {
                            option_name: "No access",
                            permission_option_id: 0
                        }
                    ]
                };

                // Then push the actual permission
                permissions[permission.permission_key].list.push(permission);

                // Set default permission value
                formDefaultPermission[permission.permission_key] = 0;
            }
        });

        // Dispatch the populated permissions object
        await dispatch(setPermissions(permissions));

        // Dispatch the populated default form permission object
        await dispatch(setFormDefaultPermission(formDefaultPermission));

        //default permission
        const roleResponse = await rolePermissionService.getRolePermissionListByUserRole(userRole);
        dispatch(updateLoaderStatus(false));
        if (roleResponse.status === HTTP_STATUS_CODE.OK) {
            await dispatch(setDefaultRolePermission(roleResponse.data.data.role_permissions));
        }

    }
}

export const setDefaultRolePermission = (permissions) => {
    return async (dispatch, getState) => {

        let formDefaultPermission = {};
        // Populate the rolePermissions object

        Object.values(PERMISSION_PAGE_KEY).forEach(page => {
            const getPermissionKey = permissions.filter(permission => permission.permission_key === page);
            formDefaultPermission[page] = getPermissionKey.length > 0 ? getPermissionKey[0].permission_option_id : 0;
        });

        // Dispatch the populated default form permission object
        await dispatch(setFormDefaultPermission(formDefaultPermission));
    }

}

export const getDefaultUserPermission = () => {
    return async (dispatch, getState) => {
        const response = await userPermissionService.getUserPermissionList();
        dispatch(updateLoaderStatus(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }

        let userPermissions = {};

        // Populate the user permission object
        response.data.data.user_permissions.forEach((permission) => {
            userPermissions[permission.permission_key] = permission.option_key;
        });

        // Dispatch the populated default form permission object
        await dispatch(setDefaultUserPermission(userPermissions));

    }
}

export const actionGetProfilePic = (id) => {
    return async (dispatch, getState) => {
        const response = await fileService.getById(id);
        if (response?.status === HTTP_STATUS_CODE.GET) {
            if (response.data?.base64) {
                dispatch(setUserProfile(`data:${response.data.file.file_type};base64,${response.data.base64}`));
            }
        }
    }
}

export const actionSendOtp = (phoneNumber) => {
    return async (dispatch, getState) => {
        const otpFormData = {phone_number:phoneNumber,otp:""};
        await dispatch(setOtpFormData(otpFormData));
        await dispatch(updateLoaderStatus(true));
        const response = await authService.sendOtp(phoneNumber);
        await dispatch(updateLoaderStatus(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        showToast(TOAST_TYPE.SUCCESS, response.data.message, "top-right");
        dispatch(setShowVerifyOtp(true));
    }
}

export const actionVerifyOtp = (data) => {
    return async (dispatch, getState) => {
        await dispatch(updateLoaderStatus(true));
        const response = await authService.verifyOtp(data);
        await dispatch(setDisabledButton(false));
        await dispatch(updateLoaderStatus(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }

        //set validation
        showToast(TOAST_TYPE.SUCCESS, response.data.message, "top-right");
        dispatch(setShowVerifyOtp(false));
        dispatch(setPhoneNumberValidation(true));
    }
}