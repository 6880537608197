// get axios api config
import api from './api';

// get axios options
import { axiosOptions } from '../helper/common-helper';

// export the functions
export default {
    async list(subCatId) {
        const axiosConfig = await axiosOptions();
        return await api().get(`service-one/sub-category-attribute/list/${subCatId}`, axiosConfig);
    }

}
