//reducer
import { setUpdateId, setFormData, setFormModel,  setLocationList, setCountryList, setCountyList, setDivisionList, setDDLocationList, setSubLocationList } from "./reducer";
import { updateLoaderStatus, setTableLoader, setDisabledButton } from '../general/reducer';
import { setTableData } from "../table/reducer";

// others
import { HTTP_STATUS_CODE, PERMISSION_KEY, PERMISSION_PAGE_KEY, TABLES, TOAST_TYPE, validatePermission } from '../../config/constant';
import { showToast } from "../../helper/toast-service-helper";

//services
import locationService from '../../services/location'

import { handingApiErrors } from "../../helper/common-helper";


// export user create action
export const actionCreate = (data) => {
    return async (dispatch, getState) => {


        const response = await locationService.create(data);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        await dispatch(actionGetAll());
        await dispatch(setFormModel(false));
        showToast(TOAST_TYPE.SUCCESS, 'Successfully created', 'top-right');
    }

}

// export user update action
export const actionUpdate = (data, id) => {
    return async (dispatch, getState) => {

        const response = await locationService.update(data, id);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        await dispatch(actionGetAll());
        await dispatch(setFormModel(false));
        showToast(TOAST_TYPE.SUCCESS, 'Successfully updated', 'top-right');

    }
}

// export user get all action
export const actionGetAll = () => {
    return async (dispatch, getState) => {


        const { defaultUserPermission } = getState().auth;
        if (!validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.LOCATION, PERMISSION_KEY.READ)) {
            return;
        }


        let getParams = getState().table[TABLES.USER];
        const data = {
            offset: getParams.offset,
            limit: getParams.limit,
        };
        const response = await locationService.getAll(data);

        dispatch(setTableLoader(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        await dispatch(setTableData({
            data: response.data.data.locations,
            totalRecord: response.data.data.total_count,
            tableKey: TABLES.USER
        }));

    }
}

// export user get by id action
export const actionGetById = (id) => {
    return async (dispatch, getState) => {


        const response = await locationService.getById(id);
        dispatch(updateLoaderStatus(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        const data = response.data.data.location;
        await dispatch(setUpdateId(id));
        
        await dispatch(actionGetCountryList());
        await dispatch(actionGetCountyList());
        await dispatch(actionGetDivisionList(data.county));
        await dispatch(actionGetDDLocationList(data.division));
        await dispatch(actionGetSubLocationList(data.location));
        await dispatch(setFormData({
            location_name: data.location_name,
            address: data.address,
            county: data.county,
            division: data.division,
            location: data.location,
            sub_location: data.sub_location,
            country: data.country,
            agent_id: data.agent_id ? data.agent_id : null,
            active: data.active
        }));

        await dispatch(setFormModel(true));
    }
}

export const actionGetLocationList = (fetch="") => {
    return async (dispatch, getState) => {
        const response = await locationService.list(fetch);
        await dispatch(updateLoaderStatus(false));
        if (response?.status === 200) {
            await dispatch(setLocationList(response.data.data.locations));
        }
    }
}
export const actionGetCountryList = () => {
    return async (dispatch, getState) => {
        const response = await locationService.countryList();
        if (response?.status === HTTP_STATUS_CODE.GET) {
            await dispatch(setCountryList(response.data.data.countries));
        }
    }
}
export const actionGetCountyList = () => {
    return async (dispatch, getState) => {
        const response = await locationService.countyList();
        if (response?.status === HTTP_STATUS_CODE.GET) {
            await dispatch(setCountyList(response.data.data.county));
        }
    }
}
export const actionGetDivisionList = (countyId) => {
    return async (dispatch, getState) => {
        const response = await locationService.divisionList(countyId);
        if (response?.status === HTTP_STATUS_CODE.GET) {
            await dispatch(setDivisionList(response.data.data.division));
        }
    }
}
export const actionGetDDLocationList = (divisionId) => {
    return async (dispatch, getState) => {
        const response = await locationService.locationList(divisionId);
        if (response?.status === HTTP_STATUS_CODE.GET) {
            await dispatch(setDDLocationList(response.data.data.location));
        }
    }
}
export const actionGetSubLocationList = (locationId) => {
    return async (dispatch, getState) => {
        const response = await locationService.subLocationList(locationId);
        if (response?.status === HTTP_STATUS_CODE.GET) {
            await dispatch(setSubLocationList(response.data.data.sub_location));
        }
    }
}