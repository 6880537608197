// impor the libraries
import { createSlice } from '@reduxjs/toolkit'
import { RECORD_STATUS } from '../../config/constant';

const defaultFormData = {
  first_name: "",
  middle_name: "",
  last_name: "",
  national_id: "",
  email: "",
  phone_number: "",
  citizen_ship: "",
  cladfy_request_id: "",
  serial_number: "",
  dob: "",
  gender: "",
  marital_status: "",
  occupation: "",
  nominee_name: "",
  nominee_relationship: "",
  nominee_phone_number: "",
  address: "",
  remarks: "",
  location_id: "",
  organization_id: "",
  agent_id: "",
  active: RECORD_STATUS.ACTIVE.id,
  isAgreed: false
};

// user default initial state
const initialState = {
  formData: defaultFormData,
  formModel: false,
  nationalIdResponse: {},
  showRegisterationSuccessMsg: false,
  customerWallet: null,
  supportDetails: null,
  validatedNationalId:false,
  validatedPhoneNumber:false
}

// create a slice and reducers
const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setFormModel: (state, action) => {
      state.formModel = action.payload;
    },
    setNationalIDValidation: (state, action) => {
      state.validatedNationalId = action.payload;
    },
    setPhoneNumberValidation: (state, action) => {
      state.validatedPhoneNumber = action.payload;
    },
    setSupportDetails: (state, action) => {
      state.supportDetails = action.payload;
    },
    setShowRegisterationSuccessMsg: (state, action) => {
      state.showRegisterationSuccessMsg = action.payload;
    },
    setUpdateId: (state, action) => {
      state.updateId = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setDefaultFormData: (state, action) => {
      state.formData = defaultFormData;
    },
    setNationalIdResponse: (state, action) => {
      state.nationalIdResponse = action.payload;
      const data = action.payload;
      state.formData.first_name = data.first_name;
      state.formData.last_name = data.last_name;
      state.formData.middle_name = data.other_name;
      state.formData.gender = data.gender;
      state.formData.dob = data.dob;
      state.formData.citizen_ship = data.citizenship;
      state.formData.serial_number = data.serial_no;
      state.formData.cladfy_request_id = data.cladfy_request_id;
      state.validatedNationalId = false;
      // state.validatedPhoneNumber = false;
    },
    setClearCustomerState: (state, action) => {
      state.formData = defaultFormData;
      state.formModel = false;
      state.updateId = null;
    },
    setCustomerWallet: (state, action) => {
      state.customerWallet = action.payload;
    }

  },
})
// export the user module
export default customerSlice.reducer;

export const {
  setFormModel,
  setUpdateId,
  setFormData,
  setDefaultFormData,
  setNationalIdResponse,
  setClearCustomerState,
  setShowRegisterationSuccessMsg,
  setCustomerWallet,
  setSupportDetails,
  setNationalIDValidation,
  setPhoneNumberValidation
} = customerSlice.actions;