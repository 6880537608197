import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { actionGetLocationList } from '../../../store/location/action';
import { ENVIRONMENT, MARITAL_STATUS_LIST, REGEXP, TOAST_TYPE } from '../../../config/constant';
import { setDisabledButton, updateLoaderStatus, updatePageTitle } from '../../../store/general/reducer';
import { actionCreate, actionValidateNationalId } from '../../../store/customer/action';

import { setFormData, setShowRegisterationSuccessMsg } from '../../../store/customer/reducer';
import { showToast } from '../../../helper/toast-service-helper';

import { Dropdown } from 'primereact/dropdown';
import { IconMsgSuccessCheckMark, Spinner } from '../../../svg-icons';
import { useNavigate } from 'react-router-dom';
import { getDisplayDateOnly } from '../../../helper/date-helper';
import { actionSendOtp } from '../../../store/auth/actions';

const CustomerRegister = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    // create a state
    const [errors, setErrors] = useState({});

    const { formData, showRegisterationSuccessMsg, validatedNationalId, validatedPhoneNumber } = useSelector((state) => state.customer);
    const { locationList } = useSelector((state) => state.location);
    const { disabledButton } = useSelector((state) => state.general);

    useEffect(() => {
        setErrors({});
        dispatch(setShowRegisterationSuccessMsg(false));
        dispatch(actionGetLocationList());
        dispatch(updatePageTitle("Customer Register"));
    }, []);


    // form validation schema
    let schema = Yup.object().shape({
        // first_name: Yup.string()
        //     .max(50, 'First name must be at most 50 characters long')
        //     .required('First name is required'),
        // last_name: Yup.string()
        //     .max(50, 'Last name must be at most 50 characters long')
        //     .required('Last name is required'),
        // middle_name: Yup.string()
        //     .max(50, 'Middle name must be at most 50 characters long')
        //     .required('Middle name is required'),
        email: Yup.string()
            .max(50, 'Email must be at most 50 characters long')
            .matches(REGEXP.emailRegExp, 'Invalid email address')
            .required('Email is required'),
        phone_number: Yup.string()
            .matches(REGEXP.phoneNumberRegExp, 'Phone number is not valid')
            .max(15, 'Phone number must be at most 15 characters long')
            .min(7, 'Phone number must be at least 7 characters long')
            .required('Phone number is required'),
        national_id:
            Yup.string()
                .matches(REGEXP.numberRegExp, 'National ID is not valid')
                .min(5, 'National ID must be at least 5 character long')
                .max(10, 'National ID must be at most 10 characters long')
                .required('National ID is required'),
        marital_status:
            Yup.string()
                .required('Marital Status is required'),
        occupation: Yup.string()
                .min(1, 'occupation must be at least 1 character long')
                .max(50, 'occupation must be at most 50 characters long')
                .matches(REGEXP.alphabetRegExp, 'occupation can only contain alphabetic characters') // Allows letters and spaces only
                .matches(REGEXP.noConsecutiveSpaceRegExp, 'occupation cannot contain consecutive spaces') // Prevents multiple consecutive spaces
                .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
                .trim()
                  .required("Occupation is required"),
        nominee_name: Yup.string()
                .min(1, 'Next of Kin name must be at least 1 character long')
                .max(50, 'Next of Kin  name must be at most 50 characters long')
                .matches(REGEXP.alphabetRegExp, 'Next of Kin  name can only contain alphabetic characters') // Allows letters and spaces only
                .matches(REGEXP.noConsecutiveSpaceRegExp, 'Next of Kin  name cannot contain consecutive spaces') // Prevents multiple consecutive spaces
                .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
                .trim()
                .required('Next of Kin name is required'),
        nominee_relationship: Yup.string()
                .min(1, 'Next of Kin relationship must be at least 1 character long')
                .max(50, 'Next of Kin  relationship must be at most 50 characters long')
                .matches(REGEXP.alphabetRegExp, 'Next of Kin  relationship can only contain alphabetic characters') // Allows letters       and spaces only
                .matches(REGEXP.noConsecutiveSpaceRegExp, 'Next of Kin  relationship cannot contain consecutive spaces') // Prevents multiple consecutive spaces
                .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
                  .required("Next of Kin Relationship is required"),
        nominee_phone_number: Yup.string()
                  .matches(REGEXP.phoneNumberRegExp, "Phone number is not valid")
                  .max(15, "Phone number must be at most 15 characters long")
                  .min(7, "Phone number must be at least 7 characters long")
                  .required("Next of Kin Phone number is required"),
        address:
            Yup.string()
            .trim()
            .max(255, 'Address must be at most 255 characters long')
            .test('not-only-numbers', 'Address cannot be only numbers', (value) => {
                return !/^[0-9]+$/.test(value); // Ensure the address is not only numbers
            })
            .test('contains-letter-or-number', 'Address must contain at least one letter', (value) => {
                return /[a-zA-Z]/.test(value); // Ensure there is at least one letter or number
            })
                .required('Address is required'),
        // remarks:
        //     Yup.string()
        //         .max(255, 'Remarks must be at most 255 characters long')
        //         .required('Remarks is required'),
        location_id:
            Yup.string()
                .required('Location is required'),
        organization_id:
            Yup.string()
                .required('Organization is required'),
        agent_id:
            Yup.string(),
        isAgreed: Yup.boolean()
            .oneOf([true], 'You must accept the terms and conditions')
            .required('Agent is required')

    });

    //validate the fields
    const validateField = async (name, value) => {
        try {
            await Yup.reach(schema, name).validate(value);
            setErrors({ ...errors, [name]: '' });
        } catch (validationError) {
            setErrors({ ...errors, [name]: validationError.message });
        }
    };

    //validate the form
    const validateForm = async () => {
        try {
            await schema.validate(formData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (validationErrors) {
            const newErrors = validationErrors.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    //handling the form submition
    const handleSubmit = async (e) => {

        // prevent the form submission
        e.preventDefault();

        // get the form status
        const isValid = await validateForm();        
        
        if (isValid) { // check the form is valid or invalid
            if(!validatedNationalId){
                showToast(TOAST_TYPE.ERROR,"Please validate the National ID",'top-right');
            }
            if(!validatedPhoneNumber){
                showToast(TOAST_TYPE.ERROR,"Please verify the M-Pesa Phone Number",'top-right');            
            }
            if(!validatedNationalId || !validatedPhoneNumber){
                return;
            }
            dispatch(setDisabledButton(true));
            dispatch(updateLoaderStatus(true));

            const finalData = {
                first_name: formData.first_name,
                middle_name: formData.middle_name,
                last_name: formData.last_name,
                national_id: formData.national_id == '123456789' ? formData.national_id + Math.floor(10000 + Math.random() * 90000) : formData.national_id,
                email: formData.email,
                phone_number: formData.phone_number,
                citizen_ship: formData.citizen_ship,
                cladfy_request_id: formData.cladfy_request_id,
                serial_number: formData.serial_number,
                dob: formData.dob,
                gender: formData.gender,
                marital_status: formData.marital_status,
                occupation: formData.occupation,
                nominee_name: formData.nominee_name,
                nominee_relationship: formData.nominee_relationship,
                nominee_phone_number: formData.nominee_phone_number,
                address: formData.address,
                remarks: formData.remarks,
                location_id: formData.location_id,
                profile: null,
                active: formData.active,
                organization_id: formData.organization_id,
                agent_id: formData.agent_id
            };
            // create form data
            dispatch(actionCreate(finalData));
        } else {
            console.log('Form has errors:', errors);
        }
    };

    // handling the input changes
    const handleChange = async (e) => {
        let { name, value } = e.target;

        if (name === 'dob') {
            value = getDisplayDateOnly(value);
        }
        if (name === 'isAgreed') {
            value = e.target.checked;
        }

        let data = {
            ...formData,
            [name]: value,
        };
        if (name === 'location_id') {

            const currentLocationData = locationList.filter(loc => loc.id === value)[0];

            data = {
                ...formData,
                [name]: value,
                organization_id: currentLocationData.organization_id,
                agent_id: currentLocationData.agent_id
            };
        }

        dispatch(setFormData(data));
        validateField(name, value);
    };

    const validateNationalId = (e) => {

        if (e.trim() != "") {
            dispatch(actionValidateNationalId(formData.national_id));
        } else {
            showToast(TOAST_TYPE.ERROR, "Provide the National ID to validate", 'top-right');
        }

    };
    const validatePhoneNumber = (e) => {

        if (e.trim() != "" && (formData.phone_number).length >= 7) {
            dispatch(actionSendOtp(formData.phone_number));
        } else {
            showToast(TOAST_TYPE.ERROR, "Provide the M-Pesa Phone Number", 'top-right');
        }

    };


    const gotoLogin = () => {
        navigate("/");
    }
    console.log("showRegisterationSuccessMsg", showRegisterationSuccessMsg);
    return (
        <>
            {!showRegisterationSuccessMsg ? (
                <div className='bg-customer-register w-100 pt-5 pb-3'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-2'></div>
                            <div className='col-md-8 col-12'>
                                <div className='card'>
                                    <div className='card-body shadow rounded p-5'>
                                        <h5 className='text-center mb-5'><strong>Customer Registration Form</strong></h5>
                                        <form onSubmit={handleSubmit} noValidate>
                                            <div className='row'>
                                                <div className="col-12 mt-3">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>National Identity Number</label>
                                                                <div className="input-group">
                                                                    <input
                                                                        type="text"
                                                                        className={`form-control ${validatedNationalId ? 'is-valid' : ''}`}
                                                                        name="national_id"
                                                                        disabled={validatedNationalId}
                                                                        value={formData.national_id}
                                                                        onChange={handleChange}
                                                                        placeholder='Enter the National ID'
                                                                    />
                                                                    {!validatedNationalId && (<span className="input-group-text cursor-pointer"
                                                                        onClick={e => validateNationalId(formData.national_id)}
                                                                    >
                                                                        Validate
                                                                    </span>)}
                                                                </div>
                                                                {errors.national_id && <span className="error-message">{errors.national_id}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>M-Pesa Phone Number</label>
                                                                <div className="input-group">
                                                                    <input type="text"
                                                                        className={`form-control ${validatedPhoneNumber ? 'is-valid' : ''}`}
                                                                        name="phone_number"
                                                                        disabled={validatedPhoneNumber}
                                                                        placeholder='Enter the phone number'
                                                                        value={formData.phone_number}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {!validatedPhoneNumber && (<span className="input-group-text cursor-pointer"
                                                                        onClick={e => validatePhoneNumber(formData.phone_number)}
                                                                    >
                                                                        Verify
                                                                    </span>)}
                                                                </div>
                                                                {errors.phone_number && <span className="error-message">{errors.phone_number}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>Serial Number</label>
                                                                <input
                                                                    readOnly={true}
                                                                    type="text"
                                                                    className="form-control "
                                                                    name="serial_number"
                                                                    placeholder='Enter the serial number'
                                                                    value={formData.serial_number}
                                                                    onChange={handleChange}
                                                                />
                                                                {/* {errors.serial_number && <span className="error-message">{errors.serial_number}</span>} */}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>First Name</label>
                                                                <input type="text" className="form-control "
                                                                    readOnly={true}
                                                                    name="first_name"
                                                                    placeholder='Enter the first name'
                                                                    value={formData.first_name}
                                                                    onChange={handleChange}
                                                                />
                                                                {/* {errors.first_name && <span className="error-message">{errors.first_name}</span>} */}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>Middle Name</label>
                                                                <input type="text" className="form-control "
                                                                    readOnly={true}
                                                                    name="middle_name"
                                                                    placeholder='Enter the middle name'
                                                                    value={formData.middle_name}
                                                                    onChange={handleChange}
                                                                />
                                                                {/* {errors.middle_name && <span className="error-message">{errors.middle_name}</span>} */}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>Last Name</label>
                                                                <input type="text" className="form-control "
                                                                    readOnly={true}
                                                                    name="last_name"
                                                                    placeholder='Enter the last name'
                                                                    value={formData.last_name}
                                                                    onChange={handleChange}
                                                                />
                                                                {/* {errors.last_name && <span className="error-message">{errors.last_name}</span>} */}
                                                            </div>
                                                        </div>


                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>Gender</label>
                                                                <input type="text" className="form-control "
                                                                    readOnly={true}
                                                                    name="gender"
                                                                    placeholder='Enter the gender'
                                                                    value={formData.gender}
                                                                    onChange={handleChange}
                                                                />
                                                                {/* {errors.gender && <span className="error-message">{errors.gender}</span>} */}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>Date of Birth</label>
                                                                <input type="text" className="form-control "
                                                                    readOnly={true}
                                                                    name="dob"
                                                                    placeholder='Enter the date of birth'
                                                                    value={formData.dob}
                                                                    onChange={handleChange}
                                                                />
                                                                {/* {errors.dob && <span className="error-message">{errors.dob}</span>} */}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>Citizenship</label>
                                                                <input type="text" className="form-control "
                                                                    readOnly={true}
                                                                    name="citizen_ship"
                                                                    placeholder='Enter the citizenship'
                                                                    value={formData.citizen_ship}
                                                                    onChange={handleChange}
                                                                />
                                                                {/* {errors.citizen_ship && <span className="error-message">{errors.citizen_ship}</span>} */}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>Marital Status</label>
                                                                <Dropdown
                                                                    value={formData.marital_status}
                                                                    onChange={handleChange}
                                                                    options={MARITAL_STATUS_LIST}
                                                                    optionLabel="name"
                                                                    optionValue="id"
                                                                    placeholder="Select the marital status"
                                                                    className="w-100 custom-single-dropdown"
                                                                    name='marital_status'
                                                                />

                                                                {errors.marital_status && <span className="error-message">{errors.marital_status}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>Mining Location</label>

                                                                <Dropdown
                                                                    value={formData.location_id}
                                                                    onChange={handleChange}
                                                                    options={locationList}
                                                                    optionLabel="location_name"
                                                                    optionValue="id"
                                                                    placeholder="Select the location"
                                                                    className="w-100 custom-single-dropdown"
                                                                    name='location_id'
                                                                />

                                                                {errors.location_id && <span className="error-message">{errors.location_id}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>Email address</label>
                                                                <input type="text" className="form-control "
                                                                    name="email"
                                                                    placeholder='Enter the email address'
                                                                    value={formData.email}
                                                                    onChange={handleChange}
                                                                />
                                                                {errors.email && <span className="error-message">{errors.email}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>Occupation</label>
                                                                <input type="text" className="form-control "
                                                                    name="occupation"
                                                                    placeholder='Enter the occupation'
                                                                    value={formData.occupation}
                                                                    onChange={handleChange}
                                                                />
                                                                {errors.occupation && <span className="error-message">{errors.occupation}</span>}
                                                            </div>
                                                        </div>


                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>Next of Kin Name</label>
                                                                <input type="text" className="form-control "
                                                                    name="nominee_name"
                                                                    placeholder='Enter the next of kin name'
                                                                    value={formData.nominee_name}
                                                                    onChange={handleChange}
                                                                />
                                                                {errors.nominee_name && <span className="error-message">{errors.nominee_name}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>Next of Kin Relationship</label>
                                                                <input type="text" className="form-control "
                                                                    name="nominee_relationship"
                                                                    placeholder='Enter the next of kin relationship'
                                                                    value={formData.nominee_relationship}
                                                                    onChange={handleChange}
                                                                />
                                                                {errors.nominee_relationship && <span className="error-message">{errors.nominee_relationship}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>Next of Kin Phone Number</label>
                                                                <input type="text" className="form-control "
                                                                    name="nominee_phone_number"
                                                                    placeholder='Enter the next of kin phone number'
                                                                    value={formData.nominee_phone_number}
                                                                    onChange={handleChange}
                                                                />
                                                                {errors.nominee_phone_number && <span className="error-message">{errors.nominee_phone_number}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <label>Address</label>
                                                                <textarea className="form-control "
                                                                    name="address"
                                                                    placeholder={`Address Line 1\nAddress Line 2\nCity\nState\nCountry\nPincode`}
                                                                    value={formData.address}
                                                                    onChange={handleChange}
                                                                    rows={6}
                                                                />
                                                                {errors.address && <span className="error-message">{errors.address}</span>}
                                                            </div>
                                                        </div>
                                                        <div className='clearfix'></div>
                                                        <div className="col-md-12">
                                                            <label className='w-100 cursor-pointer'>
                                                                <input type='checkbox' checked={formData.isAgreed} onChange={handleChange} name='isAgreed' />
                                                                <span className='ms-2'>I herewith Agree to the <a href="https://madinichapaa.com/terms-conditions/" target='_blank' className='cursor-pointer text-muted-one text-underline fnt-size-14'>Terms and Conditions</a></span>
                                                            </label>
                                                            {errors.isAgreed && <span className="error-message">{errors.isAgreed}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3">
                                                    <div className="form-group mb-3">
                                                        <button
                                                            disabled={disabledButton}
                                                            className="btn btn-orange-primary"
                                                            type='submit'
                                                        >
                                                            {disabledButton ? <Spinner /> : null} Create Account
                                                        </button>
                                                    </div>
                                                </div>
                                                <p className='line-login mt-3 position-relative text-center'>
                                                    <span className='fnt-size-12'>Or</span>
                                                </p>
                                                <p className='text-center mt-4'>Have an Account Already?
                                                    <span
                                                        className='ms-2 btn btn-orange-primary-outline cursor-pointer'
                                                        onClick={gotoLogin}
                                                    >Sign in</span></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-2'></div>
                        </div>

                    </div>
                </div>
            ) : (
                <div className="container-fluid">
                    <div className={`row fit-height bg-msg-box`}>
                        <div className='col-md-6 align-self-center'>
                            <div className='form-group text-center'>
                                <IconMsgSuccessCheckMark
                                    width={130}
                                    height={130}
                                />
                                <h6 className='m-0 mt-3'><strong>Congratulations !!!!!!</strong></h6>

                                <p className='text-muted-one fnt-size-14 mb-3'>Registration completed successfully! We’ve sent a verification email to you.<br></br> Please click the link in the email to verify your account and set a new password</p>

                                <button onClick={gotoLogin} type="button" className="btn-block btn btn-orange-primary">Back to Sign in</button>
                            </div>
                        </div>
                        <div className={`d-none d-md-block col-md-6 bg-image-two`}></div>
                    </div>
                </div>
            )}
        </>
    );
};
export default CustomerRegister;